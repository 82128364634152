import { FunctionalComponent } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { useSubscriptionService } from '../data';
import { useTranslations } from '../../../lib/use_translations';
import { SubscriptionProps } from '../subscription.types';
import { SubscriptionRequest } from '../data/types';
import { clsx } from '../../../../shared/lib/clsx';

import { Form } from './form';
import { SubscriptionCard } from './subscription_card';
import { Checkbox } from './checkbox';
import { Banner } from './banner';
import { Icon } from './icon';
import { PreparedData } from './form/form.types';

import './styles.css';

type SubscriptionsPopupProps = {
  email: string;
  marker: string;
  host: string;
  onClose: () => void;
  props: SubscriptionProps;
  useDefaultTheme?: boolean;
};

export const SubscriptionsPopup: FunctionalComponent<SubscriptionsPopupProps> = ({
  email,
  marker,
  host,
  onClose,
  props,
  useDefaultTheme = false,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslations('subscription.popup');
  const [currentSubscriptionData, setCurrentSubscriptionData] = useState<null | any>({});
  const [editingSubscriptionId, setEditingSubscriptionId] = useState<null | number>(null);
  const [showAddDirectionButton, setShowAddDirectionButton] = useState(false);

  const {
    state: { subscriptions, loading, recieveNews, emailResendError, active },
    getSubscriptions,
    resendEmailConfirmation,
    createSubscription,
    subscribeToNews,
    freezeSubscription,
    deleteSubscription,
  } = useSubscriptionService({ email, marker, host });

  const handleEditSubscription = (subscriptionId: number) => {
    setShowAddDirectionButton(true);
    setEditingSubscriptionId(subscriptionId);
    const currentSubscription = subscriptions.find(
      (subscription) => subscription.id === subscriptionId,
    );

    if (currentSubscription) {
      const { id: currentId, marker: currentMarker, state, ...data } = currentSubscription;
      setCurrentSubscriptionData(data);
    }
  };

  const handleClose = () => {
    const target = ref.current;
    if (target) {
      target.classList.remove('sbscr-subscriptions-popup--shown');
    }

    setTimeout(() => {
      onClose();
    }, 200);
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.classList.add('sbscr-subscriptions-popup--shown');
    }
  }, [ref]);

  const handleBannerClickToSendEmail = () => resendEmailConfirmation();

  const handleSubmitForm = (formValues: PreparedData) => {
    createSubscription({ subscription: formValues as SubscriptionRequest });
  };
  const handleAddDirection = () => {
    setShowAddDirectionButton(false);
    setEditingSubscriptionId(null);
  };
  const cancelEditSubscription = () => {
    setShowAddDirectionButton(true);
    setEditingSubscriptionId(null);
    setCurrentSubscriptionData({});
  };

  return (
    <div
      className={clsx('sbscr-subscriptions-popup', {
        'sbscr-subscriptions-popup__default-theme': useDefaultTheme,
      })}
      ref={ref}
    >
      {loading && <div className="sbscr-subscriptions-popup__loader">{t('loader')}</div>}
      <div className="sbscr-subscriptions-popup__card">
        <Icon type="circle-xmark" onClick={handleClose} />
        <div className="sbscr-subscriptions-popup__header">
          <div className="sbscr-subscriptions-popup__title">{t('title')}</div>
          <div className="sbscr-subscriptions-popup__email">{email}</div>
        </div>
        <div className="sbscr-subscriptions-popup__body">
          {!loading && !active && (
            <Banner onClick={handleBannerClickToSendEmail} error={emailResendError} />
          )}
          {!showAddDirectionButton ? (
            <Form
              props={props}
              currentData={currentSubscriptionData}
              handleSubmitForm={handleSubmitForm}
              handleOnCancel={cancelEditSubscription}
            />
          ) : (
            <div onClick={handleAddDirection} className="sbscr-subscriptions-popup__add-button">
              <span>+</span>
              <span className="sbscr-subscriptions-popup__add-text">{t('add_direction')}</span>
            </div>
          )}
          <div className="sbscr-subscriptions-popup__subscriptions">
            {subscriptions.map((sub) =>
              editingSubscriptionId === sub.id ? (
                <Form
                  props={props}
                  currentData={currentSubscriptionData}
                  handleSubmitForm={handleSubmitForm}
                  handleOnCancel={cancelEditSubscription}
                />
              ) : (
                <SubscriptionCard
                  key={sub.id}
                  state={sub.state}
                  origin={sub.origin?.name ?? ''}
                  destination={sub.destination?.name ?? ''}
                  departDate={sub.depart_date}
                  returnDate={sub.return_date}
                  oneWay={sub.one_way}
                  months={sub.months}
                  vacationDuration={sub.vacation_duration}
                  maxStops={sub.max_stops}
                  maxPrice={sub.max_price}
                  maxDuration={sub.max_flight_duration}
                  currency="руб"
                  interactDisabled={loading}
                  onEdit={() => handleEditSubscription(sub.id)}
                  onFreeze={() => freezeSubscription(sub.id)}
                  onUnfreeze={() => freezeSubscription(sub.id, false)}
                  onDelete={() => deleteSubscription(sub.id)}
                />
              ),
            )}
          </div>
        </div>
        <div className="sbscr-subscriptions-popup__footer">
          <Checkbox
            id="news_subscription"
            label={t('news_subscription')}
            checked={recieveNews}
            disabled={loading}
            onChange={(val) => subscribeToNews({ receiveNews: val })}
          />
        </div>
      </div>
    </div>
  );
};
