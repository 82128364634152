import { useContext } from 'preact/hooks';

import { Hotel } from '../../../blissey/blissey.types';
import { Price } from '../price';
import { clsx } from '../../../../../../shared/lib/clsx';
import { blisseyParamsContext } from '../../../blissey/blissey_params_context';
import { useTranslations } from '../../../../../lib/use_translations';

import './styles.css';

type Props = {
  hotel: Hotel;
  compact: boolean;
};

export const Offer = ({ hotel, compact }: Props) => {
  const { t } = useTranslations();
  const { linkTarget } = useContext(blisseyParamsContext);
  return (
    <div className={clsx('offer', compact && 'offer_compact')}>
      {hotel.last_price_info && (
        <div className="offer__price">
          <Price
            compact={compact}
            price={hotel.last_price_info.price_pn}
            oldPrice={hotel.last_price_info.old_price_pn}
            discount={hotel.last_price_info.discount}
          />
        </div>
      )}
      <div className="offer__cta">
        <a className="offer__button" href={hotel.url} target={linkTarget} rel="noreferrer">
          {t('info.check_hotel')}
        </a>
      </div>
    </div>
  );
};
