import { stringify } from 'qs';

import { BlisseyParams } from '../../blissey/blissey.types';
import { ResponseHotel } from '../types';
import { tpMediaLinkWrapper } from '../../../../lib/tp_media_link_wrapper';

type URLParams = Record<string, unknown>;

const createParamsForHotellookToBookingRedirect = (
  hotel: ResponseHotel,
  options: BlisseyParams,
  locationId: number,
): URLParams => {
  const { locale, currency, marker = '' } = options;

  return {
    gateId: '2',
    selectedHotelId: String(hotel.hotel_id),
    locationId: String(locationId),
    language: locale,
    currency,
    marker,
    utm_source: options.utm_source || 'tp_widget_hotel_selections',
    skipRulerCheck: 'skip',
    'flags[utm]': 'tp_form_prod',
  };
};

const createParams = (hotel: ResponseHotel, options: BlisseyParams): URLParams => {
  const { locale, currency, marker = '' } = options;
  return {
    hotelId: String(hotel.hotel_id),
    language: locale,
    currency,
    marker,
    utm_source: options.utm_source,
  };
};

const maybeWithAdditionalHotelParams = (params: URLParams, hotel: ResponseHotel): URLParams => {
  if (hotel.last_price_info?.search_params)
    return {
      ...params,
      ...hotel.last_price_info.search_params,
    };
  return params;
};

export const createHotelUrl = (hotel: ResponseHotel, params: BlisseyParams, locationId: number) => {
  const { host, nobooking } = params;

  /*
     Если в качестве хоста выбран хотеллук и юзер не запретил редиректы на букинг,
     создаем УРЛ который редиректит с хотеллука на букинг (стандартное поведение)
     В остальных случаях будет использован переход на указаный хост
     */
  const isHotellookToBookingRedirect = host.includes('search.hotellook.com') && !nobooking;

  const urlParams = isHotellookToBookingRedirect
    ? createParamsForHotellookToBookingRedirect(hotel, params, locationId)
    : createParams(hotel, params);

  const urlHost = isHotellookToBookingRedirect
    ? 'yasen.hotellook.com/adaptors/location_deeplink'
    : host;

  const finalParams = maybeWithAdditionalHotelParams(urlParams, hotel);

  const url = `https://${urlHost}?${stringify(finalParams)}`;

  return tpMediaLinkWrapper(url, params);
};
