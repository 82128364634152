import { FunctionComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import dayjs from 'dayjs';

import { DatePickerFrom } from '../date_picker_from';
import { DatePickerTo } from '../date_picker_to';

import { DateRangePickerProps } from './date_range_picker.types';

import './index.css';

export const DateRangePicker: FunctionComponent<DateRangePickerProps> = ({
  from_value,
  to_value,
  to_required,
  from_required,
  from_max = null,
  from_min = null,
  to_max = null,
  to_min = null,
  from_max_offset = 0,
  from_min_offset = 0,
  to_max_offset = 0,
  to_min_offset = 0,
  translations,
  from_name,
  to_name,
  hiddens,
  id,
  oneWay = false,
  ...props
}) => {
  const [disableDaysBeforeFrom, setDisableDaysBeforeFrom] = useState<Date | null>(null);
  const [disableDaysAfterFrom, setDisableDaysAfterFrom] = useState<Date | null>(null);
  const [disableDaysBeforeTo, setDisableDaysBeforeTo] = useState<Date | null>(null);
  const [disableDaysAfterTo, setDisableDaysAfterTo] = useState<Date | null>(null);

  const baseTranslations = { dayjs: translations.dayjs, date_format: translations.date_format };
  const fromTranslations = {
    ...baseTranslations,
    label: translations.from_label,
    placeholder: translations.from_placeholder,
    one_way: translations.one_way,
  };
  const toTranslations = {
    ...baseTranslations,
    label: translations.to_label,
    placeholder: translations.to_placeholder,
  };
  const { from_name: fromNameHidden, to_name: toNameHidden, format: valueFormat } = hiddens ?? {};
  const fromHiddens = {
    name: fromNameHidden,
  };
  const toHiddens = {
    name: toNameHidden,
  };

  useEffect(() => {
    const offsettedFromMax =
      from_max !== null ? dayjs(from_max).add(from_max_offset, 'day').toDate() : null;
    const offsettedFromMin =
      from_min !== null ? dayjs(from_min).add(from_min_offset, 'day').toDate() : new Date();
    const offsettedToMax =
      to_max !== null ? dayjs(to_max).add(to_max_offset, 'day').toDate() : null;
    const offsettedToMin =
      to_min !== null ? dayjs(to_min).add(to_min_offset, 'day').toDate() : new Date();

    setDisableDaysAfterFrom(offsettedFromMax);
    setDisableDaysBeforeFrom(offsettedFromMin);
    setDisableDaysAfterTo(offsettedToMax);
    setDisableDaysBeforeTo(offsettedToMin);
  }, []);

  return (
    <div className="date-range-picker">
      <DatePickerFrom
        id={`${id}_from`}
        name={from_name}
        required={from_required}
        disable_days_before={disableDaysBeforeFrom}
        disable_days_after={disableDaysAfterFrom}
        translations={fromTranslations}
        hiddens={fromHiddens}
        value_format={valueFormat}
        oneWay={oneWay}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        value={from_value}
      />
      <DatePickerTo
        id={`${id}_to`}
        name={to_name}
        required={to_required}
        disable_days_before={disableDaysBeforeTo}
        disable_days_after={disableDaysAfterTo}
        translations={toTranslations}
        hiddens={toHiddens}
        value_format={valueFormat}
        disabled={oneWay}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        value={oneWay ? undefined : to_value}
      />
    </div>
  );
};
