import { h, FunctionalComponent } from 'preact';

import Checkmark from '../../../../icons/checkmark.svg';
import { clsx } from '../../../../../shared/lib/clsx';

import './styles.css';

type CheckboxProps = {
  id: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export const Checkbox: FunctionalComponent<CheckboxProps> = ({
  id,
  label,
  checked,
  onChange,
  disabled = false,
}) => {
  const handleChange = (event: h.JSX.TargetedEvent) => {
    const target = event.target as HTMLInputElement;

    onChange(target.checked);
  };

  return (
    <div className={clsx('custom-checkbox', { 'custom-checkbox--disabled': disabled })}>
      <label htmlFor={id} className="custom-checkbox__container">
        <input
          id={id}
          name={id}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className="custom-checkbox__input"
          disabled={disabled}
        />
        <div className="custom-checkbox__box like-input">
          <Checkmark className="custom-checkbox__checkmark" />
        </div>
        <span className="custom-checkbox__label">{label}</span>
      </label>
    </div>
  );
};
