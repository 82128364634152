/* eslint no-bitwise: 0 */
import { Color, Colors, Amount } from '../types/colors.types';

type Rgba = { r: number; g: number; b: number; a: number };
type HexColor = string;

type BlackWhite = {
  black?: HexColor;
  white?: HexColor;
  threshold?: number;
};

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
const DEFAULT_THRESHOLD = 179;
const DEFAULT_BW = {
  black: BLACK,
  white: WHITE,
  threshold: DEFAULT_THRESHOLD,
};

const hex2Rgba = (inHex: HexColor): Rgba | null => {
  let hex = inHex;
  if (hex.slice(0, 1) === '#') {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((s) => s + s)
      .join('');
  }

  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);

  if (!result) {
    return null;
  }

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  const a = result[4] ? parseInt(result[4], 16) / 255 : 1;

  return { r, g, b, a };
};

export function hexToRgbaFunc(hex: HexColor | undefined, opacity: number): string | false {
  const rgb = hex ? hex2Rgba(hex) : null;
  if (rgb) {
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
  }
  return false;
}

export const shadeBlend = (p: number, colorStart: string, colorEnd: string) => {
  const progress = Math.abs(p);
  const { round } = Math;
  const parseIntBase16 = parseInt;

  let fromValues;
  let toValues;
  let startR;
  let startG;
  let startB;

  if (colorStart === 'transparent') {
    return hexToRgbaFunc(colorEnd, p);
  }

  if (colorStart.indexOf('rgb') === 0) {
    fromValues = colorStart.split(',');
    toValues = (colorEnd || (p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)')).split(',');

    startR = parseIntBase16(fromValues[0].slice(4));
    startG = parseIntBase16(fromValues[1]);
    startB = parseIntBase16(fromValues[2]);

    const newR = round((parseIntBase16(toValues[0].slice(4)) - startR) * progress) + startR;
    const newG = round((parseIntBase16(toValues[1]) - startG) * progress) + startG;
    const newB = round((parseIntBase16(toValues[2]) - startB) * progress) + startB;

    return `rgb(${newR},${newG},${newB})`;
  }

  const unhashedHex = colorStart.length === 9 ? colorStart.slice(1, 7) : colorStart.slice(1);
  const alphaHex = colorStart.length === 9 ? colorStart.slice(7) : '';

  const startHex = parseIntBase16(unhashedHex, 16);
  const endHex = parseIntBase16((colorEnd || (p < 0 ? BLACK : WHITE)).slice(1), 16);

  startR = startHex >> 16;
  startG = (startHex >> 8) & 0x00ff;
  startB = startHex & 0x0000ff;

  const newHexR = (round(((endHex >> 16) - startR) * progress) + startR) * 0x10000;
  const newHexG = (round((((endHex >> 8) & 0x00ff) - startG) * progress) + startG) * 0x100;
  const newHexB = round(((endHex & 0x0000ff) - startB) * progress) + startB;

  return `#${(0x1000000 + newHexR + newHexG + newHexB)
    .toString(16)
    .slice(1)}${alphaHex.toLowerCase()}`;
};

export function rgbToHex(r: number, g: number, b: number): string {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export const getShadeColors = (
  color: string,
  shadeColor: string,
  step: number,
  amount: number,
): string[] => {
  const colors: string[] = [];
  for (let i = step; i < amount * 100; i += step) {
    const newColor = shadeBlend(i / 1000, color, shadeColor) || '';
    colors.push(newColor);
  }
  return colors;
};

export const lighten = (color: Color, amount: Amount): Colors =>
  getShadeColors(color, WHITE, 100, amount);

export const darker = (color: Color, amount: Amount): Colors =>
  getShadeColors(color, BLACK, 100, amount);

// http://stackoverflow.com/a/3943023/112731
export const getLuminance = (c: Rgba) => {
  return 0.299 * c.r + 0.587 * c.g + 0.114 * c.b;
};

export const invertToBW = (hex: HexColor, bw?: BlackWhite): HexColor => {
  const color = hex2Rgba(hex);
  const options = { ...DEFAULT_BW, ...bw };

  if (!color) {
    return options.black;
  }

  return getLuminance(color) > options.threshold ? options.black : options.white;
};

export const isLightColor = (color: string) => {
  const rgb = hex2Rgba(color);
  if (!rgb) {
    return false;
  }
  return getLuminance(rgb) > DEFAULT_THRESHOLD;
};
