import { HotelsData } from '../../blissey/blissey.types';
import { HotelsList } from '../hotels_list';
import { Layout } from '../layout';

type Props = {
  hotels: HotelsData;
  compact: boolean;
};

export const PredefinedHotels = ({ hotels, compact }: Props) => {
  return (
    <Layout>
      <HotelsList hotels={hotels} compact={compact} />
    </Layout>
  );
};
