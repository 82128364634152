type Entrie = PerformanceEntry & {
  transferSize: number;
  nextHopProtocol: string;
  initiatorType: string;
  encodedBodySize: number;
  decodedBodySize: number;
};

export const getPerformanceMetric = (promoId: string) => {
  const entries = performance.getEntriesByType('resource');
  const filteredEntries = entries.filter((entrie) => {
    try {
      const url = new URL(entrie.name).hostname;
      return url === 'tp.media';
    } catch {
      return false;
    }
  });

  const customScript = filteredEntries.find((entrie) => {
    const { name } = entrie;
    try {
      const entriePromoId = new URL(name).searchParams.get('promo_id');
      return promoId === entriePromoId;
    } catch {
      return false;
    }
  });
  const commonScript = filteredEntries.find((entrie) => {
    const { name } = entrie;
    return /common/.test(name);
  });
  const getPerfData = (entrie: Entrie) => {
    const {
      duration,
      name,
      transferSize,
      entryType,
      nextHopProtocol: protocol,
      initiatorType,
    } = entrie;
    const size = transferSize || entrie.encodedBodySize || entrie.decodedBodySize;
    const cached = !transferSize;
    return {
      name,
      size,
      cached,
      duration,
      entryType,
      protocol,
      initiatorType,
    };
  };
  const custom = customScript ? getPerfData(customScript as Entrie) : null;
  const common = commonScript ? getPerfData(commonScript as Entrie) : null;
  return { performance: { custom, common } };
};
