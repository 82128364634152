import './styles.css';

type Props = {
  min: number;
  max: number;
};

export const RangeRuler = ({ min, max }: Props) => {
  const marks = Array.from({ length: max - min + 1 }, (_, index) => index + min);

  return (
    <div className="range-ruler">
      {marks.map((mark, index) => {
        const isLabeled = index === 0 || (index + 1) % 5 === 0;
        return (
          <div className="range-ruler__mark">
            {isLabeled && <div className="range-ruler__label">{mark}</div>}
          </div>
        );
      })}
    </div>
  );
};
