/**
 * Используется для скрытого логгирования без немедленного вывода в консоль.
 * Для вывода логгированых сообщений нужно вызывать в консоли window.CASCOON_LOGGER.show()
 * Полезно если понадобится логгировать что-то в проде но не показывать это в консоль клиенту
 * // todo: add to readme.md
 */
export class CascoonLogger {
  logs: any[][] = [];

  log(...messages: any) {
    this.logs.push(messages);
  }

  show() {
    this.logs.forEach((log) => console.log(...log));
  }
}

export const cascoonLogger = window.CASCOON_LOGGER || new CascoonLogger();

window.CASCOON_LOGGER = cascoonLogger;
