import { FunctionalComponent } from 'preact';

import { clsx } from '../../../../shared/lib/clsx';

import { SwapPlacesProps } from './swap_places.types';
import './index.css';

export const SwapPlaces: FunctionalComponent<SwapPlacesProps> = ({ onClick, iconColor }) => {
  return (
    <div
      className={clsx('swap-places', { 'fill-default': iconColor })}
      data-testid="swap-places"
      onClick={onClick}
    >
      <span className="swap-places__arrow swap-places__arrow--top">
        <svg
          width="13"
          height="6"
          viewBox="0 0 13 6"
          fill="none"
          color={iconColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M3 4V6L0 3L3 0V2H13V4H3Z" />
        </svg>
      </span>
      <span className="swap-places__arrow swap-places__arrow--bottom">
        <svg
          width="13"
          height="6"
          viewBox="0 0 13 6"
          fill="none"
          color={iconColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M3 4V6L0 3L3 0V2H13V4H3Z" />
        </svg>
      </span>
    </div>
  );
};
