import { BlisseyParams, Hotel } from '../../blissey/blissey.types';
import { ResponseHotel } from '../types';

import { createHotelUrl } from './create_hotel_url';

export const createRating = (hotelId: number) => {
  const value = String(hotelId % 100);
  return Math.ceil((6.5 + parseInt(value[0], 10) / 10 + parseInt(value[1] || '1', 10) / 10) * 10);
};

export const normalizeHotelData = (
  hotel: ResponseHotel,
  {
    locationName,
    locationId,
    blisseyParams,
  }: {
    locationName?: string;
    locationId: number;
    blisseyParams: BlisseyParams;
  },
): Hotel => {
  const normalized: Hotel = {
    ...hotel,
    location_id: locationId,
    url: createHotelUrl(hotel, blisseyParams, locationId),
  };

  if (locationName) normalized.location = locationName;

  if (hotel.rating === 0) normalized.rating = createRating(hotel.hotel_id);

  return normalized;
};
