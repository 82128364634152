import dayjs from 'dayjs';
import { FunctionalComponent } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useDidMount } from 'rooks/dist/esm/hooks/useDidMount';

import { withMonthsNumber } from '../utils/with_months_number';
import { emitter } from '../../../lib/event_emitter/initialized';
import { isFunction } from '../../../../shared/lib/utils';
import { DatePickerInput } from '../date_picker_input';
import { DatePickerValue } from '../date_picker.types';

import { DatePickerToProps } from './date_picker_to.types';
import '../index.css';

const initialToState = (value: DatePickerValue | undefined) =>
  isFunction(value) ? value(dayjs) : value;
const DatePickerToView: FunctionalComponent<DatePickerToProps> = (props) => {
  const {
    id,
    name,
    required = false,
    first_day,
    hiddens,
    translations,
    hidden,
    no_labels,
    disablable,
    widget_id,
    value,
    value_format,
    color_icons,
    disabled = false,
  } = props;
  const { firstDay } = translations.dayjs;
  const realId = `${id}_${widget_id}`;

  const [to, setTo] = useState(initialToState(value));
  const inputRef = useRef<HTMLInputElement>(null);
  const firstDayOfWeek = useMemo(() => first_day ?? firstDay ?? 0, [first_day, firstDay]);

  useDidMount(() => {
    emitter.addListener(`dateToChange-${widget_id}`, setTo);
    emitter.emitEvent(`setInitialTo-${widget_id}`, [initialToState(value)]);
    emitter.emitEvent(`setDateToId-${widget_id}`, [realId]);

    // emitting label translation for "from" datepicker input
    const label = translations.label || translations.placeholder;
    if (label) emitter.emitEvent(`setDatepickerToLabel-${widget_id}`, [label]);
  });

  const handleInputClick = () => {
    emitter.emitEvent(`datepickerOpen-${widget_id}`);
  };

  const handleToClear = () => {
    emitter.emitEvent(`clearDate-${widget_id}`);
  };

  useEffect(() => {
    emitter.emitEvent(`setDatepickerToWrapper-${widget_id}`, [inputRef.current]);
  }, [inputRef.current]);

  return (
    <DatePickerInput
      ref={inputRef}
      required={required}
      id={realId}
      className={
        firstDayOfWeek === 1 ? 'form-datepicker--normal-weekdays' : 'form-datepicker--sunday_first'
      }
      value={to}
      translations={translations}
      name={name}
      hidden={hidden}
      hiddens={hiddens}
      showLabel={!no_labels}
      disablable={disablable}
      onInputClick={handleInputClick}
      valueFormat={value_format}
      onValueClear={handleToClear}
      iconColor={color_icons}
      disabled={disabled}
    />
  );
};

export const DatePickerTo = withMonthsNumber(DatePickerToView);
