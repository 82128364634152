import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { emitter } from '../../lib/event_emitter/initialized';
import { ConnectedLayoutComponent } from '../../lib/connect_to_context';

import { IComponent, IComponentProps, IMediatorProps, IMediatorParams } from './mediator.types';

const processNested = (node: IComponent): IComponentProps => {
  return node.props.children ? processNested(node.props.children[0]) : node.props;
};

export const Mediator: ConnectedLayoutComponent<IMediatorProps> = (props) => {
  const {
    children,
    link_id: linkId,
    api,
    widget: { id: widgetId },
  } = props;
  const [data, setData] = useState({});
  const [defaultData, setdDfaultData] = useState({});

  const broadcast = (params: IMediatorParams) => {
    emitter.emitEvent(`${widgetId}_${linkId}_data_updated`, [params]);
  };

  useEffect(() => {
    children.forEach((component: IComponent) => {
      const componentProps = processNested(component.props.children[0]);
      const componentName = componentProps.id;

      api.getValue(componentName, widgetId, (params) => {
        setdDfaultData((prevState) => {
          return { ...prevState, [componentName]: params, widgetId };
        });
      });
      api.subscribe(componentName, widgetId, (params) => {
        setData((prevState) => {
          return { ...prevState, [componentName]: params, widgetId };
        });
      });
    });
  }, []);

  useEffect(() => {
    if (Object.keys(defaultData).length !== 0) {
      broadcast({ ...defaultData, ...data });
    }
  }, [defaultData, data]);

  return <Fragment>{children}</Fragment>;
};
