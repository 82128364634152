import { FunctionComponent } from 'preact';
import { useMemo } from 'preact/hooks';

import { switchKeysWithValues } from '../../../lib/object';

type Props = {
  hiddens?: Record<string, string>;
  globals: {
    createdByConstructor?: boolean;
  };
};

export const withSwitchedHiddens = <T extends Props>(Component: FunctionComponent<T>) => (
  props: T,
) => {
  const { hiddens, globals } = props;
  const newHiddens = useMemo(() => {
    if (globals.createdByConstructor && hiddens) {
      return switchKeysWithValues(hiddens);
    }

    return hiddens;
  }, [hiddens]);

  return (
    <Component
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      hiddens={newHiddens}
    />
  );
};
