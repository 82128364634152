import { h, Fragment, FunctionalComponent } from 'preact';
import { useState, useMemo } from 'preact/hooks';

import { Metrics } from '../../lib/metrics';
import AviaIcon from '../../icons/avia_tab.svg';
import HotelsIcon from '../../icons/hotels_tab.svg';
import { clsx } from '../../../shared/lib/clsx';
import { generateUrl } from '../../lib/url';
import { KeyValueStringArray } from '../../../shared/types/utility.types';
import { WidgetParams } from '../../types/config/params.types';
import { Translation, TranslationItem } from '../../types/config/translations.types';
import './index.css';

type TabsProps = {
  params: WidgetParams;
  scriptOrigin: string;
  translations: Translation;
  metrics: Metrics;
  tag: string;
};

export const Tabs: FunctionalComponent<TabsProps> = ({
  params: {
    promo_id: basePromoId,
    campaign_id,
    widget_id,
    combinePromos,
    direction,
    ...paramsRest
  },
  tag,
  scriptOrigin,
  translations = { avia: 'avia', hotels: 'hotels' },
  metrics,
  children,
}) => {
  const Icons: Record<
    string,
    { title: string | number | string[] | KeyValueStringArray | TranslationItem; icon: JSX.Element }
  > = {
    '7879': {
      title: translations.avia,
      icon: <AviaIcon />,
    },
    '7873': {
      title: translations.hotels,
      icon: <HotelsIcon />,
    },
  };

  const promoIds = useMemo(() => {
    return combinePromos.reduce(
      (acc, { promoId }) => {
        acc.push(promoId);

        return acc;
      },
      [basePromoId],
    );
  }, [combinePromos]);
  const [currentTab, setCurrentTab] = useState(promoIds[0]);

  if (combinePromos.length === 0) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <div dir={direction} className={clsx('tabs-wrapper')}>
      <div className={clsx('tabs-buttons')}>
        {promoIds.map((promoId) => (
          <div
            key={promoId}
            className={clsx('tabs-button', {
              'tabs-button--active': promoId === currentTab,
            })}
            onClick={() => {
              metrics.onTabClick(promoId);
              setCurrentTab(promoId);
            }}
          >
            <span className="button-icon">{Icons[promoId].icon}</span>
            <span className="button-title">{Icons[promoId].title}</span>
          </div>
        ))}
      </div>
      <div className={clsx('tabs')}>
        <div key="default" className={clsx('tab', { 'tab--active': basePromoId === currentTab })}>
          {children}
        </div>

        {combinePromos.map(({ promoId, campaignId }) => {
          const widgetSrc = generateUrl(scriptOrigin, {
            ...paramsRest,
            promo_id: promoId,
            campaign_id: campaignId,
            nested_widget: true,
            powered_by: false,
            tag,
          });

          return (
            <div
              key={campaignId}
              className={clsx('tab', { 'tab--active': promoId === currentTab })}
            >
              <script src={widgetSrc} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
