import { Breakpoint, Breakpoints, WidgetLayoutSettings } from '../types/config/layout.types';

export const BREAKPOINTS = {
  xl: 1100,
  l: 750,
  m: 570,
  s: 390,
  xs: 0,
};

export const BREAKPOINTS_ORDER: Breakpoints = [
  Breakpoint.XL,
  Breakpoint.L,
  Breakpoint.M,
  Breakpoint.S,
  Breakpoint.XS,
];

export const layoutConfig: WidgetLayoutSettings = {
  columns: 12,
  breakpointsOrder: [Breakpoint.XL, Breakpoint.L, Breakpoint.M, Breakpoint.S, Breakpoint.XS],
  breakpointsOrderRev: [Breakpoint.XS, Breakpoint.S, Breakpoint.M, Breakpoint.L, Breakpoint.XL],
  breakpoints: {
    xl: 1100,
    l: 750,
    m: 570,
    s: 390,
    xs: 0,
  },
};
