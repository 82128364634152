import { FunctionComponent, h } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';

import { emitter } from '../../lib/event_emitter/initialized';
import { withDataReceiver } from '../../lib/with_data_receiver';

import {
  ControlledMultipleRangeProps,
  IMultipleRangeProps,
  MultipleRangeValue,
} from './range.types';

import './index.css';

export const ControlledMultipleRange = (props: ControlledMultipleRangeProps) => {
  const {
    value,
    min = value[0],
    max = value[1],
    step,
    hiddenFields,
    sliderMinElement,
    sliderMaxElement,
    direction = 'ltr',
    className,
    inverted = false,
    filled = true,
    onChange,
    hidden = false,
    testId,
  } = props;

  const [minValue, maxValue] = value;

  const testIdWrapper = `range_wrapper_${testId}`;
  const testIdSliderTouchMin = `range_thumb_min_${testId}`;
  const testIdSliderTouchMax = `range_thumb_max_${testId}`;
  const hiddensTestId = `range_hiddens_${testId}`;

  const [stepInPx, setStepInPx] = useState<number>(0);
  const [stepInPercent, setStepInPercent] = useState<number>(0);
  const [clientLeftPositionInPx, setClientLeftPositionInPx] = useState<number>(0);
  const [currentLeftInPercent, setCurrentLeftInPercent] = useState<number>(0);
  const [currentRightInPercent, setCurrentRightInPercent] = useState<number>(0);

  const [width, setWidth] = useState<number>(0);

  const sliderTouchMin = useRef<HTMLButtonElement>(null);
  const sliderTouchMax = useRef<HTMLButtonElement>(null);
  const sliderRangeLine = useRef<HTMLDivElement>(null);
  const sliderLine = useRef<HTMLDivElement>(null);

  const sliderLineBoundingClientUpdate = () => {
    if (sliderLine.current) {
      const { left, right, width: newWidth } = sliderLine.current.getBoundingClientRect();

      if (direction === 'rtl') {
        setClientLeftPositionInPx(right);
      } else {
        setClientLeftPositionInPx(left);
      }
      setWidth(newWidth);
    }
  };

  useEffect(() => {
    sliderLineBoundingClientUpdate();
  }, [sliderLine]);

  const moveLeft = (event: MouseEvent): void => {
    let newLeftInPx: number;
    if (direction === 'rtl') {
      newLeftInPx = -event.clientX + clientLeftPositionInPx;
    } else {
      newLeftInPx = event.clientX - clientLeftPositionInPx;
    }

    if (newLeftInPx < 0) {
      newLeftInPx = 0;
    }

    let newMinValue: number = Math.round(newLeftInPx / stepInPx) + min;

    if ((newMinValue - min) % step !== 0) {
      return;
    }
    if (newMinValue + step >= maxValue) {
      newMinValue = maxValue - step;
    }

    onChange([newMinValue, maxValue]);
  };

  const moveTouchLeft = (e: TouchEvent): void => {
    const event = e.touches[0];

    let newLeftInPx: number;
    if (direction === 'rtl') {
      newLeftInPx = -event.clientX + clientLeftPositionInPx;
    } else {
      newLeftInPx = event.clientX - clientLeftPositionInPx;
    }
    if (newLeftInPx < 0) {
      newLeftInPx = 0;
    }
    let newMinValue: number = Math.round(newLeftInPx / stepInPx) + min;
    if ((newMinValue - min) % step !== 0) {
      return;
    }
    if (newMinValue + step >= maxValue) {
      newMinValue = maxValue - step;
    }
    onChange([newMinValue, maxValue]);
  };

  const moveTouchRight = (e: TouchEvent): void => {
    const event = e.touches[0];
    let newRightInPx: number;
    if (direction === 'rtl') {
      newRightInPx = clientLeftPositionInPx - event.clientX;
    } else {
      newRightInPx = event.clientX - clientLeftPositionInPx;
    }

    if (newRightInPx > width) {
      newRightInPx = width;
    }

    let newMaxValue: number = Math.round(newRightInPx / stepInPx) + min;

    if ((newMaxValue - min) % step !== 0) {
      return;
    }
    if (newMaxValue - step <= minValue) {
      newMaxValue = minValue + step;
    }

    onChange([minValue, newMaxValue]);
  };

  const moveRight = (event: MouseEvent): void => {
    let newRightInPx: number;

    if (direction === 'rtl') {
      newRightInPx = clientLeftPositionInPx - event.clientX;
    } else {
      newRightInPx = event.clientX - clientLeftPositionInPx;
    }

    if (newRightInPx > width) {
      newRightInPx = width;
    }

    let newMaxValue: number = Math.round(newRightInPx / stepInPx) + min;

    if ((newMaxValue - min) % step !== 0) {
      return;
    }
    if (newMaxValue - step <= minValue) {
      newMaxValue = minValue + step;
    }

    onChange([minValue, newMaxValue]);
  };

  useEffect(() => {
    setCurrentLeftInPercent((minValue - min) * stepInPercent);

    if (currentLeftInPercent > currentRightInPercent) {
      setCurrentLeftInPercent(currentRightInPercent);
    }

    setCurrentRightInPercent((maxValue - min) * stepInPercent);

    if (currentLeftInPercent > currentRightInPercent) {
      setCurrentRightInPercent(currentLeftInPercent);
    }

    if (direction === 'rtl') {
      sliderTouchMin.current.style.setProperty('left', `${currentLeftInPercent}%`, 'important');
      sliderTouchMax.current.style.setProperty('left', `${currentRightInPercent}%`, 'important');
    } else {
      sliderTouchMin.current.style.setProperty('left', `${currentLeftInPercent}%`, 'important');
      sliderTouchMax.current.style.setProperty('left', `${currentRightInPercent}%`, 'important');
      sliderTouchMax.current.style.setProperty('right', `${currentRightInPercent}%`, 'important');
    }
    sliderRangeLine.current.style.setProperty(
      'width',
      `${currentRightInPercent - currentLeftInPercent}%`,
      'important',
    );
  }, [minValue, maxValue, min, max, step]);

  useEffect(() => {
    setStepInPx(width / (max - min));
    setStepInPercent(stepInPx / (width / 100));
    setCurrentLeftInPercent((minValue - min) * stepInPercent);
    setCurrentRightInPercent((maxValue - min) * stepInPercent);

    if (direction === 'rtl') {
      sliderTouchMin.current.style.setProperty('left', `${currentLeftInPercent}%`, 'important');
      sliderTouchMax.current.style.setProperty('left', `${currentRightInPercent}%`, 'important');
    } else {
      sliderTouchMin.current.style.setProperty('left', `${currentLeftInPercent}%`, 'important');
      sliderTouchMax.current.style.setProperty('left', `${currentRightInPercent}%`, 'important');
      sliderTouchMax.current.style.setProperty('right', `${currentRightInPercent}%`, 'important');
    }
    sliderRangeLine.current.style.setProperty(
      'width',
      `${currentRightInPercent - currentLeftInPercent}%`,
      'important',
    );
    sliderRangeLine.current.style.setProperty('left', `${currentLeftInPercent}%`, 'important');
  });

  const onMouseDownSliderMinHandler = () => {
    document.addEventListener('mousemove', moveLeft);

    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', moveLeft);
      },
      { once: true },
    );
  };

  const onMouseDownSliderMaxHandler = () => {
    document.addEventListener('mousemove', moveRight);

    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', moveRight);
      },
      { once: true },
    );
  };

  const onTouchStartSliderMinHandler = () => {
    document.addEventListener('touchmove', moveTouchLeft);

    document.addEventListener(
      'touchmove',
      () => {
        document.removeEventListener('touchmove', moveTouchLeft);
      },
      { once: true },
    );
  };

  const onTouchStartSliderMaxHandler = () => {
    document.addEventListener('touchmove', moveTouchRight);
    document.addEventListener(
      'touchmove',
      () => {
        document.removeEventListener('touchmove', moveTouchRight);
      },
      { once: true },
    );
  };

  return (
    <div
      className={`slider-multiple-wrapper ${className} ${
        hidden ? 'slider-multiple-wrapper--hide' : ''
      } ${filled ? 'slider-multiple-wrapper--filled' : ''}`}
      data-testid={testIdWrapper}
      onMouseEnter={sliderLineBoundingClientUpdate}
    >
      {(sliderMinElement || sliderMaxElement) && (
        <div className={`slider-title ${inverted ? 'slider-title--inverted' : ''}`}>
          {sliderMinElement && <div dangerouslySetInnerHTML={{ __html: sliderMinElement }} />}
          {sliderMaxElement && <div dangerouslySetInnerHTML={{ __html: sliderMaxElement }} />}
        </div>
      )}

      <div className="slider-multiple">
        <button
          className="slider-touch slider-touch--min"
          type="button"
          aria-label="min-range-control"
          onMouseDown={onMouseDownSliderMinHandler}
          onTouchMove={onTouchStartSliderMinHandler}
          ref={sliderTouchMin}
          data-testid={testIdSliderTouchMin}
        />
        <button
          className="slider-touch slider-touch--max"
          ref={sliderTouchMax}
          type="button"
          aria-label="max-range-control"
          onTouchMove={onTouchStartSliderMaxHandler}
          onMouseDown={onMouseDownSliderMaxHandler}
          data-testid={testIdSliderTouchMax}
        />
        <div className="slider-range-line" ref={sliderRangeLine} />
        <div className="slider-line" ref={sliderLine} />
        {hiddenFields?.min && (
          <input
            type="hidden"
            name={hiddenFields?.from}
            value={value[0]}
            data-testid={`${hiddensTestId}_min`}
          />
        )}
        {hiddenFields?.max && (
          <input
            type="hidden"
            name={hiddenFields?.to}
            value={value[1]}
            data-testid={`${hiddensTestId}_max`}
          />
        )}
      </div>
    </div>
  );
};

// disconnected в этом конетксте означает отвязанность от контекста
export const DisconnectedMultipleRange: FunctionComponent<IMultipleRangeProps> = (props) => {
  const {
    min,
    max,
    step,
    value: defaultValue,
    hidden,
    sliderMinElement = (minVal) => `<div data-testid="slider-min-element">${minVal}</div>`,
    sliderMaxElement = (maxVal) => `<div data-testid="slider-max-element">${maxVal}</div>`,
    direction,
    widget_id: widgetId,
    id,
    receivedData,
    class: className,
    inverted = false,
    filled = true,
  } = props;

  const initialValue = Array.isArray(defaultValue)
    ? defaultValue
    : defaultValue.split(',').map(Number);

  const [value, setValue] = useState<MultipleRangeValue>(initialValue as MultipleRangeValue);

  const elementId = `${widgetId}_${id}`;

  const apiDataUpdated = useCallback(() => {
    emitter.emitEvent(`${elementId}_data_updated`, [
      {
        min: value[0],
        max: value[1],
      },
    ]);
  }, [widgetId, id, value]);

  const setValueThruApi = useCallback(() => {
    emitter.addListener(
      `${elementId}_set_value`,
      ({ min: minValueFromApi, max: maxValueFromApi }: { min: number; max: number }) => {
        setValue([minValueFromApi, maxValueFromApi]);
      },
    );
  }, []);

  const getValueThruApi = useCallback(() => {
    emitter.addListener(
      `${elementId}_get_value`,
      (resolve: (data: { min: number; max: number }) => void) => {
        resolve({ min: value[0], max: value[1] });
      },
    );
  }, []);

  useEffect(() => {
    getValueThruApi();
    setValueThruApi();
  }, []);

  useEffect(() => {
    apiDataUpdated();
  }, [value[0], value[1]]);

  return (
    <ControlledMultipleRange
      testId={elementId}
      value={value}
      hidden={!!receivedData?.hide}
      className={className}
      hiddenFields={hidden}
      direction={direction}
      sliderMaxElement={sliderMaxElement(value[1], props)}
      sliderMinElement={sliderMinElement(value[0], props)}
      step={step}
      min={min}
      max={max}
      inverted={inverted}
      filled={filled}
      onChange={setValue}
    />
  );
};

export const MultipleRange = withDataReceiver<IMultipleRangeProps>(DisconnectedMultipleRange);
