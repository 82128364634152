import { h, FunctionalComponent } from 'preact';

import { VacationMonths } from './vacation_months/vacation_months';
import { VacationDurationSelect } from './vacation_duration/vacation_duration';

import './styles.css';

type FuzzyDatesSelectProps = {
  oneWay: boolean;
  months: number[];
  duration: { min: number; max: number };
};

export const FuzzyDatesSelect: FunctionalComponent<FuzzyDatesSelectProps> = ({
  oneWay = false,
  months = [],
  duration = { min: 7, max: 14 },
}) => {
  return (
    <div className="fuzzy-dates-select">
      <div className="fuzzy-dates-select__months">
        <VacationMonths months={months} />
      </div>
      {!oneWay && (
        <div className="fuzzy-dates-select__days">
          <VacationDurationSelect duration={duration} />
        </div>
      )}
    </div>
  );
};
