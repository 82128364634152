import dayjs from 'dayjs';
import { createContext, FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';

import { IMetrics } from '../types/metric.types';
import { layoutConfig } from '../constants/layout';
import { WidgetConfig } from '../types/config/config.types';
import { IWidget } from '../types/widget.types';
import { isString } from '../../shared/lib/utils';

import { api } from './api';
import { tpMediaLinkWrapper } from './tp_media_link_wrapper';
import { pluralisation } from './pluralisation';
import { ErrorsHandler } from './errors_handler';
import { Formatter } from './formatter';
import { IContextValues } from './connect_to_context';

export type DataProviderProps = {
  metrics: IMetrics;
  config: WidgetConfig;
  locale?: string;
  widget: IWidget;
  showCommonError?: (arg: Error) => void;
  showNothingFoundError?: (arg: Error) => void;
};

export const DataContext = createContext<IContextValues>({} as IContextValues);

export const DataProvider: FunctionalComponent<DataProviderProps> = ({
  config,
  metrics,
  showCommonError,
  showNothingFoundError,
  widget,
  children,
  locale,
}) => {
  const { translations, translations_list: translationsList } = config;

  const finalLocale = locale || config.locale || widget.params.locale;

  /**
   * За реальный перевод компонентов виджета считаем или тот что соответствует выбраной локали из списка пеерводов
   * или, если списка нет - то тот что лежит в поле translations.
   */
  const certainTranslation =
    (isString(finalLocale) && translationsList?.[finalLocale]) || translations;

  const store = useMemo(() => {
    return {
      metrics,
      pluralisation,
      dayjsProp: dayjs,
      tpMediaLinkWrapper,
      api,
      config,
      errorsHandler: ErrorsHandler,
      formatter: Formatter,
      layoutConfig,
      translations: certainTranslation,
      widget,
      showNothingFoundError,
      showCommonError,
    };
  }, [config, widget, certainTranslation]);

  return <DataContext.Provider value={store}>{children}</DataContext.Provider>;
};
