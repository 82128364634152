import { useCallback, useEffect, useRef, Ref } from 'preact/hooks';

export const useOutsideClick = (
  ref: Ref<HTMLElement | null>,
  handler: (event: Event) => void,
  when = true,
) => {
  const savedHandler = useRef(handler);

  const memoizedCallback = useCallback(
    (event: Event) => {
      const targetElement = (event.composedPath
        ? event.composedPath()[0]
        : event.target) as Element;

      if (ref.current && !ref.current.contains(targetElement)) {
        savedHandler.current(event);
      }
    },
    [ref],
  );

  useEffect(() => {
    savedHandler.current = handler;
  });

  useEffect(() => {
    if (when) {
      document.addEventListener('click', memoizedCallback, true);
      document.addEventListener('ontouchstart', memoizedCallback, true);

      return () => {
        document.removeEventListener('click', memoizedCallback, true);
        document.removeEventListener('ontouchstart', memoizedCallback, true);
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [ref, handler, when, memoizedCallback]);
};
