import { useCallback, useContext } from 'preact/hooks';

import { DataContext } from '../../../lib/data_provider';

type SubscriptionMetricsContext = {
  subscription_id: number;
};

export const useMetrics = () => {
  const { metrics } = useContext(DataContext);

  const onAddSubscription = useCallback(
    (context: SubscriptionMetricsContext) => {
      metrics.send('add_subscription', context);
    },
    [metrics],
  );

  const onDeleteSubscription = useCallback(
    (context: SubscriptionMetricsContext) => {
      metrics.send('delete_subscription', context);
    },
    [metrics],
  );

  const onFreezeSubscription = useCallback(
    (context: SubscriptionMetricsContext) => {
      metrics.send('freeze_subscription', context);
    },
    [metrics],
  );

  const onUnfreezeSubscription = useCallback(
    (context: SubscriptionMetricsContext) => {
      metrics.send('unfreeze_subscription', context);
    },
    [metrics],
  );

  return { onAddSubscription, onDeleteSubscription, onFreezeSubscription, onUnfreezeSubscription };
};
