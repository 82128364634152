import { FunctionalComponent, PreactContext } from 'preact';
import dayjs from 'dayjs';
import { useContext } from 'preact/hooks';

import { WidgetLayoutSettings } from '../types/config/layout.types';
import { Settings } from '../types/widget_settings.types';
import { Translations } from '../types/config/translations.types';
import { IMetrics } from '../types/metric.types';
import { IWidget } from '../types/widget.types';
import { WidgetGlobals } from '../types/config/globals.types';
import { WidgetField, WidgetFieldParams } from '../types/config/fields.types';
import { WidgetConfig } from '../types/config/config.types';
import { WidgetParams } from '../types/config/params.types';
import { isObjectLike, isUndefined } from '../../shared/lib/utils';

import { IApi } from './api';
import { TpMediaLinkWrapper } from './tp_media_link_wrapper';
import { Pluralisation } from './pluralisation';
import { IErrorsHandler } from './errors_handler';
import { Formatter } from './formatter';

export interface IContextValues {
  metrics: IMetrics;
  pluralisation: Pluralisation;
  dayjsProp: typeof dayjs;
  tpMediaLinkWrapper: TpMediaLinkWrapper;
  api: IApi;
  config: WidgetConfig;
  formatter: Formatter;
  translations: Translations;
  errorsHandler: IErrorsHandler;
  layoutConfig: WidgetLayoutSettings;
  widget: IWidget;
  showCommonError?: (arg: Error) => void;
  showNothingFoundError?: (arg: Error) => void;
}

export type ConnectedLayoutComponentProps<T = Record<string, unknown>> = {
  widget: IWidget;
  metrics: IMetrics;
  pluralisation: Pluralisation;
  plurals: Pluralisation;
  dayjs: typeof dayjs;
  dayjsProp: typeof dayjs;
  api: IApi;
  tpMediaLinkWrapper: TpMediaLinkWrapper;
  settings: Settings;
  errorsHandler: IErrorsHandler;
  formatter: Formatter;
  layoutConfig: WidgetLayoutSettings;
  wrapper: HTMLElement;
  globals: WidgetGlobals;
  translations: Translations;
  params: WidgetParams;
  showCommonError?: (arg: Error) => void;
  showNothingFoundError?: (arg: Error) => void;
  field?: WidgetField;
} & WidgetParams &
  WidgetFieldParams &
  T;

export type ConnectedLayoutComponent<T = Record<string, unknown>> = FunctionalComponent<
  ConnectedLayoutComponentProps<T>
>;

export type ConnectedLayoutComponentExternal = FunctionalComponent<{
  id: string;
  field?: WidgetField;
  style?: any;
}>;

export const connectToContext = (
  Context: PreactContext<IContextValues>,
  Component: ConnectedLayoutComponent,
): ConnectedLayoutComponentExternal => (props) => {
  const values = useContext(Context);
  const {
    metrics,
    pluralisation,
    dayjsProp,
    tpMediaLinkWrapper,
    api,
    errorsHandler,
    formatter,
    layoutConfig,
    config,
    translations,
    widget,
    showCommonError,
    showNothingFoundError,
  } = values;

  const { id, field: _, children, style, ...restProps } = props;
  let { field } = props;

  const widgetParams = widget.params;

  const mergedTranslaions = translations?.[id]
    ? { ...translations[id], dayjs: translations.dayjs }
    : {};

  if (field) {
    const params = field.params ? { ...field.params } : {};

    params.globals = config.globals;

    params.translations = mergedTranslaions;

    if (typeof params.translations !== 'string') {
      params.translations = { ...params.translations, dayjs: translations?.dayjs };
    }

    const getValueFromParams = (key: string, defaultValue?: WidgetFieldParams[string]) => {
      const defaultKey = `default_${key}`;

      if (!isUndefined(widgetParams[defaultKey])) {
        return widgetParams[defaultKey];
      }

      //* Костыль исключает direction, такой id используется во
      //* многих конфигах как идентификатор автокомплита,
      //* а также в конфиге есть поле с таким же названием
      //* и значениями ltr/rtl - направлениями отображения
      //* элементов на лейауте
      if (key !== 'direction' && !isUndefined(widgetParams[key])) {
        return widgetParams[key];
      }

      return defaultValue;
    };

    const combinedValueKeys = id.split('_and_');
    const isCombinedField = combinedValueKeys.length === 2;

    if (isCombinedField) {
      const [firstValueKey, secondValueKey] = combinedValueKeys;
      const [firstDefaultValue, secondDefaultValue] = isObjectLike<Record<string, string>>(
        params.value,
      )
        ? [params.value[firstValueKey], params.value[secondValueKey]]
        : [];
      const firstValue = getValueFromParams(firstValueKey, firstDefaultValue);
      const secondValue = getValueFromParams(secondValueKey, secondDefaultValue);

      params.value = { [firstValueKey]: firstValue, [secondValueKey]: secondValue };
    } else {
      params.value = getValueFromParams(id, params.value);
    }

    // Мутация поля приведет к тому что в модели конструктора попадут поля с лишними свойствами, так что поля не мутируем
    field = { ...field, params };
  }

  const fieldParams = field?.params || {};

  return (
    // Todo: разобраться что TS имеет ввиду
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component
      id={id}
      widget={widget}
      metrics={metrics}
      pluralisation={pluralisation}
      plurals={pluralisation}
      dayjs={dayjsProp}
      dayjsProp={dayjsProp}
      api={api}
      tpMediaLinkWrapper={tpMediaLinkWrapper}
      settings={{ config }}
      errorsHandler={errorsHandler}
      formatter={formatter}
      layoutConfig={layoutConfig}
      wrapper={widget.wrapper}
      globals={config.globals}
      translations={mergedTranslaions}
      params={widget.params}
      showCommonError={showCommonError}
      showNothingFoundError={showNothingFoundError}
      field={field}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...fieldParams}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...widget.params}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
    >
      {children}
    </Component>
  );
};
