import { FunctionalComponent } from 'preact';

import Close from '../../../icons/close.svg';
import './styles.css';
import { clsx } from '../../../../shared/lib/clsx';

type HighlightAlertProps = {
  show: boolean;
  onClose: () => void;
  title: string;
  description: string;
};

export const HighlightAlert: FunctionalComponent<HighlightAlertProps> = ({
  show,
  onClose,
  title,
  description,
}) => {
  return (
    <div className={clsx('sbscr-highlight-alert', { 'sbscr-highlight-alert--show': show })}>
      <h4 className="sbscr-highlight-alert__header">{title}</h4>
      <p className="sbscr-highlight-alert__text">{description}</p>
      <Close className="sbscr-highlight-alert__close" onClick={onClose} />
    </div>
  );
};
