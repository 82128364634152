/* eslint-disable @typescript-eslint/no-explicit-any */

import rollbar from './rollbar';

interface IParams {
  id: string;
  widget: any;
}
export interface IErrorsHandler {
  rollbar: any;
  params: Partial<IParams>;
  setMetaData: (params: Partial<IParams>) => void;
  init: (params: Partial<IParams>) => IErrorsHandler;
  send: (message: string, error: Error, widgetId?: string) => void;
}

export const ErrorsHandler: IErrorsHandler = {
  rollbar: undefined,
  params: {},
  init(params) {
    rollbar();
    this.rollbar = window.Rollbar;
    this.setMetaData(params);
    return this;
  },
  setMetaData(params) {
    this.params = { ...this.params, ...params };
    this.rollbar.captureEvent({ widget: params }, 'error');
    this.rollbar.captureEvent({ widget: params }, 'critical');
  },
  send(message, error, widgetId) {
    const errorMessage = widgetId ? `${widgetId} ${message}` : message;
    if ('meta' in error) {
      // todo: проверка через typeof RequestError не работает
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.rollbar.info(`Request details: ${JSON.stringify({ ...error.meta }, null, 2)}`);
    }
    this.rollbar.error(errorMessage, error);
  },
};
