import { FunctionalComponent } from 'preact';

import { Icon } from '../icon';

import './styles.css';

export const Layout: FunctionalComponent<{ header?: () => JSX.Element }> = ({
  header,
  children,
}) => (
  <div className="layout">
    {header && <div className="layout__header">{header()}</div>}
    <div className="layout__content">{children}</div>
    <div className="layout__footer">
      Hotel rating provided by TrustYou™
      <Icon type="trustyou" />
    </div>
  </div>
);
