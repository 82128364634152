export const Cookies = {
  setCookie(name: string, value: string, days: number): void {
    let date: Date;
    let expires = '';

    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  },
  getCookie(name: string): string | null {
    let c: string;
    const ca = document.cookie.split(';');
    let i = 0;
    const nameEQ = `${name}=`;

    while (i < ca.length) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
      i += 1;
    }
    return null;
  },
  eraseCookie(name: string): void {
    document.cookie = `${name}=; Max-Age=-99999999;`;
  },
};
