import { makeNumber, normalizeBoolean, notNullish } from '../../../../shared/lib/utils';
import { parseMultipleValue } from '../../../lib/url';
import { WidgetParams } from '../../../types/config/params.types';
import { conditionalField } from '../../../lib/object';
import { IContextValues } from '../../../lib/connect_to_context';
import { IParams } from '../../../types/node.types';

import { BlisseyParams, ViewType } from './blissey.types';

const normalizeBlisseyCurrencyParam = (currency: string | undefined, locale: string) => {
  if (!currency) return locale === 'ru' ? 'rub' : 'usd';
  if (currency === 'byr') return 'byn';
  return currency.toLowerCase();
};

export const createBlisseyParams = (
  params: WidgetParams & IContextValues & IParams,
): BlisseyParams => {
  const {
    limit,
    nobooking,
    categories,
    currency,
    locale,
    utm_source,
    host,
    ids,
    id,
    iata,
    type,
  } = params.widget.params;

  return {
    ...params,
    viewType: type === ViewType.Compact ? ViewType.Compact : ViewType.Full,
    limit: makeNumber(limit),
    nobooking: normalizeBoolean(nobooking),
    categories: categories && parseMultipleValue(categories),
    currency: normalizeBlisseyCurrencyParam(currency, locale),
    utm_source: utm_source || 'tp_widget_hotel_selections',
    host: host || 'search.hotellook.com',
    language: params.settings.config.translations.language || locale,
    ...conditionalField('hotelsId', () => parseMultipleValue(ids).map(Number), notNullish(ids)),
    ...conditionalField('locationId', parseInt(id, 10), notNullish(id)),
    ...conditionalField('iata', iata, notNullish(iata)),
    linkTarget: params.globals.target || '_blank',
  };
};
