import spTracker from 'mamka';

export class Mamka {
  private nameSpace: string;

  private category: string;

  private tracker = spTracker;

  constructor(nameSpace: string, category: string) {
    this.nameSpace = nameSpace;
    this.category = category;

    spTracker('newTracker', nameSpace, 'avsplow.com', {
      appId: 'tp_widgets',
      encodeBase64: false,
    });
  }

  send = <T>(action: string, metrics: T) => {
    if (process.env.NODE_ENV === 'production') {
      this.tracker(`trackStructEvent:${this.nameSpace}`, this.category, action, '', '', '', [
        metrics,
      ]);
    }
  };
}
