import { WidgetConfig } from '../../types/config/config.types';
import { conditionalField } from '../object';
import { notNullish } from '../../../shared/lib/utils';

import { useJourneyId } from './journey_id';

export type WidgetConfigWithDynamicParameters = WidgetConfig & {
  globals: { hiddens: { journey_id?: string } };
};

/**
 * Динамические параметры - это параметры, которые могут поменяться в рантайме,
 * например, по запросу от других микрофронтендов. Здесь мы просто берем конфиг виджета
 * и добиваем его такими параметрами
 * @param widgetId
 * @param config
 */
export const useConfigWithDynamicParameters = (
  widgetId: string,
  config: WidgetConfig,
): WidgetConfigWithDynamicParameters => {
  const journeyId = useJourneyId(widgetId);

  return {
    ...config,
    globals: {
      ...config.globals,
      hiddens: {
        ...config.globals.hiddens,
        ...conditionalField('journey_id', journeyId, notNullish(journeyId)),
      },
    },
  };
};
