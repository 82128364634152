import { h, FunctionComponent } from 'preact';
import { useMemo } from 'preact/hooks';
import { memo } from 'preact/compat';

import { InjectedProps } from '../date_picker.types';

export const withMonthsNumber = <T extends InjectedProps>(
  WrappedComponent: FunctionComponent<T>,
) => {
  const WithMonthsNumber: FunctionComponent<Omit<T, 'monthsNumber'>> = (props) => {
    const { layoutConfig, wrapper } = props;
    const monthsNumber = useMemo(() => {
      const number = wrapper.offsetWidth > layoutConfig.breakpoints.m ? 2 : 1;

      return number;
    }, [wrapper]); // eslint-disable-next-line react/jsx-props-no-spreading

    return (
      <WrappedComponent
        {...(props as T)} // eslint-disable-line react/jsx-props-no-spreading
        monthsNumber={monthsNumber}
      />
    );
  };

  return memo(WithMonthsNumber);
};
