import dayjs from 'dayjs';

import { Formatter } from '../../../../lib/formatter';

import { CurrentData, DefaultFormData, PreparedData } from './form.types';
import { MAX_FLIGHT_DURATION_RANGE, MAX_PRICE_RANGE, MAX_STOPS_RANGE } from './constants';

const removeUndefinedProperties = (obj: any) => {
  const newObj = { ...obj }; // Make a copy of the object
  Object.keys(newObj).forEach((propName) => {
    if (newObj[propName] === undefined) {
      delete newObj[propName];
    }
  });
  return newObj;
};

const currentMonth = dayjs().month();

export const normalizeCurrencyParam = (currency: string | undefined, locale: string) => {
  let currentCurrency = '';
  if (!currency) currentCurrency = locale === 'ru' ? 'rub' : 'usd';
  if (currency === 'byr') currentCurrency = 'byn';
  return Formatter.transformCurrency(currentCurrency.toLowerCase());
};

export const getDefaultFormData = (
  currentData: CurrentData,
  originIata?: string,
  destinationIata?: string,
): DefaultFormData => ({
  originIata: currentData.origin?.iata || originIata || '',
  destinationIata: currentData.destination?.iata || destinationIata || '',
  months: currentData.months || [currentMonth],
  vacationDuration: currentData.vacation_duration || { min: 7, max: 14 },
  departDate: currentData.depart_date || '',
  returnDate: currentData.return_date || '',
  maxPrice: currentData.max_price || MAX_PRICE_RANGE.max,
  maxFlightDuration: currentData.max_flight_duration || MAX_FLIGHT_DURATION_RANGE.max,
  maxStops: currentData.max_stops || MAX_STOPS_RANGE.max,
});
const getStringValue = (formData: FormData, key: string): string => {
  const value = formData.get(key);
  if (typeof value !== 'string') {
    throw new Error(`Expected a string value for key '${key}', but received a different type.`);
  }
  return value;
};
const getStringArrayValue = (formData: FormData, key: string): string[] => {
  const values = formData.getAll(key);
  if (values.some((v) => typeof v !== 'string')) {
    throw new Error(
      `Expected an array of strings for key '${key}', but received a different type or one of the values is not a string.`,
    );
  }
  return values as string[];
};

export const extractFormData = (
  formData: FormData,
  showFuzzyDate: boolean,
  shmarker: string,
): PreparedData => {
  const data: PreparedData = {
    marker: shmarker,
    origin: {
      iata: getStringValue(formData, 'origin_data'),
      name: getStringValue(formData, 'origin_data'),
    },
    destination: {
      iata: getStringValue(formData, 'destination_data'),
      name: getStringValue(formData, 'destination_data'),
    },
    one_way: formData.get('one_way') === 'on',
    months: formData.getAll('months').length ? getStringArrayValue(formData, 'months') : undefined,
    vacation_duration:
      showFuzzyDate && formData.get('one_way') !== 'on'
        ? {
            min: parseInt(getStringValue(formData, 'vacation_duration_from'), 10),
            max: parseInt(getStringValue(formData, 'vacation_duration_to'), 10),
          }
        : undefined,
    depart_date: showFuzzyDate ? undefined : getStringValue(formData, 'depart_date'),
    return_date:
      showFuzzyDate || formData.get('one_way') === 'on'
        ? undefined
        : getStringValue(formData, 'return_date'),
    max_price:
      parseInt(getStringValue(formData, 'max_price'), 10) === MAX_PRICE_RANGE.max
        ? undefined
        : parseInt(getStringValue(formData, 'max_price'), 10),
    max_flight_duration:
      parseInt(getStringValue(formData, 'max_flight_duration'), 10) ===
      MAX_FLIGHT_DURATION_RANGE.max
        ? undefined
        : parseInt(getStringValue(formData, 'max_flight_duration'), 10),
    max_stops:
      parseInt(getStringValue(formData, 'max_stops'), 10) === MAX_STOPS_RANGE.max
        ? undefined
        : parseInt(getStringValue(formData, 'max_stops'), 10),
  };
  return removeUndefinedProperties(data);
};
