import { useState, useEffect } from 'preact/hooks';

import { emitter } from '../event_emitter/initialized';

export const journeyIdChangedEventName = (widgetId: string) => `${widgetId}_journey-id_changed`;

export const listenJourneyId = (widgetId: string, handler: (id: string) => void) => {
  emitter.addListener(journeyIdChangedEventName(widgetId), handler);
  return () => emitter.removeListener(journeyIdChangedEventName(widgetId), handler);
};

export const setJourneyId = (newJourneyIds: Record<string, string>) => {
  Object.keys(newJourneyIds).forEach((widgetId) => {
    emitter.emitEvent(journeyIdChangedEventName(widgetId), [newJourneyIds[widgetId]]);
  });
};

export const useJourneyId = (widgetId: string) => {
  const [id, setId] = useState<string | undefined>(undefined);

  useEffect(() => listenJourneyId(widgetId, setId), [widgetId]);

  return id;
};
