import { h, FunctionalComponent } from 'preact';
import { Suspense, lazy } from 'preact/compat';

import { ICalendarProps } from './calendar.types';

const CalendarWidget = lazy(() => import('./calendar'));

const Loader: FunctionalComponent = () => (
  <div className="loader-wrapper">
    <img
      alt="alt info"
      className="loader"
      width={150}
      height={200}
      src="https://tp.media/cascoon/schedule_loader.svg"
    />
  </div>
);

export const Calendar: FunctionalComponent<ICalendarProps> = (props: ICalendarProps) => {
  const { children } = props;
  const renderLoader = () => <Loader />;
  return (
    <div className="calendar-lazy-wrapper">
      <Suspense fallback={renderLoader()}>
        <CalendarWidget
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        >
          {children}
        </CalendarWidget>
      </Suspense>
    </div>
  );
};
