import { FunctionalComponent } from 'preact';

import './styles.css';

type AgreementProps = {
  locale: string;
  mainText: string;
  linkText: string;
};

export const Agreement: FunctionalComponent<AgreementProps> = ({ locale, mainText, linkText }) => {
  return (
    <p className="sbscr-agreement">
      {mainText}
      <br />
      <a
        href={`https://support.travelpayouts.com/hc/${
          locale === 'ru' ? locale : 'en'
        }/articles/360004121052`}
        rel="noreferrer"
        target="_blank"
      >
        {linkText}
      </a>
    </p>
  );
};
