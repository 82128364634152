import { FunctionalComponent } from 'preact';

import { withDataReceiver } from '../../lib/with_data_receiver';
import { isNonEmptyString } from '../../../shared/lib/utils';

import { TextProps, TextViewProps } from './text.types';

const TextView: FunctionalComponent<TextViewProps> = ({
  id,
  translations,
  class: className,
  receivedData: { value },
}) => {
  const textValue = isNonEmptyString(value) ? value : translations.value;

  return (
    <div
      id={id}
      className={`form-text ${className}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: textValue }}
      data-testid={`text-${id}`}
    />
  );
};

export const Text = withDataReceiver<TextProps>(TextView);
