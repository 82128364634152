import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';

import { InputProps } from './input.types';

import './index.css';

export const Input: FunctionComponent<InputProps> = ({
  hidden,
  pattern,
  no_labels: noLabels,
  translations,
  id,
  value: defaultValue,
  required,
}) => {
  const [value, setValue] = useState<string>(defaultValue || '');
  const setCustomValidity = (event: Event) => {
    if (!translations.pattern) return;
    const input = event.target as HTMLInputElement;

    input.setCustomValidity(translations.pattern);
  };

  const clearCustomValidity = (event: Event) => {
    const input = event.target as HTMLInputElement;

    input.setCustomValidity('');
  };
  const onChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    setValue(input.value);
  };
  const Label: FunctionComponent = () => {
    return <label htmlFor={id}>{translations.label}</label>;
  };

  return (
    <div className="input-wrapper">
      {translations.label && !noLabels ? <Label /> : ''}
      <input
        id={id}
        className="input-wrapper__input"
        type="text"
        required={required}
        pattern={pattern}
        placeholder={translations.placeholder}
        onInvalid={setCustomValidity}
        onInput={clearCustomValidity}
        value={value}
        onChange={onChange}
      />
      <input type="hidden" name={hidden.name} value={value} />
    </div>
  );
};
