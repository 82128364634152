import { FunctionalComponent } from 'preact';
import { memo } from 'preact/compat';
import dayjs from 'dayjs';
import { CaptionProps, useDayPicker, useNavigation } from 'react-day-picker';

import { clsx } from '../../../../shared/lib/clsx';
import { ArrowSvg } from '../../calendar/calendar_navbar/arrow_svg';

const disabledClassName = 'rdp-nav_button_disabled';

export const DatePickerCaption: FunctionalComponent<CaptionProps> = memo(({ id, displayMonth }) => {
  const { displayMonths, goToMonth, previousMonth, nextMonth } = useNavigation();
  const { formatters, dir, numberOfMonths, classNames } = useDayPicker();

  const dayjsDisplayMonth = dayjs(displayMonth);
  const label = formatters.formatCaption(displayMonth);

  const displayIndex = displayMonths.findIndex((month) =>
    dayjs(month).isSame(dayjsDisplayMonth, 'month'),
  );

  let isFirst = displayIndex === 0;
  let isLast = displayIndex === displayMonths.length - 1;

  const hideNext = numberOfMonths > 1 && (isFirst || !isLast);
  const hidePrevious = numberOfMonths > 1 && (isLast || !isFirst);

  if (dir === 'rtl') {
    [isLast, isFirst] = [isFirst, isLast];
  }

  const previousClassName = clsx(
    classNames.nav_button,
    dir === 'rtl' ? classNames.nav_button_next : classNames.nav_button_previous,
    {
      [disabledClassName]: !previousMonth,
    },
  );

  const nextClassName = clsx(
    classNames.nav_button,
    dir === 'rtl' ? classNames.nav_button_previous : classNames.nav_button_next,
    {
      [disabledClassName]: !nextMonth,
    },
  );

  const handlePreviousClick = () => {
    if (previousMonth) {
      goToMonth(previousMonth);
    }
  };

  const handleNextClick = () => {
    if (nextMonth) {
      goToMonth(nextMonth);
    }
  };

  if (!nextMonth && !previousMonth) {
    return null;
  }

  return (
    <div className={classNames.caption}>
      <div className={classNames.caption_label} aria-live="polite" aria-atomic="true" id={id}>
        {label}
      </div>
      <div className={classNames.nav}>
        {!hidePrevious && (
          <div className={previousClassName} onClick={handlePreviousClick}>
            <ArrowSvg className="rdp-nav_icon" />
          </div>
        )}
        {!hideNext && (
          <div className={nextClassName} onClick={handleNextClick}>
            <ArrowSvg className="rdp-nav_icon" />
          </div>
        )}
      </div>
    </div>
  );
});
