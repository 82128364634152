import { useMemo } from 'preact/hooks';
import { FunctionalComponent } from 'preact';

import { withDataReceiver } from '../../lib/with_data_receiver';
import { tpMediaLinkWrapper } from '../../lib/tp_media_link_wrapper';
import { isString } from '../../../shared/lib/utils';

import { ButtonLinkProps, ButtonLinkViewProps } from './button_link.types';

import './index.css';

const ButtonLinkView: FunctionalComponent<ButtonLinkViewProps> = (props) => {
  const { receivedData, id, translations, widget_id: widgetId, redirect } = props;
  const finalHref = useMemo(() => {
    const { href } = receivedData;

    // В первые разы может прийти undefined
    if (redirect && href !== undefined) {
      try {
        return tpMediaLinkWrapper(href, props);
      } catch (error) {
        return href;
      }
    }

    return href;
  }, [receivedData, redirect]);

  const t = isString(translations) ? translations : translations.text;
  const html = { __html: receivedData.value || t };

  return (
    <a // eslint-disable-line jsx-a11y/control-has-associated-label
      rel="noreferrer"
      id={`${id}_${widgetId}`}
      className="button_link"
      href={finalHref}
      target="_blank"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={html}
    />
  );
};
export const ButtonLink = withDataReceiver<ButtonLinkProps>(ButtonLinkView);
