import { useCallback, useContext } from 'preact/hooks';

import { blisseyParamsContext } from '../../../blissey/blissey_params_context';
import { clsx } from '../../../../../../shared/lib/clsx';
import { Formatter } from '../../../../../lib/formatter';
import { useTranslations } from '../../../../../lib/use_translations';

import './styles.css';

export const Price = ({
  oldPrice,
  discount,
  price,
  compact,
}: {
  oldPrice: number;
  discount: number;
  price: number;
  compact: boolean;
}) => {
  const { t } = useTranslations();
  const { currency } = useContext(blisseyParamsContext);

  const priceFormatter = useCallback((rawPrice: number) => {
    return Formatter.money(rawPrice, currency);
  }, []);

  return (
    <div className={clsx('price', compact && 'price_compact')}>
      <div className="price__period">{t('info.price_per_night_from')}</div>
      {oldPrice && (
        <div className="price__old">
          <div className="price__old-value">{priceFormatter(oldPrice)}</div>
          <div className="price__discount">{`-${discount}%`}</div>
        </div>
      )}

      {price && <div className="price__final">{priceFormatter(price)}</div>}
    </div>
  );
};
