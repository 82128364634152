import numeral from 'numeral';
import dayjs from 'dayjs';

import { IDayjsTranslations } from '../types/config/translations.types';

import { dayjsLocalisation } from './dayjs_localisation';
import { currencies } from './currency';

const allCurriensMap: Record<string, string> = Object.keys(currencies).reduce((acc, key) => {
  return { ...acc, ...currencies[key as 'left' | 'right'] };
}, {});
const numeralOptions = {
  delimiters: {
    thousands: String.fromCharCode(8239),
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number: number) {
    return number === 1 ? 'er' : 'ème';
  },
};

const usdNumeralOptions = {
  ...numeralOptions,
  delimiters: {
    ...numeralOptions.delimiters,
    thousands: ',',
  },
};

const currencyDirection = (currency: string) =>
  (Object.keys(currencies) as Array<keyof typeof currencies>).find((direction) =>
    Object.keys(currencies[direction]).some((currencyFromList) => currencyFromList === currency),
  );

(Object.keys(currencies) as Array<keyof typeof currencies>).forEach((direction) => {
  const currency = currencies[direction];
  (Object.keys(currency) as Array<keyof typeof currency>).forEach((currencySymbol) => {
    if (currencySymbol === 'usd') {
      numeral.register('locale', currencySymbol, {
        ...usdNumeralOptions,
        currency: {
          symbol: currency[currencySymbol],
        },
      });
    } else {
      numeral.register('locale', currencySymbol, {
        ...numeralOptions,
        currency: {
          symbol:
            direction === 'left'
              ? `${currency[currencySymbol]}${String.fromCharCode(8201)}`
              : `${String.fromCharCode(8201)}${currency[currencySymbol]}`,
        },
      });
    }
  });
});

export class Formatter {
  static number(value: number) {
    return numeral(value).format('0,0');
  }

  static decimal(value: number) {
    return numeral(value).format('0.0');
  }

  static money(value: number, currency: string) {
    numeral.locale(currency);

    const formatType = currencyDirection(currency.toLowerCase()) === 'left' ? '$0,0' : '0,0$';
    const result = numeral(value).format(formatType);

    return result;
  }

  static transformCurrency(currency: string) {
    return allCurriensMap[currency];
  }

  static date(
    value: dayjs.ConfigType,
    format: string,
    customizedLocaleObject?: IDayjsTranslations,
  ) {
    const localeObject = customizedLocaleObject ? dayjsLocalisation(customizedLocaleObject) : 'en';

    return dayjs(value).locale(localeObject).format(format);
  }

  static percent(value: number) {
    return numeral(value).format('0.00%');
  }

  static decimalPercent(value: number) {
    return `${numeral(value).format('0.00')}%`;
  }

  static time(value: Date) {
    return numeral(value).format('00:00');
  }

  static reduceAmount = (
    value: number,
    amount: number,
    translation: string,
    currency: string,
    precision = 1,
  ): string => {
    const direction = currencyDirection(currency);
    const currencySign = allCurriensMap[currency];
    const preparedValue = `${
      Math.floor((Number(value) / amount) * precision) / precision
    }${translation.toLocaleLowerCase()}`;

    return direction === 'left'
      ? `${currencySign}${String.fromCharCode(8201)}${preparedValue}`
      : `${preparedValue}${String.fromCharCode(8201)}${currencySign}`;
  };
}
