import { FunctionalComponent } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

import { noop } from '../../../../shared/lib/utils';
import Arrow from '../icons/subscr_arrow.svg';

import './styles.css';

type PriceAlertProps = {
  show: boolean;
  title: string;
  description: string;
};

export const PriceAlert: FunctionalComponent<PriceAlertProps> = ({ show, title, description }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) return noop;
    const alertElement = ref.current;

    if (show) {
      alertElement.classList.add('sbscr-price-alert--show');
    } else {
      alertElement.classList.add('sbscr-price-alert--hide');
      setTimeout(() => alertElement.style.setProperty('display', 'none'), 500);
    }

    return noop;
  }, [ref, show]);

  return (
    <div className="sbscr-price-alert" ref={ref}>
      <h4 className="sbscr-price-alert__header">{title}</h4>
      <Arrow className="sbscr-price-alert__arrow" />
      <p className="sbscr-price-alert__text">{description}</p>
    </div>
  );
};
