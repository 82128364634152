import { FunctionalComponent } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { useSwipeable } from 'react-swipeable/es';

import { useSlideIndex } from './lib/use_slide_index';
import { ImageSliderBullets } from './image_slider_bullets';
import { imagePreloader } from './lib/image_preloader';

import './styles.css';

type ImageSliderProps = {
  images: string[];
  width?: number | string; // default - 100%
  height?: number | string; // default - 100%
  startIndex?: number; // default - 0
  slideDuration?: number; // default - 300 ms
  autoPlay?: boolean; // default - true
  autoPlayDelay?: number; // default - 3000 ms
};

const getImageSlideStyles = (url: string | null, duration: number, idx: number) => ({
  transition: `${duration / 1000}s`,
  backgroundImage: url ? `url(${url})` : '',
  transform: `translate3d(${idx * 100}%, 0px, 0px)`,
});

export const ImageSlider: FunctionalComponent<ImageSliderProps> = ({
  images,
  width = '100%',
  height = '100%',
  startIndex = 0,
  slideDuration = 300,
  autoPlay = true,
  autoPlayDelay = 3000,
}) => {
  if (!images.length) {
    return null;
  }

  const {
    slideIdx,
    updateSlideIdx,
    isRightDirection,
    getNextLoopingIdx,
    previousSlideIdx,
    setPaused,
  } = useSlideIndex({
    imageCount: images.length,
    startIndex,
    autoPlay,
    autoPlayDelay: autoPlayDelay + slideDuration,
  });

  const [currentSliderStyle, setCurrentSliderStyle] = useState(
    getImageSlideStyles(images[startIndex], slideDuration, 0),
  );
  const [nextSliderStyle, setNextSliderStyle] = useState(
    getImageSlideStyles(images[startIndex + 1], slideDuration, 1),
  );
  const isSlidingRef = useRef(false);

  const handleClick = useCallback(() => {
    if (isSlidingRef.current) {
      return;
    }

    updateSlideIdx(slideIdx + 1);
  }, [slideIdx, updateSlideIdx]);

  const handleClickBullets = useCallback(
    (idx: number) => {
      if (idx === slideIdx || isSlidingRef.current) {
        return;
      }

      updateSlideIdx(idx);
    },
    [slideIdx, updateSlideIdx],
  );

  const handleSwipeRight = useCallback(() => {
    if (isSlidingRef.current) {
      return;
    }

    updateSlideIdx(slideIdx - 1);
  }, [slideIdx, updateSlideIdx]);

  const handleSwipeLeft = useCallback(() => {
    if (isSlidingRef.current) {
      return;
    }

    updateSlideIdx(slideIdx + 1);
  }, [slideIdx, updateSlideIdx]);

  const swipableHandlers = useSwipeable({
    onSwipedRight: handleSwipeRight,
    onSwipedLeft: handleSwipeLeft,
  });

  useEffect(() => {
    if (slideIdx === previousSlideIdx) {
      return;
    }

    const currentUrl = images[getNextLoopingIdx(isRightDirection ? slideIdx - 1 : slideIdx + 1)];
    const nextUrl: string = images[slideIdx];
    const currentOffsetX: 1 | -1 = isRightDirection ? -1 : 1;
    const nextReadyOffsetX: 1 | -1 = isRightDirection ? 1 : -1;

    setNextSliderStyle(getImageSlideStyles(nextUrl, 0, nextReadyOffsetX));
    setTimeout(() => {
      isSlidingRef.current = true;
      setCurrentSliderStyle(getImageSlideStyles(currentUrl, slideDuration, currentOffsetX));
      setNextSliderStyle(getImageSlideStyles(nextUrl, slideDuration, 0));
    }, 50);
  }, [slideIdx, isRightDirection]);

  const handleSlideEnd = useCallback(() => {
    isSlidingRef.current = false;
    imagePreloader.load(images[slideIdx + 2]);
    setCurrentSliderStyle(getImageSlideStyles(images[slideIdx], 0, 0));
  }, [slideIdx]);

  const pauseAutoPlay = () => {
    setPaused(true);
  };

  const resumeAutoPlay = () => {
    setPaused(false);
  };

  return (
    <div className="image-slider" style={{ width, height }}>
      {/* Render Slider */}
      <div
        className="image-slider__slider"
        onClick={handleClick}
        onMouseEnter={pauseAutoPlay}
        onMouseLeave={resumeAutoPlay}
        onTouchStart={pauseAutoPlay}
        onTouchEnd={resumeAutoPlay}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...swipableHandlers}
      >
        <div
          className="image-slider__slide"
          style={currentSliderStyle}
          onTransitionEnd={handleSlideEnd}
        />
        {images.length > 1 && <div className="image-slider__slide" style={nextSliderStyle} />}
      </div>

      {/* Render Bullets */}
      {images.length > 1 && (
        <ImageSliderBullets
          length={images.length}
          currentIdx={slideIdx}
          onClickBullets={handleClickBullets}
        />
      )}
    </div>
  );
};
