import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';

import { clsx } from '../../../../../shared/lib/clsx';
import { Icon, IconType } from '../icon';
import { categoriesTranslationsMap } from '../../constants';
import { useTranslations } from '../../../../lib/use_translations';

import './styles.css';

type CategorySelectProps = {
  categories: string[];
  activeCategory: string;
  onCategoryChange: (string: string) => void;
};

const getCategoryTranslationKey = (key: string) => {
  return categoriesTranslationsMap[key as keyof typeof categoriesTranslationsMap];
};

export const CategoryDropdown = ({
  categories,
  activeCategory,
  onCategoryChange,
}: CategorySelectProps) => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslations();

  return (
    <nav className="category-dropdown">
      <div className="category-dropdown__hitarea" onClick={() => setVisible(true)}>
        <Icon type="burger" height={14} />
        {t(`tabs.${getCategoryTranslationKey(activeCategory)}`)}
      </div>
      {visible && (
        <ul className="category-dropdown__categories">
          {categories.map((category) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              className={clsx(
                'category-dropdown__category',
                activeCategory === category && 'category-dropdown__category_active',
              )}
              onClick={() => {
                onCategoryChange(category);
                setVisible(false);
              }}
            >
              <Icon type={category as IconType} height={14} />
              {t(`tabs.${getCategoryTranslationKey(category)}`)}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export const CategorySelect = ({
  categories,
  activeCategory,
  onCategoryChange,
}: CategorySelectProps) => {
  const { t } = useTranslations();

  return (
    <Fragment>
      <CategoryDropdown
        categories={categories}
        activeCategory={activeCategory}
        onCategoryChange={onCategoryChange}
      />
      <nav className="category-select">
        {categories.map((category) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            className={clsx(
              'category-select__category',
              activeCategory === category && 'category-select__category_active',
            )}
            onClick={() => onCategoryChange(category)}
          >
            <Icon type={category as IconType} height={14} />
            {t(`tabs.${getCategoryTranslationKey(category)}`)}
          </li>
        ))}
      </nav>
    </Fragment>
  );
};
