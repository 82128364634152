import fetchJsonp from 'fetch-jsonp';

import { delayAsyncFunction } from '../../../widget/lib/delay_promise';

import { CancellationError, RequestError } from './request_errors';
import {
  FetchData,
  Get,
  Method,
  Post,
  Put,
  RequestErrorContext,
  RequestParams,
  RequestService,
  ServiceResult,
} from './types';

const FIRST_SERVER_STATUS_CODE = 400;

const fetchData: FetchData = async ({
  href,
  options,
  attempts = 1,
  handleError,
  timeout = 300,
  jsonp = false,
}) => {
  const context: RequestErrorContext = { request: null, response: null };
  try {
    context.request = {
      url: href,
      ...options,
    };

    context.response = jsonp
      ? ((await fetchJsonp(href, options)) as Response)
      : await fetch(href, options);

    const { response } = context;

    if (!jsonp && !response.ok) {
      const attemptsNumber = attempts - 1;

      if (attemptsNumber > 0 && response.status >= FIRST_SERVER_STATUS_CODE) {
        const delayedFetchData = delayAsyncFunction(fetchData, timeout);

        return delayedFetchData({ href, options, attempts: attemptsNumber, handleError });
      }

      throw new Error(`HTTP ${response.status} status`);
    }

    return response.json();
  } catch (err: any) {
    if (handleError) {
      handleError(new RequestError(err.message, context));
    }

    if (err.name === 'AbortError') {
      throw new CancellationError(err);
    }

    throw new RequestError(err.message, context);
  }
};

export const createRequestService: RequestService = ({
  url,
  headers = {},
  credentials,
  handleError,
}) => {
  const makeRequest = <T>({
    method,
    path,
    params,
    body,
    jsonp = false,
    attempts,
    timeout,
    headers: requestHeaders = {},
  }: RequestParams): ServiceResult<T> => {
    const abortController = new AbortController();

    const mergedHeaders = { ...headers, ...requestHeaders };

    const options: RequestInit = {
      method,
      credentials,
      headers: mergedHeaders,
      signal: abortController.signal,
    };

    if (options.method === Method.Post || options.method === Method.Put) {
      options.body = JSON.stringify(body);
    }

    const trimmedUrl = url.replace(/\/$/, '');
    let trimmedPath = path.replace(/^\//, '');
    trimmedPath = trimmedPath.length > 0 ? `/${trimmedPath}` : trimmedPath;

    const fullUrl = new URL(`${trimmedUrl}${trimmedPath}`);

    if (params) {
      fullUrl.search = new URLSearchParams(params).toString();
    }

    const request = fetchData<T>({
      href: fullUrl.href,
      options,
      attempts,
      handleError,
      timeout,
      jsonp,
    });
    const cancel = () => abortController.abort();

    return {
      request,
      cancel,
    };
  };

  const get: Get = (args) => makeRequest({ method: Method.Get, ...args });
  const post: Post = (args) => makeRequest({ method: Method.Post, ...args });
  const put: Put = (args) => makeRequest({ method: Method.Put, ...args });

  return { get, post, put };
};
