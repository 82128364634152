import { FunctionalComponent } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

import { emitter } from '../../lib/event_emitter/initialized';
import { isString } from '../../../shared/lib/utils';
import { WidgetParams } from '../../types/config/params.types';
import { GlobalHiddens } from '../../types/config/globals.types';

import { FormProps } from './form.types';

import './index.css';

export const Form: FunctionalComponent<FormProps> = ({
  settings,
  metrics,
  globals,
  widget,
  children,
  params,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { url, method, target, hiddens } = globals;
  const {
    events,
    globals: { styles },
  } = settings.config;

  useEffect(() => {
    if (events) {
      Object.keys(events).forEach((eventName) => {
        let event = events[eventName];
        let preparedEventName = eventName;

        //* ATTENTION: workaround for the widget constructor
        if (isString(event)) {
          const redirectUrl = event;

          preparedEventName = 'submit';

          event = function submit() {
            eval(redirectUrl); // eslint-disable-line
          };
        }

        formRef.current.addEventListener(preparedEventName, event);
      });
    }
  }, []);

  useEffect(() => {
    const { current } = formRef;

    if (current !== null && styles) {
      Object.keys(styles).forEach((styleKey) => {
        current.style.setProperty(styleKey, styles[styleKey], 'important');
      });
    }
  }, [formRef]);

  return (
    <form
      id="root"
      ref={formRef}
      onSubmit={(event) => {
        emitter.emitEvent(`formSubmit-${widget.id}`, [event]);
        metrics.send('lead', {});
      }}
      className="root search-form"
      action={url}
      method={method || 'GET'}
      target={target || '_blank'}
      data-testid="form"
    >
      {children}
      {hiddens &&
        (Object.keys(hiddens) as Array<keyof GlobalHiddens>).map((key) => {
          const value = hiddens[key];
          let inputValue = value;

          // TODO: надо подумать как обойтись без кастования
          if (value && params && params[value as keyof WidgetParams]) {
            inputValue = params[value as keyof WidgetParams];
          }

          // Временное решение для удаления параметра trs из ссылки перехода для виджетов с типом формы, когда значение trs в коде для вставки отсутствует.
          if (value === 'trs' && inputValue === 'trs') {
            return null;
          }

          return <input type="hidden" name={key} value={inputValue} />;
        })}
    </form>
  );
};
