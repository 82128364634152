import { FunctionalComponent } from 'preact';
import { useRef, useEffect } from 'preact/hooks';

import { withDataReceiver } from '../../lib/with_data_receiver';
import { isString } from '../../../shared/lib/utils';

import { ImageBlockProps, ImageBlockViewProps } from './image_block.types';
import './index.css';

const ImageBlockView: FunctionalComponent<ImageBlockViewProps> = ({
  id,
  src,
  href,
  receivedData,
  class: className,
  widget_id: widgetId,
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const preparedHref = (isString(receivedData.href) && receivedData.href) || href;

  useEffect(() => {
    linkRef.current.style.setProperty(
      'background-image',
      `url(${receivedData.src || src})`,
      'important',
    );
  }, []);

  return (
    <a //eslint-disable-line
      ref={linkRef}
      id={id}
      href={preparedHref}
      target="_blank"
      className={`image-block ${className}`}
      data-testid={`image_block_${id}_${widgetId}`}
    />
  );
};

export const ImageBlock = withDataReceiver<ImageBlockProps>(ImageBlockView);
