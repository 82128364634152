import { useMemo, useState } from 'preact/hooks';

import { ControlledMultipleRange } from '../../../range';
import { useTranslations } from '../../../../lib/use_translations';

import { RangeRuler } from './range_ruler/range_ruler';

import './styles.css';

export const VacationDurationSelect = ({
  duration: { min: defaultMin, max: defaultMax },
}: {
  duration: { min: number; max: number };
}) => {
  const [vacationDuration, setVacationDuration] = useState([defaultMin, defaultMax]);
  const { t } = useTranslations('subscription.popup');

  const tooltipText = useMemo(() => {
    const [min, max] = vacationDuration;

    return `${t('subscription_card.info.vacation_duration')}: ${t('vacation_duration', {
      min,
      max,
      count: max,
    })}`;
  }, [vacationDuration, t]);

  return (
    <div className="vacation-duration">
      <div className="vacation-duration__tooltip">{tooltipText}</div>
      <div>
        <ControlledMultipleRange
          min={1}
          max={30}
          step={1}
          value={vacationDuration}
          hiddenFields={{
            min: 'from',
            max: 'to',
            from: 'vacation_duration_from',
            to: 'vacation_duration_to',
          }}
          testId="vacation-duration"
          onChange={setVacationDuration}
        />
        <div className="vacation-duration__ruler">
          <RangeRuler min={1} max={30} />
        </div>
      </div>
    </div>
  );
};
