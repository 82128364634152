import { FunctionalComponent, h } from 'preact';
import { lazy, Suspense } from 'preact/compat';
import { useMemo } from 'preact/hooks';

import { ConnectedLayoutComponent } from '../../lib/connect_to_context';
import { DataProviderProps } from '../../lib/data_provider';

import { IMapProps } from './map.types';

const ReactMapbox = lazy(() => import('../react_mapbox/index'));

const Loader: FunctionalComponent = () => (
  <div className="loader-wrapper">
    <img
      alt="alt info"
      className="loader"
      width={150}
      height={200}
      src="https://tp.media/cascoon/schedule_loader.svg"
    />
  </div>
);

export const Map: ConnectedLayoutComponent<IMapProps> = (props) => {
  const {
    widget,
    showCommonError,
    showNothingFoundError,
    metrics,
    id,
    globals: { isAB },
    settings,
    field,
    layoutConfig,
    errorsHandler,
    children,
  } = props;

  const providerProps = useMemo(() => {
    const childProps: DataProviderProps = {
      config: settings.config,
      widget,
      showCommonError,
      showNothingFoundError,
      metrics,
    };
    return childProps;
  }, []);

  const { height, width } = widget.params;
  const styleWidth = width ? `${width}px` : '100vw';
  const styleHeight = height ? `${height}px` : '100vh';

  const renderLoader = () => <Loader />;

  return (
    <div className="map-lazy-wrapper" style={{ width: styleWidth, height: styleHeight }}>
      <Suspense fallback={renderLoader()}>
        <ReactMapbox
          id={id}
          isAB={isAB}
          field={field}
          settings={settings}
          widget={widget}
          breakpoints={layoutConfig.breakpoints}
          errorsHandler={errorsHandler}
          providerProps={providerProps}
        >
          {children}
        </ReactMapbox>
      </Suspense>
    </div>
  );
};
