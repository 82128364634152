import { Fragment, FunctionalComponent, h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { makeNumber } from '../../../../shared/lib/utils';
import { clsx } from '../../../../shared/lib/clsx';
import { device } from '../../../lib/device';
import Cross from '../../../icons/close.svg';

import { ListProps } from './list.types';
import { ListItem } from './list_item';
import { RadioButtonList } from './radio_list';
import { prepareHiddens, prepareSubFieldsCount } from './utils';
import './index.css';

export const List: FunctionalComponent<ListProps> = ({
  fields,
  translations,
  radio,
  handleTotalChange,
  handleListClose,
  hiddens: hiddensProp,
  isOpen,
  maxTotal,
  total,
  iconColor,
}) => {
  const [subFieldsCount, setSubFieldsCount] = useState(prepareSubFieldsCount(fields));
  const [hiddens, setHiddens] = useState(prepareHiddens(fields, hiddensProp));
  const {
    fields: fieldsTranslations,
    radio: radioTranslations,
    label: listTitleTranslations,
    dayjs: { close: closeButtonTranslations },
  } = translations;

  const updateHiddens = (fieldName: string, value: string | number) => {
    setHiddens((prevHiddens) => ({
      ...prevHiddens,
      [fieldName]: { ...prevHiddens[fieldName], value },
    }));
  };

  useEffect(() => {
    if (isOpen && device.isMobile()) {
      document.body.classList.add('cascoon-no-scroll-mobile');
      return () => {
        document.body.classList.remove('cascoon-no-scroll-mobile');
      };
    }
    return undefined;
  }, [isOpen]);

  const handleChange = (fieldName: string, newCount: number, diff: number) => {
    const subField = fields.find(({ field_name: name }) => name === fieldName)?.sub_field;
    let newHiddenValue: number | string = newCount;

    if (subField) {
      const updatedFieldCount =
        diff === -1
          ? subFieldsCount[fieldName].slice(0, -1)
          : [...subFieldsCount[fieldName], makeNumber(subField.value)];
      const newSubFieldsCount = { ...subFieldsCount, [fieldName]: updatedFieldCount };

      newHiddenValue = updatedFieldCount.join(',');
      setSubFieldsCount(newSubFieldsCount);
    }

    updateHiddens(fieldName, newHiddenValue);

    handleTotalChange(diff);
  };

  const handleSubFieldChange = (subFieldName: string, newCount: number) => {
    const fieldName = subFieldName.split('_')[0];
    const fieldIdx = Number(subFieldName.split('_')[1]);

    const fieldToUpdate = subFieldsCount[fieldName];
    const updatedFieldCount = [
      ...fieldToUpdate.slice(0, fieldIdx),
      newCount,
      ...fieldToUpdate.slice(fieldIdx + 1),
    ];
    const newFieldsCount = { ...subFieldsCount, [fieldName]: updatedFieldCount };

    const newHiddenValue = updatedFieldCount.join(',');

    updateHiddens(fieldName, newHiddenValue);
    setSubFieldsCount(newFieldsCount);
  };

  return (
    <div
      className={clsx(
        'select-list',
        isOpen && 'select-list--display',
        device.isMobile() && 'select-list--mobile',
      )}
      data-testid="counters-select-list"
    >
      <h2 className="select-list-title">{listTitleTranslations}</h2>
      <button type="button" className="select-cross-button" onClick={handleListClose}>
        <Cross className="select-cross" />
      </button>
      <ul className="select-list-wrapper">
        {fields.map(
          ({
            field_name: fieldName,
            max_value: maxValue,
            min_value: minValue,
            value,
            sub_field,
          }) => (
            <Fragment>
              <ListItem
                handleChange={handleChange}
                key={fieldName}
                translations={fieldsTranslations[fieldName]}
                hidden={hiddens[fieldName]}
                fieldName={fieldName}
                maxValue={maxValue}
                minValue={minValue}
                value={value}
                total={total}
                maxTotal={maxTotal}
                iconColor={iconColor}
              />
              {sub_field && subFieldsCount[fieldName].length !== 0 && (
                <ul
                  className="select-list-sub-list"
                  data-testid={`counters-select-sub-list-${fieldName}`}
                >
                  {subFieldsCount[fieldName].map((_, index) => {
                    const sfListItemName = `${fieldName}_${index}`;
                    const sfTranslations = fieldsTranslations[sub_field.field_name];
                    const { max_value: sfMaxVal, min_value: sfMinVal, value: sfVal } = sub_field;

                    return (
                      <ListItem
                        handleChange={handleSubFieldChange}
                        key={sfListItemName}
                        translations={sfTranslations}
                        fieldName={sfListItemName}
                        maxValue={sfMaxVal}
                        minValue={sfMinVal}
                        value={sfVal}
                        iconColor={iconColor}
                      />
                    );
                  })}
                </ul>
              )}
            </Fragment>
          ),
        )}
      </ul>
      {radio && radioTranslations && (
        <div className="select-list-radio">
          <RadioButtonList translations={radioTranslations} items={radio} />
        </div>
      )}
      <button type="button" className="select-close-button" onClick={handleListClose}>
        {closeButtonTranslations}
      </button>
    </div>
  );
};
