import * as plurals from 'make-plural';

type PluralCategory = plurals.PluralCategory;
type PluralsFunctions = Record<string, (count: number | string) => PluralCategory>;

export const pluralisation = (
  locale: string,
  count: number | string,
  messages: Partial<Record<PluralCategory, string>>,
): string => {
  if (count === 0 && messages.zero) return messages.zero;

  const shortLocaleCode = locale.slice(0, 2);
  const pluralCategoryDetector = (plurals as PluralsFunctions)[shortLocaleCode];

  if (!pluralCategoryDetector) {
    throw new Error(`No plural category detector found for ${shortLocaleCode}`);
  }

  const pluralCategory = pluralCategoryDetector(count);

  const message = messages[pluralCategory];

  if (!message) {
    throw new Error(`No message found for ${shortLocaleCode}`);
  }

  return message.replace('%{count}', String(count));
};

export type Pluralisation = typeof pluralisation;
