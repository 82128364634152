import { useReducer } from 'preact/hooks';

import { DataState } from '../types';

import * as Actions from './actions';
import { ActionType } from './types';

type Action = ReturnType<
  | typeof Actions.subscriptionsReceived
  | typeof Actions.subscriptionCreated
  | typeof Actions.subscriptionUpdated
  | typeof Actions.subscriptionDeleted
  | typeof Actions.subscriptionFrozen
  | typeof Actions.resentEmailConfirmation
  | typeof Actions.subscribedToNews
  | typeof Actions.error
  | typeof Actions.emailResendError
  | typeof Actions.loading
>;

const initialState: DataState = {
  subscriptions: [],
  active: false,
  recieveNews: false,
  loading: false,
  error: null,
  emailResendError: null,
};

const reducer = (state: DataState, action: Action) => {
  switch (action.type) {
    case ActionType.SubscriptionsReceived:
      return {
        ...state,
        subscriptions: action.payload.subscriptions.slice().reverse(),
        active: action.payload.active,
        recieveNews: action.payload.recieve_news,
        error: null,
        loading: false,
      };
    case ActionType.SubscriptionCreated:
    case ActionType.SubscriptionUpdated:
    case ActionType.SubscriptionDeleted:
    case ActionType.SubscriptionFrozen:
      return {
        ...state,
        subscriptions: action.payload.subscriptions.slice().reverse(),
        error: null,
        loading: false,
      };
    case ActionType.EmailConfirmationResent:
      return {
        ...state,
        emailResendError: null,
        loading: false,
      };
    case ActionType.SubscribedToNews:
      return {
        ...state,
        recieveNews: action.payload,
        error: null,
        loading: false,
      };

    case ActionType.EmailResendError:
      return {
        ...state,
        emailResendError: action.payload,
        loading: false,
      };
    case ActionType.Error:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ActionType.Loading:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export const useDataReducer = () => useReducer(reducer, initialState);
