import './public-path';
import './revision';
import './index.css';

import locator from '@travelpayouts/frontend-service-locator';

import { launchWidget } from './lib/init/launch_widget';
import { cascoonInitService } from './lib/init/cascoon_init_service';
import { emitter } from './lib/event_emitter/initialized';
import { cascoonFrontendApi } from './lib/cascoon_frontend_api/cascoon_frontend_api';

cascoonInitService.registerEmitter(emitter);
cascoonInitService.registerLaunchFn(launchWidget);
locator.register('cascoon', cascoonFrontendApi);
