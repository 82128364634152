import { useCallback, useContext } from 'preact/hooks';

import { getPropertyByPath } from '../../shared/lib/utils';

import { DataContext } from './data_provider';
import { pluralisation } from './pluralisation';
import { templateReplacer } from './template_replacer';

type Options = Record<string, string | number>;
export type Translator = ReturnType<typeof useTranslations>['t'];

export const useTranslations = (namespace?: string) => {
  const { translations, locale } = useContext(DataContext).config;
  const { language, dayjs, ...strings } = translations;

  const t = useCallback((path: string, options?: Options) => {
    let translation = namespace
      ? getPropertyByPath(strings, `${namespace}.${path}`)
      : getPropertyByPath(strings, path);

    const { count, ...restOptions } = options || {};

    if (count !== undefined && typeof translation === 'object') {
      translation = pluralisation(locale, count, translation as Record<string, string>);
    }

    if (typeof translation === 'string') {
      return Object.keys(restOptions) ? templateReplacer(translation, restOptions) : translation;
    }
    return path;
  }, []);

  return {
    t,
    translations,
    language,
    dayjs,
    locale,
    strings,
  };
};
