/**
 * В процессе рендера лэйаута виджета учавствует WidgetConfig.layout и WidgetConfig.fields
 * Fields - это массив объектов с уникальными type, содержащих название Реакт-компонента для рендера и параметров для его рендера
 * Layout описывает в каком порядке и как будут отрендерены Fields
 * Layout может отрендерить один и тот же Field несколько раз в разных местах, размерах и выравниваниях, если это потребуется
 * Layout так же может содержать вложенные Layout в каждом из своих узлов
 *
 * Для рендера лэйаута сначала составляется дерево реакт-элементов
 * Рекурсивно проходясь по Layout и его вложенным Layout мы каждому узлу Layout находим соответствующий Field, из Field достаем
 * реакт-компонент для рендера и параметры и создаем [React-]элементы из этого компонента и пропсов
 *
 * Готовое дерево элементов отправляется на рендер
 */

import { FunctionalComponent } from 'preact';

import { WidgetField, WidgetFields } from '../../types/config/fields.types';
import { WidgetConfig } from '../../types/config/config.types';
import {
  Breakpoint,
  WidgetLayout,
  WidgetLayoutNode,
  WidgetLayoutNodeWithParams,
} from '../../types/config/layout.types';
import { IWidget } from '../../types/widget.types';
import { connectedComponents as components } from '../../components';

export type ComponentWrapperProps = {
  className: string;
  id: string;
  'data-field-name': string;
  'data-testid': string;
};

/**
 * Существуют узлы лэйаута, которые не нужно рендерить, эта функция определяет нужно ли рендерить конкретный узел
 */
const shouldSkipLayoutNode = (
  item: WidgetLayoutNodeWithParams,
  config: WidgetConfig,
  widget: IWidget,
) => {
  const plain = widget.params.plain === 'true';
  return plain && config.globals.complicating_fields?.includes(item.id);
};

/**
 * Создание имен классов для компонентов
 * Эти классы будут навешаны на сам элемент узла лэйаута или на его враппер
 */
export const createClassName = (
  groupItem: WidgetLayoutNodeWithParams,
  field: WidgetField,
  breakpoint: Breakpoint,
  initialClassname = '',
) => {
  let align = groupItem.align || field?.layout?.align;
  const direction = field?.params?.direction || 'ltr';

  if (direction === 'rtl') {
    if (align === 'left') {
      align = 'right';
    } else if (align === 'right') {
      align = 'left';
    }
  }

  let order = 1;
  if (groupItem.order !== undefined) {
    order = groupItem.order;
  } else if (field && field.layout && field.layout.order) {
    order = field.layout.order;
  }

  let className = groupItem.nested ? `cascoon-node` : `cascoon-leaf`;
  className = `${className} cascoon-${breakpoint}--${groupItem.width} align--${align || 'left'}`;

  if ((align !== 'left' && direction === 'ltr') || (align !== 'right' && direction === 'rtl')) {
    className = align !== undefined ? `${className} ${breakpoint}--${align}` : className;
  }

  if (order !== 1) {
    className = `${className} cascoon-${breakpoint}--order_${order}`;
  }

  return initialClassname ? `${initialClassname} ${className}` : className;
};

/**
 * Создание React-элементов для конкретного узла лэйаута
 * Если у узла есть вложенный лэйаут - рекурсивно создаются элементы для его узлов
 * И вкладываются в текущий
 */
function createLayoutItem(
  item: WidgetLayoutNode,
  fields: WidgetFields,
  config: WidgetConfig,
  widget: IWidget,
  breakpoint: Breakpoint,
  ComponentWrapper?: any,
): JSX.Element {
  let renderedComponent;

  if (!item.nested) {
    let field = config.fields[item.id];
    if (!field && item.id.indexOf('empty') === 0) field = { type: 'EmptyBlock' };

    if (field) {
      if (components[field.type]) {
        const Component = components[field.type];

        renderedComponent = (
          <Component class={`${item.id}`} id={item.id} field={field} style={field.params?.style} />
        );
      } else {
        renderedComponent = (
          <div>
            Missed component for
            {field.type}
          </div>
        );
      }
    } else {
      renderedComponent = (
        <div>
          Missed Field for
          {item.id}
        </div>
      );
    }

    const className = createClassName(item, fields[item.id], breakpoint);

    const wrapperProps = {
      id: item.id,
      key: item.id,
      className,
      'data-field-name': item.id,
      'data-testid': item.id,
    };

    if (ComponentWrapper) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ComponentWrapper {...wrapperProps}>{renderedComponent}</ComponentWrapper>;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <div {...wrapperProps}>{[renderedComponent]}</div>;
  }

  const field = config.fields[item.id];
  const Component = field ? components[field.type] : components.Wrapper;

  const className = createClassName(item, fields[item.id], breakpoint, `${item.id}`);

  return (
    <Component
      id={item.id}
      field={field}
      data-testid={item.id}
      class={className}
      style={field?.params?.style}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      {createLayoutTree(item.nested, fields, breakpoint, config, widget, ComponentWrapper)}
    </Component>
  );
}

/**
 * Создание Реакт-элементов лэйаута
 */
export function createLayoutTree(
  layout: WidgetLayout,
  fields: WidgetFields,
  breakpoint: Breakpoint,
  config: WidgetConfig,
  widget: IWidget,
  ComponentWrapper?: FunctionalComponent<ComponentWrapperProps>,
): JSX.Element[] {
  return layout
    .flat(2)
    .filter((item) => !shouldSkipLayoutNode(item, config, widget))
    .map((item) => createLayoutItem(item, fields, config, widget, breakpoint, ComponentWrapper));
}
