import { IRequestError } from '../../../../shared/lib/request';

export type SubscriptionsBaseParams = {
  email: string;
  host: string;
  marker: string;
};

export type CreateSubscriptionParams = {
  subscription: SubscriptionRequest;
};
export type UpdateSubscriptionParams = CreateSubscriptionParams;
export type subscribeToNewsParams = {
  receiveNews: boolean;
};

export enum SubscriptionState {
  Active = 'active',
  Frozen = 'iced',
  Disabled = 'disabled',
  Old = 'old',
}

type VacationDuration = { min: number; max: number };

type SubscriptionResponse = {
  id: number;
  marker: string;
  state: SubscriptionState;
  destination?: {
    iata: string;
    name: string;
  };
  origin?: {
    iata: string;
    name: string;
  };
  one_way: boolean;
  depart_date?: Date | string; // foramtted as YYYY-MM-DD
  return_date?: Date | string; // foramtted as YYYY-MM-DD
  months?: number[];
  vacation_duration?: VacationDuration;
  max_flight_duration?: number;
  max_price?: number;
  max_stops?: number;
};

export type SubscriptionRequest = Omit<SubscriptionResponse, 'id' | 'state'>;

export type SubscriptionsResponseData = {
  active: boolean;
  recieve_news: boolean;
  subscriptions: SubscriptionResponse[];
};

export type Error = string | null | IRequestError;

export type DataState = {
  subscriptions: SubscriptionResponse[];
  active: boolean;
  recieveNews: boolean;
  loading: boolean;
  error: Error;
  emailResendError: Error;
};
