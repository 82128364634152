import { FunctionalComponent } from 'preact';
import { useMemo, useEffect } from 'preact/hooks';
import './index.css';

import { tpMediaLinkWrapper } from '../../lib/tp_media_link_wrapper';
import { isFunction } from '../../../shared/lib/utils';

import { useMaxImageSize } from './utils/use_max_image_size';
import { LinkImageProps } from './link_image.types';

export const LinkImage: FunctionalComponent<LinkImageProps> = (props) => {
  const {
    id,
    href,
    img,
    fallback_img: fallbackImg,
    target = '_blank',
    show_logo: showLogo = 'true',
    translations,
    redirect,
    widget_id: widgetId,
    className,
    url: urlParam,
  } = props;

  const elementId = `${id}_${widgetId}`;
  const testId = `link_image_${elementId}`;

  const { width, url: imageUrl } =
    useMemo(() => {
      const show = showLogo && showLogo !== 'false';

      const image = img ?? {
        url: urlParam,
        width: props.width,
      };

      return show ? image : fallbackImg;
    }, [img, showLogo, fallbackImg]) ?? {};

  const { ref: imageRef, width: calculatedWidth, height: calculatedHeight } = useMaxImageSize(
    width,
  );

  const url = useMemo(() => {
    return isFunction(imageUrl) ? imageUrl(props) : imageUrl;
  }, [imageUrl, props]);

  const redirectUrl = useMemo(() => {
    return isFunction(href) ? href(props) : href;
  }, [href, props]);

  useEffect(() => {
    const image = imageRef.current;
    if (image) {
      image.style.setProperty('width', `${calculatedWidth}px`, 'important');
      image.style.setProperty('height', `auto`, 'important');
    }
  }, [imageRef]);

  return url ? (
    <a
      onClick={(event) => {
        if (redirect) {
          const link = event.currentTarget;

          link.href = tpMediaLinkWrapper(redirectUrl, props);
        }
      }}
      id={elementId}
      href={redirectUrl}
      target={target}
      className={`form-link-image ${className}`}
      data-testid={testId}
    >
      <span>
        <img
          src={url}
          ref={imageRef}
          alt={translations.alt}
          className="form-link-image__image"
          width={calculatedWidth}
          height={calculatedHeight}
        />
      </span>
    </a>
  ) : null;
};
