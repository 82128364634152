import { h, Fragment, FunctionComponent } from 'preact';

import { ImageSlider } from '../image_slider';

import { InformationProps } from './information_block.types';
import './information_block.css';

export const InformationBlock: FunctionComponent<InformationProps> = ({
  data,
  hotel,
  plurals,
  locale,
}) => (
  <Fragment>
    <div className="chansey-slider">{data && <ImageSlider imageUrls={data.image_urls} />}</div>
    <div className="chansey-information">
      <div className="hotel-type-group">
        <div className="stars">{Array(data?.hotel_stars).fill(<div className="star" />)}</div>
        <div className="hotel-type">{data?.hotel_type}</div>
      </div>
      <h1 className="chansey-title">{data?.hotel_name}</h1>
      <div className="location">
        <div className="location-icon" />
        <a className="location-link" href={data?.map_url}>
          {`${data?.city_name}, ${data?.country_name}`}
        </a>
      </div>
      <div className="raiting">
        <div className="raiting-group">
          <div className="raiting-value">{data?.rating}</div>
          <div className="raiting-reviews">
            {data?.reviews_count &&
              `${hotel.review} ${plurals(locale, data?.reviews_count, hotel.reviews_plural)}`}
          </div>
        </div>
        <div className="raiting-icon" />
        <div className="raiting-tags">
          {data?.tags.map((tag) => (
            <div className="raiting-tag">{tag}</div>
          ))}
        </div>
      </div>
    </div>
  </Fragment>
);
