/* eslint-disable react/destructuring-assignment, react/jsx-props-no-spreading */
import { FunctionalComponent, h } from 'preact';
import { useMemo, useState, useEffect } from 'preact/hooks';

import { isBoolean } from '../../../shared/lib/utils';
import { Autocomplete } from '../autocomplete';
import { clsx } from '../../../shared/lib/clsx';

import { CombinedAutocompleteProps, CombinedBoolean } from './combined_autocomplete.types';
import { SwapPlaces } from './swap_places';
import './index.css';

const extractFlag = (flag: CombinedBoolean, key: string): boolean => {
  return isBoolean(flag) ? flag : flag[key];
};

export const CombinedAutocomplete: FunctionalComponent<CombinedAutocompleteProps> = ({
  id,
  translations,
  value = {},
  hiddens = {},
  required = false,
  geoip_default: geoIpDefault = {},
  fetch_default: fetchDefault = false,
  fetch_on_focus: fetchOnFocus = false,
  color_icons: iconColor,
  ...props
}) => {
  const [originId, destinationId] = id.split('_and_');
  const originHiddens = hiddens[originId];
  const destinationHiddens = hiddens[destinationId];
  const originFetchDefault = extractFlag(fetchDefault, originId);
  const destinationFetchDefault = extractFlag(fetchDefault, destinationId);
  const originFetchOnFocus = extractFlag(fetchOnFocus, originId);
  const destinationFetchOnFocus = extractFlag(fetchOnFocus, destinationId);
  const originRequired = extractFlag(required, originId);
  const destinationRequired = extractFlag(required, destinationId);

  const [originValue, setOriginValue] = useState(value[originId]);
  const [destinationValue, setDestinationValue] = useState(value[destinationId]);

  useEffect(() => {
    setDestinationValue(value[destinationId]);
    setOriginValue(value[originId]);
  }, [value[originId], value[destinationId]]);

  const showSwapper = useMemo(() => originValue && destinationValue, [
    originValue,
    destinationValue,
  ]);

  const handleClick = () => {
    setOriginValue(destinationValue);
    setDestinationValue(originValue);
  };

  return (
    <div
      className={clsx('combined-autocomplete', {
        'combined-autocomplete--with_labels': !props.no_labels,
      })}
    >
      <Autocomplete
        {...props}
        id={originId}
        name={originId}
        value={originValue}
        hiddens={originHiddens}
        translations={translations[originId]}
        required={originRequired}
        fetch_default={originFetchDefault}
        fetch_on_focus={originFetchOnFocus}
        geoip_default={geoIpDefault[originId]}
        onSelected={setOriginValue}
      />
      {showSwapper && <SwapPlaces onClick={handleClick} iconColor={iconColor} />}
      <Autocomplete
        {...props}
        id={destinationId}
        name={destinationId}
        value={destinationValue}
        hiddens={destinationHiddens}
        translations={translations[destinationId]}
        required={destinationRequired}
        fetch_default={destinationFetchDefault}
        fetch_on_focus={destinationFetchOnFocus}
        geoip_default={geoIpDefault[destinationId]}
        onSelected={setDestinationValue}
      />
    </div>
  );
};
