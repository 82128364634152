import { emitter } from '../../../lib/event_emitter/initialized';

type Api = {
  getValue: <T>(fieldId: string, widgetId: string, callback: (data: T) => void) => void;
  setValue: <T>(fieldId: string, widgetId: string, callback: (data: T) => void) => void;
  dataUpdated: <T>(fieldId: string, widgetId: string, data: T) => void;
  inputDisabled: <T>(fieldId: string, widgetId: string, callback: (data: T) => void) => void;
};

export const api: Api = {
  getValue(fieldId, widgetId, callback) {
    emitter.addListener(`${widgetId}_${fieldId}_get_value`, callback);
  },
  setValue(fieldId, widgetId, callback) {
    emitter.addListener(`${widgetId}_${fieldId}_set_value`, callback);
  },
  dataUpdated(fieldId, widgetId, data) {
    emitter.emitEvent(`${widgetId}_${fieldId}_data_updated`, [data]);
  },
  inputDisabled(fieldId, widgetId, callback) {
    emitter.addListener(`inputDisabled-${widgetId}--${fieldId}`, callback);
  },
};
