import { FunctionalComponent } from 'preact';

import { WrapperProps } from './wrapper.types';

export const Wrapper: FunctionalComponent<WrapperProps> = ({
  id,
  class: className,
  children,
  'data-testid': testid = 'wrapper',
}) => {
  return (
    <div id={id} className={`wrapper ${className}`} data-testid={testid}>
      {children}
    </div>
  );
};
