import { ResponseHotel } from '../api/types';
import { IParams } from '../../../types/node.types';
import { IContextValues } from '../../../lib/connect_to_context';

export enum ViewType {
  Compact = 'compact',
  Full = 'full',
}

export type BlisseyParams = IParams &
  IContextValues & {
    viewType: ViewType;
    limit: number;
    nobooking: boolean;
    categories?: string[];
    currency: string;
    locale: string;
    host: string;
    language: string;
    utm_source: string;
    marker?: string;
    locationId?: number;
    hotelsId?: number[];
    iata?: string;
    linkTarget: string;
  };

export type Hotel = ResponseHotel & {
  location?: string;
  location_id?: number;
  url: string;
};

export type BlisseyParamsWithLocationId = BlisseyParams & { locationId: number };

export type BlisseyParamsWithHotelsId = BlisseyParams & { hotelsId: string[] };

export type BlisseyParamsWithIATA = BlisseyParams & { iata: string };

export type HotelsData = Hotel[];

export type HotelsByCategoriesData = { hotels: Record<string, Hotel[]>; categories: Array<string> };

export const isBlisseyParamsWithLocationId = (
  params: BlisseyParams,
): params is BlisseyParamsWithLocationId => 'locationId' in params;

export const isBlisseyParamsWithHotelsId = (
  params: BlisseyParams,
): params is BlisseyParamsWithHotelsId => 'hotelsId' in params;

export const isBlisseyParamsWithIATA = (params: BlisseyParams): params is BlisseyParamsWithIATA =>
  'iata' in params;
