let originalState = '';

export const setBodyScrollFreezeState = (state: boolean) => {
  if (state) {
    originalState = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
  }

  if (!state) {
    document.body.style.overflow = originalState;
  }
};
