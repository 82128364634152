import { h, FunctionComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import dayjs from 'dayjs';

import { DateRangePicker } from '../datepicker';
import { clsx } from '../../../shared/lib/clsx';
import { normalizeBoolean } from '../../../shared/lib/utils';
import { createWidgetRequestService } from '../../lib/widget_request';

import { InformationBlock } from './information_block';
import { useChanseyReducer } from './reducer';
import { ResultLinks } from './result_links';
import { InfoLoader, ButtonLoader } from './loaders';
import { RedirectBookingURLParams, HotelOffer, ChanseyProps, HotelData } from './chansey.types';

import './chansey.css';

const BOOKING_URL =
  'https://yasen.hotellook.com/adaptors/location_deeplink?flags[utm]=tp_form_prod';

const getLocationId = (data: HotelData | null): string => {
  if (data && data.map_url) {
    const urlObj = new URL(data.map_url);
    return urlObj.searchParams.get('locationId') || '';
  }
  return '';
};

const convertToStrings = (params: RedirectBookingURLParams): Record<string, string> => {
  return Object.entries(params).reduce(
    (obj, [key, value]) => ({ ...obj, [key]: String(value) }),
    {},
  );
};

const createQueryString = (params: RedirectBookingURLParams): string => {
  return new URLSearchParams(convertToStrings(params)).toString();
};

const redirectToBookingUrl = (params: RedirectBookingURLParams) => {
  const queryParams = createQueryString(params);
  window.open(`${BOOKING_URL}&${queryParams}`, '_blank');
};

export const Chansey: FunctionComponent<ChanseyProps> = (props) => {
  const {
    nobooking = '',
    translations,
    plurals,
    locale,
    hotel_id: hotelId = '',
    currency,
    shmarker,
    id,
    wrapper,
    layoutConfig,
    widget_id: widgetId,
  } = props;

  const finalNobooking = nobooking !== '' ? nobooking : false;
  const finalLocale = locale !== '' ? locale : 'en';
  const finalHotelId = hotelId !== '' ? hotelId : 414901;
  const finalCurrency = currency !== '' ? currency : 'usd';

  const { hotel, search } = translations;
  const [state, dispatch] = useChanseyReducer();

  useEffect(() => {
    const hotellookHotelInfoService = createWidgetRequestService({
      url: 'https://internal.travelpayouts.com/hotels_info',
      handleError: (error) => {
        dispatch({ type: 'SET_ERROR', payload: error });
        dispatch({ type: 'SET_LOADING', payload: false });
      },
    });
    const fetchHotelData = async () => {
      const data = await hotellookHotelInfoService.get<string>({
        jsonp: true,
        path: '',
        params: {
          hotel_id: finalHotelId.toString(),
          locale: finalLocale,
          currency: finalCurrency,
          marker: shmarker,
          v: '1',
        },
      }).request;
      dispatch({ type: 'SET_DATA', payload: JSON.parse(data) });
    };
    fetchHotelData();
  }, []);

  const handleSubmit = async (event: Event) => {
    event.preventDefault();

    const { checkin, checkout } = event.currentTarget as HTMLFormElement;

    if (checkin && checkout) {
      const checkInDate = checkin.value;
      const checkOutDate =
        checkInDate === checkout.value
          ? dayjs(checkInDate).add(1, 'day').format('YYYY-MM-DD')
          : checkout.value;

      const dates = {
        startDate: new Date(checkin.value),
        endDate: new Date(checkout.value),
      };

      const locationId: string = getLocationId(state.data);

      const shouldRedirect = !normalizeBoolean(finalNobooking);

      if (shouldRedirect) {
        const urlParams: RedirectBookingURLParams = {
          gateId: 2,
          selectedHotelId: finalHotelId,
          language: finalLocale,
          locationId: parseInt(locationId, 10),
          checkIn: checkInDate,
          checkOut: checkOutDate,
          children: 0,
          marker: shmarker,
          adults: 1,
          currency: finalCurrency,
          utm_source: 'tp_widget_hotel',
          skipRulerCheck: 'skip',
        };

        redirectToBookingUrl(urlParams);
        return;
      }

      dispatch({ type: 'SET_DATES', payload: dates });
      dispatch({ type: 'SET_RESULT_LOADING', payload: true });

      const hotellookCheckHotelPriceService = createWidgetRequestService({
        url: 'https://yasen.hotellook.com/tp/v1/check_hotel_price.json',
        handleError: (error) => {
          dispatch({ type: 'SET_ERROR', payload: error });
          dispatch({ type: 'SET_RESULT_LOADING', payload: false });
        },
      });

      const data = await hotellookCheckHotelPriceService.get<HotelOffer[]>({
        path: '',
        params: {
          hotel_id: finalHotelId.toString(),
          Id: '0',
          locale: finalLocale,
          v: '1',
          host: 'chansey.hotellook.com',
          limit: '4',
          currency: finalCurrency,
          marker: shmarker,
          nobooking: finalNobooking.toString(),
          checkin: checkInDate,
          checkout: checkOutDate,
        },
      }).request;

      dispatch({ type: 'SET_RESULT', payload: data });
    }
  };

  const resultNoExists = state.error || (state.result && !state.result.length);

  const chanseyFormClass = clsx('chansey-form', {
    'chansey-form--hidden': state.result && state.result.length > 0,
  });

  const chanseyFormTitleText = resultNoExists ? search.price_error : search.enter_dates;

  return (
    <div className="chansey-wrapper">
      <div className="chansey-information-wrapper">
        {state.loading ? (
          <InfoLoader />
        ) : (
          <InformationBlock data={state.data} hotel={hotel} plurals={plurals} locale={locale} />
        )}
      </div>
      <div className="chansey-controls">
        {resultNoExists && (
          <div className="error-block">
            <span className="error-block--icon" />
            <span className="error-block--text">{search.no_rooms}</span>
          </div>
        )}
        <form className={chanseyFormClass} onSubmit={handleSubmit}>
          <p className="control-title">{chanseyFormTitleText}</p>
          <DateRangePicker
            to_name=""
            from_name=""
            layoutConfig={layoutConfig}
            widget_id={widgetId}
            wrapper={wrapper}
            id={id}
            translations={{
              to_label: '',
              from_label: '',
              date_format: search.date_format,
              dayjs: translations.dayjs,
              from_placeholder: search.datepicker_placeholder.checkin,
              to_placeholder: search.datepicker_placeholder.checkout,
            }}
            hiddens={{ format: 'YYYY-MM-DD', from_name: 'checkin', to_name: 'checkout' }}
          />
          <button type="submit" className="button" disabled={state.resultLoading}>
            {state.resultLoading ? <ButtonLoader /> : search.show_prices}
          </button>
        </form>
        {state.result && state.result.length > 0 && (
          <ResultLinks
            result={state.result}
            params={props}
            search={search}
            onClick={() => dispatch({ type: 'SET_RESULT', payload: null })}
            plurals={plurals}
            dates={state.dates}
            locale={locale}
          />
        )}
      </div>
    </div>
  );
};
