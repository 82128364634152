import { FunctionalComponent } from 'preact';

import './index.css';
import { SubmitProps } from './submit.types';

export const Submit: FunctionalComponent<SubmitProps> = ({
  id,
  translations,
  no_labels: noLabeles,
  widget_id: widgetId,
}) => {
  const realId = `${id}_${widgetId}`;
  const testId = `button_${realId}`;

  return (
    <button
      id={realId}
      className={`form-submit ${noLabeles ? 'form-submit--no-label' : ''}`}
      type="submit"
      data-testid={testId}
    >
      <span className="form-submit__content">{translations.text}</span>
    </button>
  );
};
