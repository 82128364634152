import { FunctionalComponent } from 'preact';

import { clsx } from '../../../../../shared/lib/clsx';
import Plane from '../../icons/plane_solid.svg';
import Snowflake from '../../icons/snowflake_regular.svg';
import SolidClose from '../../icons/circle_xmark_solid.svg';
import AtSign from '../../icons/at_sign.svg';
import Pen from '../../icons/pen_solid.svg';
import FrownFace from '../../icons/face_frown_regular.svg';
import Trash from '../../icons/trash_solid.svg';
import Droplet from '../../icons/droplet_solid.svg';
import RightLeft from '../../icons/right_left_solid.svg';
import RightArrow from '../../icons/arrow_right_solid.svg';
import Plus from '../../icons/plus_solid.svg';
import Minus from '../../icons/minus_solid.svg';
import Checkmark from '../../icons/check_solid.svg';

import './styles.css';

type IconType =
  | 'plane'
  | 'snowflake'
  | 'sad-smile'
  | 'light-pen'
  | 'dark-pen'
  | 'colored-pen'
  | 'light-trash'
  | 'dark-trash'
  | 'colored-trash'
  | 'freeze'
  | 'unfreeze'
  | 'active-swap-arrows'
  | 'frozen-swap-arrows'
  | 'old-swap-arrows'
  | 'active-arrow-right'
  | 'frozen-arrow-right'
  | 'old-arrow-right'
  | 'circle-xmark'
  | 'at-sign'
  | 'plus-sign'
  | 'minus-sign'
  | 'checkmark';

const getSvg = (type: IconType) => {
  switch (type) {
    case 'plane':
      return <Plane />;
    case 'circle-xmark':
      return <SolidClose />;
    case 'at-sign':
      return <AtSign />;
    case 'sad-smile':
      return <FrownFace />;
    case 'unfreeze':
      return <Droplet />;
    case 'snowflake':
    case 'freeze':
      return <Snowflake />;
    case 'light-pen':
    case 'dark-pen':
    case 'colored-pen':
      return <Pen />;
    case 'light-trash':
    case 'dark-trash':
    case 'colored-trash':
      return <Trash />;
    case 'active-swap-arrows':
    case 'frozen-swap-arrows':
    case 'old-swap-arrows':
      return <RightLeft />;
    case 'active-arrow-right':
    case 'frozen-arrow-right':
    case 'old-arrow-right':
      return <RightArrow />;
    case 'plus-sign':
      return <Plus />;
    case 'minus-sign':
      return <Minus />;
    case 'checkmark':
      return <Checkmark />;
    default:
      return '';
  }
};

type IconProps = {
  type: IconType;
  onClick?: () => void;
};

export const Icon: FunctionalComponent<IconProps> = ({ type, onClick }) => {
  return (
    <span className={clsx('sbscr-icon', `sbscr-icon-${type}`)} onClick={onClick}>
      {getSvg(type)}
    </span>
  );
};
