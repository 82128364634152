type EventHandler<E> = (event: E) => void;

export const composeEventHandlers = <E = Event>(
  internal: EventHandler<E>,
  external?: EventHandler<E>,
): EventHandler<E> => {
  return external
    ? (event) => {
        internal(event);
        external(event);
      }
    : internal;
};
