import { h, FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import { useTranslations } from '../../../../lib/use_translations';
import { Icon } from '../icon';

import './styles.css';

export const Banner: FunctionalComponent<{ onClick: () => void; error: unknown }> = ({
  onClick,
  error,
}) => {
  const { t } = useTranslations('subscription.banner');

  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const handleButtonClick = () => {
    setHasBeenClicked(true);
    onClick();
  };

  const buttonText = error ? t('send_letter_error') : t('send_letter_success');
  const showButton = error !== null || !hasBeenClicked; // Показываем кнопку только если есть ошибка или если еще не было клика
  const showText = error !== null || hasBeenClicked; // Показываем текст только если есть ошибка или если был клик

  return (
    <div className="subscription-banner">
      <div className="subscription-banner-wrapper">
        <Icon type="at-sign" />
        <div className="subscription-banner-info-block">
          <div className="subscription-banner-text">{t('text')}</div>
          <div className="subscription-banner-resend-letter">
            {showText && <span className="subscription-banner-send-text">{buttonText}</span>}
            {showButton && (
              <div className="subscription-banner-send-button" onClick={handleButtonClick}>
                {t('send_letter')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
