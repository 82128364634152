import { h, FunctionalComponent } from 'preact';
import { useCallback } from 'preact/hooks';

import { clsx } from '../../../../../shared/lib/clsx';
import { SubscriptionState } from '../../data';
import { Icon } from '../icon';
import { useTranslations, Translator } from '../../../../lib/use_translations';
import { Formatter } from '../../../../lib/formatter';
import { getPriceText, getDurationText, getStopsText } from '../../lib/get_range_translation';

import { CardInfo } from './card_info/card_info';
import './styles.css';

type SubscriptionProps = {
  state: SubscriptionState;
  origin: string;
  destination: string;
  departDate?: string | Date;
  returnDate?: string | Date;
  currency: string;
  maxPrice?: number;
  maxDuration?: number;
  maxStops?: number;
  oneWay?: boolean;
  months?: number[];
  vacationDuration?: {
    min: number;
    max: number;
  };
  interactDisabled: boolean;
  onEdit: () => void;
  onFreeze: () => void;
  onUnfreeze: () => void;
  onDelete: () => void;
};

const getTitle = (state: SubscriptionState, t: Translator) => {
  switch (state) {
    case SubscriptionState.Disabled:
      return t('subscription_card.status.disabled');
    case SubscriptionState.Frozen:
      return t('subscription_card.status.frozen');
    case SubscriptionState.Old:
      return t('subscription_card.status.outdated');
    default:
      return '';
  }
};

const getVacationDurationText = (min: number, max: number, t: Translator) => {
  return t('vacation_duration', { count: max, min, max });
};

const getTitleIconType = (state: SubscriptionState) => {
  switch (state) {
    case SubscriptionState.Frozen:
      return 'snowflake';
    case SubscriptionState.Old:
      return 'sad-smile';
    default:
      return 'plane';
  }
};

const getEditIconType = (state: SubscriptionState) => {
  switch (state) {
    case SubscriptionState.Frozen:
      return 'colored-pen';
    case SubscriptionState.Old:
      return 'dark-pen';
    default:
      return 'light-pen';
  }
};

const getDeleteIconType = (state: SubscriptionState) => {
  switch (state) {
    case SubscriptionState.Frozen:
      return 'colored-trash';
    case SubscriptionState.Old:
      return 'dark-trash';
    default:
      return 'light-trash';
  }
};

const getSwapArrowsIconType = (state: SubscriptionState) => {
  switch (state) {
    case SubscriptionState.Frozen:
      return 'frozen-swap-arrows';
    case SubscriptionState.Old:
      return 'old-swap-arrows';
    default:
      return 'active-swap-arrows';
  }
};

const getRightArrowIconType = (state: SubscriptionState) => {
  switch (state) {
    case SubscriptionState.Frozen:
      return 'frozen-arrow-right';
    case SubscriptionState.Old:
      return 'old-arrow-right';
    default:
      return 'active-arrow-right';
  }
};

export const SubscriptionCard: FunctionalComponent<SubscriptionProps> = ({
  state,
  origin,
  destination,
  departDate = '',
  returnDate = '',
  currency,
  maxPrice,
  maxDuration,
  maxStops,
  months,
  vacationDuration,
  oneWay = false,
  interactDisabled,
  onEdit,
  onFreeze,
  onUnfreeze,
  onDelete,
}) => {
  const { t, dayjs: dayjsMap } = useTranslations('subscription.popup');

  const freezeButtonText =
    state === SubscriptionState.Frozen
      ? t('subscription_card.unfreeze')
      : t('subscription_card.freeze');
  const freezeButtonIconType = state === SubscriptionState.Frozen ? 'unfreeze' : 'freeze';
  const freezeHandler = state === SubscriptionState.Frozen ? onUnfreeze : onFreeze;
  const showFreezeBtn = state !== SubscriptionState.Disabled && state !== SubscriptionState.Old;

  const isFlexible = Boolean(months);

  const getDateCardInfo = useCallback(() => {
    if (isFlexible) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const monthsNames = months!.map((month) => {
        const monthName = dayjsMap?.monthsShort[month];
        if (!monthName) {
          return '';
        }

        return monthName.charAt(0).toUpperCase() + monthName.slice(1);
      });

      return (
        <CardInfo title={t('subscription_card.info.dates.flexible')}>
          {monthsNames.join(', ')}
        </CardInfo>
      );
    }

    const formattedDepart = departDate && Formatter.date(departDate, 'D MMM YYYY', dayjsMap);
    const formattedReturn = returnDate && Formatter.date(returnDate, 'D MMM YYYY', dayjsMap);

    if (oneWay) {
      return (
        <CardInfo title={t('subscription_card.info.dates.one_way')}>{formattedDepart}</CardInfo>
      );
    }

    return (
      <CardInfo title={t('subscription_card.info.dates.round_trip')}>
        <span>{formattedDepart}</span>
        <span>&#8211;</span>
        <span>{formattedReturn}</span>
      </CardInfo>
    );
  }, [isFlexible, oneWay, departDate, returnDate]);

  const handleEdit = (event: h.JSX.TargetedEvent) => {
    const target = event.currentTarget as HTMLDivElement;
    if (target.ariaDisabled === 'false') {
      onEdit();
    }
  };

  const handleFreeze = (event: h.JSX.TargetedEvent) => {
    const target = event.currentTarget as HTMLDivElement;
    if (target.ariaDisabled === 'false') {
      freezeHandler();
    }
  };

  const handleDelete = (event: h.JSX.TargetedEvent) => {
    const target = event.currentTarget as HTMLDivElement;
    if (target.ariaDisabled === 'false') {
      onDelete();
    }
  };

  return (
    <div
      className={clsx('sbscr-subscription-card', `sbscr-subscription-card--${state.toLowerCase()}`)}
    >
      <div className="sbscr-subscription-card__header">
        <div className="sbscr-subscription-card__title">
          <Icon type={getTitleIconType(state)} />
          <span className="sbscr-subscription-card__title-text">{getTitle(state, t)}</span>
        </div>
        <div
          className={clsx('sbscr-subscription-card__buttons', {
            'sbscr-subscription-card__buttons--disabled': interactDisabled,
          })}
        >
          <div
            role="button"
            className="sbscr-subscription-card__btn"
            onClick={handleEdit}
            tabIndex={0}
            aria-disabled={interactDisabled}
          >
            <Icon type={getEditIconType(state)} />
            <span className="sbscr-subscription-card__btn-text">{t('subscription_card.edit')}</span>
          </div>
          {showFreezeBtn && (
            <div
              role="button"
              className="sbscr-subscription-card__btn"
              onClick={handleFreeze}
              tabIndex={0}
              aria-disabled={interactDisabled}
            >
              <Icon type={freezeButtonIconType} />
              <span className="sbscr-subscription-card__btn-text">{freezeButtonText}</span>
            </div>
          )}
          <div
            role="button"
            className="sbscr-subscription-card__btn"
            onClick={handleDelete}
            tabIndex={0}
            aria-disabled={interactDisabled}
          >
            <Icon type={getDeleteIconType(state)} />
            <span className="sbscr-subscription-card__btn-text">
              {t('subscription_card.delete')}
            </span>
          </div>
        </div>
      </div>
      <div className="sbscr-subscription-card__body">
        <CardInfo title={t('subscription_card.info.direction')}>
          <span>{origin}</span>
          <Icon type={oneWay ? getSwapArrowsIconType(state) : getRightArrowIconType(state)} />
          <span>{destination}</span>
        </CardInfo>
        {getDateCardInfo()}
        {isFlexible && vacationDuration && (
          <CardInfo title={t('subscription_card.info.vacation_duration')}>
            {getVacationDurationText(vacationDuration.min, vacationDuration.max, t)}
          </CardInfo>
        )}
      </div>
      <div className="sbscr-subscription-card__footer">
        <CardInfo title={t('subscription_card.info.price')}>
          {getPriceText(maxPrice, currency, t)}
        </CardInfo>
        <CardInfo title={t('subscription_card.info.flight_duration')}>
          {getDurationText(maxDuration, t)}
        </CardInfo>
        <CardInfo title={t('subscription_card.info.stops')}>{getStopsText(maxStops, t)}</CardInfo>
      </div>
    </div>
  );
};
