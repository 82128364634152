import { useState } from 'preact/hooks';
import { FunctionalComponent } from 'preact';

import ArrowIcon from '../../icons/select_arrow.svg';
import { clsx } from '../../../shared/lib/clsx';

import { SelectProps } from './select.types';
import './index.css';

export const Select: FunctionalComponent<SelectProps> = ({
  id,
  no_labels: noLabels,
  translations,
  widget_id: widgetId,
  options,
  hiddens,
  value: defaultValue,
  color_icons: iconColor,
}) => {
  const defaultOption = defaultValue ?? options?.[0]?.[0] ?? null;

  const selectOptions = options || [['empty', 'empty']];

  const [value, setValue] = useState<string | null>(defaultOption);

  const handleSelectedChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setValue(event.target.value);

  return (
    <div className="select">
      {translations.label && !noLabels && (
        <label htmlFor={`${id}_${widgetId}`} className="select__label">
          {translations.label}
        </label>
      )}
      <select
        className="select__select"
        id={`${id}_${widgetId}`}
        name={id}
        onChange={handleSelectedChange}
      >
        {selectOptions.map((option) => (
          <option value={option[0]} selected={option[0] === value}>
            {translations.fields && translations.fields[option[1]]
              ? translations.fields[option[1]].title
              : option[1]}
          </option>
        ))}

        {value === null && (
          <option value="" disabled selected hidden>
            {translations.placeholder}
          </option>
        )}
      </select>
      <ArrowIcon
        className={clsx('select__arrow-svg', { 'fill-default': iconColor })}
        color={iconColor}
      />

      {hiddens && hiddens.selected !== undefined && (
        <input
          type="hidden"
          name={hiddens.selected}
          value={value === null ? undefined : value}
          data-testid="select-hidden"
        />
      )}
    </div>
  );
};
