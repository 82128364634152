import { ComponentType } from 'preact';

import { DataContext } from '../lib/data_provider';
import { connectToContext } from '../lib/connect_to_context';

import { MultipleRange } from './range';
import { Chansey } from './chansey';
import { Submit } from './submit';
import { Button } from './button';
import { Title } from './title';
import { LinkImage } from './link_image';
import { Checkbox } from './checkbox';
import { EmptyBlock } from './empty_block';
import { Loader } from './loader';
import { TimePicker } from './time_picker';
import { Autocomplete } from './autocomplete';
import { CombinedAutocomplete } from './combined_autocomplete';
import { DatePicker, DatePickerFrom, DatePickerTo, DateRangePicker } from './datepicker';
import { Select } from './select';
import { Map } from './map';
import { Text } from './text';
import { Card } from './card';
import { Form } from './form';
import { List } from './list';
import { CountersSelect } from './counters_select';
import { Booking } from './booking';
import { Link } from './link';
import { Scroll } from './scroll';
import { Wrapper } from './wrapper';
import { ButtonLink } from './button_link';
import { ImageBlock } from './image_block';
import { Mediator } from './mediator';
import { Input } from './input';
import { Tooltip } from './tooltip';
import { Calendar } from './calendar';
import { Tabs } from './tabs';
import { ErrorBanner } from './error_banner';
import { ErrorBoundary } from './error_boundary';
import { Blissey } from './blissey';
import { Subscription } from './subscription';

// eslint-disable-next-line import/prefer-default-export
export const Components: { [index: string]: ComponentType<any> | any } = {
  Input,
  Chansey,
  Mediator,
  MultipleRange,
  Button,
  Tooltip,
  Calendar,
  Link,
  Map,
  Wrapper,
  Scroll,
  List,
  Submit,
  Form,
  LinkImage,
  Error,
  Checkbox,
  Card,
  Title,
  Text,
  Loader,
  EmptyBlock,
  TimePicker,
  Autocomplete,
  CombinedAutocomplete,
  DatePickerFrom,
  DatePickerTo,
  DatePicker,
  Select,
  CountersSelect,
  Booking,
  ButtonLink,
  ImageBlock,
  DateRangePicker,
  Tabs,
  ErrorBanner,
  ErrorBoundary,
  Blissey,
  Subscription,
};

export const connectedComponents: typeof Components = Object.keys(Components).reduce((acc, key) => {
  return {
    ...acc,
    [key]: connectToContext(DataContext, Components[key]),
  };
}, {});
