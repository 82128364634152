const delay = (timeout: number) =>
  new Promise((res) => {
    setTimeout(() => {
      res(null);
    }, timeout);
  });

export const delayAsyncFunction = function delayPromise<T, D>(
  fn: (...args: T[]) => Promise<D>,
  timeout = 0,
) {
  const newPromise = async (...args: T[]) => {
    const promise = fn(...args);
    const wait = delay(timeout);
    const [result] = await Promise.all([promise, wait]);

    return result;
  };

  return newPromise;
};

export const delayPromise = async <T>(promise: Promise<T>, timeout = 0) => {
  const wait = delay(timeout);
  const [result] = await Promise.all([promise, wait]);

  return result;
};
