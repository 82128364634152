import { FunctionalComponent } from 'preact';

import BellSvg from '../icons/bell.svg';

import './styles.css';

export const Bell: FunctionalComponent = () => {
  return (
    <div className="bell-icon">
      <BellSvg className="bell-icon__svg" />
    </div>
  );
};
