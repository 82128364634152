import { Component, ComponentChildren } from 'preact';

export class ErrorBoundary extends Component<{
  children: ComponentChildren;
  onError: (error: Error) => void;
}> {
  componentDidCatch(error: Error) {
    const { onError } = this.props;
    onError(error);
  }

  static getDerivedStateFromError() {
    return {};
  }

  render() {
    const { children } = this.props;

    return children;
  }
}
