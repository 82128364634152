import { h, FunctionComponent } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { ImageSlider as ImageLibSlider } from '../../../lib/image_slider';
import './image_slider.css';

type GallerySizes = {
  width: number;
  height: number;
};

const IMAGE_WIDTH = 320;
const IMAGE_HEIGHT = 200;

/**
 * Подготовка размеров галереи
 * Если ширина не задана, используется значение по умолчанию (320px)
 */
function prepareGalleryImages(image_urls: string[], sizes: GallerySizes): string[] {
  const replaceTemplate = (s: string, value: number) =>
    s.replace(/{{x}}|{{y}}/gi, (value * 2).toString());

  return image_urls
    .slice(0, 5)
    .map((url) => replaceTemplate(replaceTemplate(url, sizes.width), sizes.height));
}

export const ImageSlider: FunctionComponent<{ imageUrls: string[] }> = ({ imageUrls }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [gallerySizes, setGallerySizes] = useState<GallerySizes>({
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  });
  const [images, setImages] = useState<string[]>([]);

  // Используем useEffect для получения offsetWidth после рендера
  useEffect(() => {
    if (wrapperRef.current) {
      const width = wrapperRef.current.offsetWidth || IMAGE_WIDTH;
      setGallerySizes({ width, height: IMAGE_HEIGHT });
    }
  }, []);

  useEffect(() => {
    if (imageUrls) {
      const preparedImages = prepareGalleryImages(imageUrls, gallerySizes);
      setImages(preparedImages);
    }
  }, [imageUrls, gallerySizes]);

  return (
    <div className="chansey-image-slider" ref={wrapperRef}>
      <ImageLibSlider images={images} />
    </div>
  );
};
