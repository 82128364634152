import { IDayjsTranslations } from '../types/config/translations.types';

export const dayjsLocalisation = (translations: IDayjsTranslations) => ({
  weekdays: translations.weekdays,
  weekdaysShort: translations.weekdaysShort,
  weekdaysMin: translations.weekdaysShort,
  name: translations.name,
  months: translations.months,
  monthsShort: translations.monthsShort,
  ordinal: eval(translations.ordinal), // eslint-disable-line no-eval
  relativeTime: translations.relativeTime,
  formats: {},
});
