type AnyArgs = any[];
type AnyFunction = (...args: AnyArgs) => void;

export class EventEmitter {
  private listeners: Record<string, AnyFunction[]> = {};

  private argsList: Record<string, AnyArgs> = {};

  public addListener(eventName: string, cb: AnyFunction) {
    const args = this.argsList[eventName];

    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }

    if (!this.listeners[eventName].includes(cb)) {
      this.listeners[eventName].push(cb);

      // If we have args for this eventName -> send it to the listener
      if (args) {
        this.emitEvent(eventName, args);
      }
    }
  }

  public emitEvent(eventName: string, args: AnyArgs = []) {
    const eventListeners = this.listeners[eventName] ?? [];
    this.argsList[eventName] = args;

    eventListeners.forEach((cb: AnyFunction) => {
      cb(...args);
    });
  }

  public removeListener(eventName: string, cb: AnyFunction) {
    const eventListeners = this.listeners[eventName] ?? [];

    this.listeners[eventName] = eventListeners.filter((eventListener) => eventListener !== cb);
  }

  public removeAllListeners() {
    this.listeners = {};
    this.argsList = {};
  }
}
