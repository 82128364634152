interface IDevice {
  isMobileSize: () => boolean;
  isTouch: () => boolean;
  isDesktopSize: () => boolean;
  isMobile: () => boolean;
}

const MAX_MOBILE_WIDTH = 460;
const MIN_DESKTOP_WIDTH = 1024;

export const device: IDevice = {
  isMobileSize(): boolean {
    return window.innerWidth <= MAX_MOBILE_WIDTH;
  },
  isTouch(): boolean {
    return !!window.TouchEvent;
  },
  isDesktopSize(): boolean {
    return window.innerWidth >= MIN_DESKTOP_WIDTH;
  },

  isMobile(): boolean {
    return (
      window.orientation !== undefined ||
      navigator.userAgent.indexOf('IEMobile') !== -1 ||
      (this.isTouch() && this.isMobileSize())
    );
  },
};
