import zeroStars from './assets/0stars.svg';
import oneStar from './assets/1stars.svg';
import twoStars from './assets/2stars.svg';
import fourStars from './assets/4stars.svg';
import fiveStars from './assets/5stars.svg';
import burger from './assets/burger.svg';
import business from './assets/business.svg';
import center from './assets/center.svg';
import center2 from './assets/center2.svg';
import gay from './assets/gay.svg';
import hanger from './assets/hanger.svg';
import highprice from './assets/highprice.svg';
import kremlinview from './assets/kremlinview.svg';
import water_view from './assets/water_view.svg';
import luxury from './assets/luxury.svg';
import panoramic_view from './assets/panoramic_view.svg';
import pets from './assets/pets.svg';
import pool from './assets/pool.svg';
import popularity from './assets/popularity.svg';
import price from './assets/price.svg';
import rating from './assets/rating.svg';
import restaurant from './assets/restaurant.svg';
import romantic from './assets/romantic.svg';
import russians from './assets/russians.svg';
import smoke from './assets/smoke.svg';
import star from './assets/star.svg';
import wifi from './assets/wifi.svg';
import trustyou from './assets/trustyou.svg';

export const iconsMap = {
  '0stars': zeroStars,
  '1stars': oneStar,
  '2stars': twoStars,
  '4stars': fourStars,
  '5stars': fiveStars,
  business,
  center,
  gay,
  hanger,
  highprice,
  lake_view: water_view,
  luxury,
  panoramic_view,
  pets,
  pool,
  popularity,
  price,
  rating,
  restaurant,
  river_view: water_view,
  russians,
  sea_view: water_view,
  smoke,
  tophotels: popularity,
  distance: center,
  'center 2': center2,
  burger,
  kremlinview,
  romantic,
  star,
  wifi,
  location: center,
  trustyou,
};
