import { FunctionalComponent } from 'preact';
import { useRef } from 'preact/hooks';
import { memo } from 'preact/compat';
import { DayProps, useDayPicker, useDayRender } from 'react-day-picker';
import dayjs from 'dayjs';

import { clsx } from '../../../../shared/lib/clsx';

export const DatePickerDay: FunctionalComponent<DayProps> = memo(({ displayMonth, date }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useDayRender(date, displayMonth, ref);
  const {
    onClick,
    onMouseEnter,
    disabled: disabledButton,
    className,
    children,
    ...restButtonProps
  } = buttonProps;
  const { classNames } = useDayPicker();
  const isSameMonth = dayjs(displayMonth).isSame(dayjs(date), 'month');
  const disabled = disabledButton || !isSameMonth;

  // any потому что не хочется тут типы реакта тащить
  const handleMouseEnter = (event: any) => {
    if (onMouseEnter && !disabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onMouseEnter(event);
    }
  };

  const handleClick = (event: any) => {
    if (onClick && !disabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick(event);
    }
  };

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      type="button"
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      className={clsx(classNames.button, classNames.button_reset, className)}
      disabled={disabled}
      {...restButtonProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      {isSameMonth ? children : null}
    </div>
  );
});
