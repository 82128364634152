import dayjs from 'dayjs';
import { FunctionalComponent } from 'preact';
import { useMemo, useState } from 'preact/hooks';

import { withMonthsNumber } from '../utils/with_months_number';
import { getDisabledDays } from '../utils/get_disabled_days';
import { isFunction } from '../../../../shared/lib/utils';
import { DatePickerInput } from '../date_picker_input';

import { DatePickerProps } from './date_picker.types';
import '../index.css';

const DatePickerView: FunctionalComponent<DatePickerProps> = (props) => {
  const {
    id,
    name,
    hiddens,
    disable_days_before,
    disable_days_after,
    translations,
    hidden,
    no_labels,
    required = false,
    disablable,
    monthsNumber,
    direction,
    value,
    value_format,
    first_day,
    color_icons,
    widget_id,
  } = props;
  const realId = `${id}_${widget_id}`;
  const { firstDay } = translations.dayjs;

  const initalFromValue = isFunction(value) ? value(dayjs) : value;

  const [date, setDate] = useState(initalFromValue);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleFromChange = (newFrom?: Date) => {
    setIsPopupOpen(false);
    setDate(newFrom);
  };

  const handleInputClick = () => {
    setIsPopupOpen(true);
  };

  const handleDateClear = () => {
    setDate(undefined);
  };

  const disabled = useMemo(() => getDisabledDays(disable_days_before, disable_days_after), [
    disable_days_before,
    disable_days_after,
  ]);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const calendarTitle = translations.label || translations.placeholder;

  return (
    <DatePickerInput
      id={realId}
      className="form-datepicker--from"
      value={date}
      translations={translations}
      name={name}
      hidden={hidden}
      required={required}
      onPopupOpen={handlePopupOpen}
      onPopupClose={handlePopupClose}
      hiddens={hiddens}
      showLabel={!no_labels}
      valueFormat={value_format}
      disablable={disablable}
      isPopupOpen={isPopupOpen}
      onInputClick={handleInputClick}
      onValueClear={handleDateClear}
      calendarTitle={calendarTitle}
      dayPickerProps={{
        mode: 'single',
        selected: date,
        disabled,
        dir: direction,
        numberOfMonths: monthsNumber,
        onSelect: handleFromChange,
        weekStartsOn: first_day || firstDay || 0,
      }}
      iconColor={color_icons}
    />
  );
};

export const DatePicker = withMonthsNumber(DatePickerView);
