import PlaneSvg from '../icons/plane.svg';
import PlaneShadowSvg from '../icons/plane_shadow.svg';

import './styles.css';

export const Plane = () => {
  return (
    <div
      className="plane-svg"
      ref={(el) => el && setTimeout(() => el.classList.add('plane-svg--show'), 500)}
    >
      <PlaneSvg className="plane-svg__body" />
      <PlaneShadowSvg className="plane-svg__shadow" />
    </div>
  );
};
