import { FunctionalComponent } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { emitter } from '../../lib/event_emitter/initialized';
import { clsx } from '../../../shared/lib/clsx';
import Checkmark from '../../icons/checkmark.svg';

import './index.css';

import { CheckboxProps, ValueType } from './checkbox.types';

export const Checkbox: FunctionalComponent<CheckboxProps> = (props) => {
  const {
    class: className,
    hiddens,
    filled = false,
    inverted,
    translations,
    name,
    id,
    reverse,
    widget_id,
    link_id,
    checked = false,
    value: propValue,
    values_map: valuesMap = { true: true, false: false },
    color_icons: iconColor,
  } = props;
  const extractValueFromMap = (isChecked: boolean) => valuesMap[isChecked.toString()];
  const valueToBool = (value: ValueType) => {
    const extractedKey = Object.keys(valuesMap).find((key) => valuesMap[key] === value);
    return extractedKey === 'true';
  };

  const defaultChecked = propValue !== undefined ? propValue === 'true' || false : checked;
  const defaultUseValue = reverse ? !defaultChecked : defaultChecked;
  const [innerValue, setInnerValue] = useState(extractValueFromMap(defaultUseValue));
  const [innerChecked, setInnerChecked] = useState(defaultChecked);

  useEffect(() => {
    const useValue = reverse ? !innerChecked : innerChecked;
    setInnerValue(extractValueFromMap(useValue));
  }, [innerChecked]);

  const apiDataUpdated = () => {
    emitter.emitEvent(`${widget_id}_${link_id}_data_updated`, [
      { active: innerChecked, value: innerValue },
    ]);
  };

  const setValueThruApi = () => {
    emitter.addListener(
      `${widget_id}_${link_id}_set_value`,
      (emitterChecked: boolean | undefined) => {
        if (typeof emitterChecked === 'boolean') {
          setInnerChecked(emitterChecked);
        }
      },
    );
  };
  const getValueThruApi = () => {
    emitter.addListener(
      `${widget_id}_${link_id}_get_value`,
      (resolve: (data: { active: boolean; value: ValueType }) => void) => {
        return resolve({ active: innerChecked, value: innerValue });
      },
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerChecked(event.target.checked);
  };

  useEffect(() => {
    if (link_id) {
      emitter.emitEvent(`inputDisabled-${widget_id}--${link_id}`, [!valueToBool(innerValue)]);
    }
  }, [innerValue]);

  useEffect(() => {
    getValueThruApi();
    setValueThruApi();
  }, []);

  useEffect(() => {
    apiDataUpdated();
  });

  return (
    <div
      className={clsx('form-checkbox', {
        'form-checkbox--filled': filled,
        'form-checkbox--checked': innerChecked,
        [className]: className,
      })}
    >
      <input
        data-testid="checkbox"
        id={`${id}_${widget_id}`}
        className="form-checkbox__input"
        name={name || id}
        type="checkbox"
        checked={innerChecked}
        onChange={handleChange}
        value={innerValue.toString()}
      />
      {hiddens && hiddens.active !== undefined && (
        <input type="hidden" name={hiddens.active} value={innerValue.toString()} />
      )}
      <div className="form-checkbox-wrapper like-input">
        <Checkmark
          className={clsx('form-checkbox__svg', { 'fill-default': iconColor })}
          color={iconColor}
        />
      </div>
      <label
        htmlFor={`${id}_${widget_id}`}
        className={`form-checkbox__label ${inverted ? 'form-checkbox__label--inverted' : ''}`}
      >
        {translations.label}
      </label>
    </div>
  );
};
