import { useState, useEffect, useMemo } from 'preact/hooks';
import useResizeObserver from 'use-resize-observer';
import { useDebouncedValue } from 'rooks/dist/esm/hooks/useDebouncedValue';

import { BREAKPOINTS, BREAKPOINTS_ORDER } from '../constants/layout';
import { Breakpoint, WidgetLayoutByBreakpoint } from '../types/config/layout.types';
import { isNil } from '../../shared/lib/utils';
import { CASCOON_CONTAINER_CLASS_NAME } from '../constants/class_name_prefix';

const breakpointsOrderRev = BREAKPOINTS_ORDER;

export const useWidgetResize = (layouts: WidgetLayoutByBreakpoint, widgetId: string) => {
  const [observedWidth, setObservedWidth] = useState<number | null>(null);
  const [width, setWidthImmediately] = useDebouncedValue(observedWidth, 100);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint | null>(null);

  const onResize = ({ width: updatedWidth }: { width?: number }) => {
    if (!isNil(updatedWidth)) {
      if (isNil(width)) {
        setWidthImmediately(updatedWidth);
      } else {
        setObservedWidth(updatedWidth);
      }
    }
  };
  const { ref } = useResizeObserver<HTMLDivElement>({ onResize });

  const widgetContainer = useMemo(
    () => document.querySelector(`.${CASCOON_CONTAINER_CLASS_NAME}-${widgetId}`),
    [widgetId],
  );

  const layout = currentBreakpoint ? layouts[currentBreakpoint] : null;

  useEffect(() => {
    const widgetContainerWidth = widgetContainer?.clientWidth;
    const currentWidth = width ?? widgetContainerWidth ?? Breakpoint.XL;

    const newBreakpoint = breakpointsOrderRev.find(
      (breakpoint) => currentWidth >= BREAKPOINTS[breakpoint],
    );

    setCurrentBreakpoint((prevBreakpoint) => newBreakpoint ?? prevBreakpoint);
  }, [layouts, widgetId, width]);

  return { ref, layout, breakpoint: currentBreakpoint };
};
