import { FunctionComponent } from 'preact';

import { IIconProps } from '../calendar_month/calendar_month.types';

export const ArrowSvg: FunctionComponent<IIconProps> = ({ className }) => {
  return (
    <svg
      width="8"
      height="12"
      className={className}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.211056 5.78823C0.0943776 5.9053 0.0943778 6.0947 0.211056 6.21177L5.769 11.7886C5.88554 11.9055 6.07467 11.9063 6.19219 11.7904L7.28409 10.713C7.40295 10.5958 7.40319 10.404 7.28462 10.2864L3.11819 6.15522C2.9987 6.03675 3.00001 5.84319 3.12108 5.72633L7.2788 1.71349C7.40008 1.59644 7.40115 1.40247 7.28116 1.28408L6.19219 0.209646C6.07467 0.0936918 5.88554 0.0944872 5.769 0.211426L0.211056 5.78823Z"
        fill="inherit"
      />
    </svg>
  );
};
