import { useTranslations } from '../../../../../lib/use_translations';

import './styles.css';

const formatRating = (wholeRating: number) => {
  const rating = String(wholeRating / 10);
  return rating.includes('.') ? rating : `${rating}.0`;
};

export const Rating = ({ rating, explanation }: { rating: number; explanation: boolean }) => {
  const { t } = useTranslations();

  return (
    <div className="rating">
      <div className="rating__value">{formatRating(rating)}</div>
      {explanation && <div className="rating__label">{t('info.rating')}</div>}
    </div>
  );
};
