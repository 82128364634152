import { Matcher } from 'react-day-picker';

type DisabledDayDate = Date | null | undefined;

export const getDisabledDays = (before: DisabledDayDate, after: DisabledDayDate) => {
  const result: Matcher[] = [];

  if (before) {
    result.push({ before });
  }

  if (after) {
    result.push({ after });
  }

  return result;
};
