import { useEffect, useMemo, useState } from 'preact/hooks';

import { WidgetParams } from '../../../types/config/params.types';
import { IContextValues } from '../../../lib/connect_to_context';
import { HotelsByCategories } from '../components/hotels_by_categories';
import { getHotelsById } from '../api/get_hotels_by_id';
import { getHotelsByLocation } from '../api/get_hotels_by_location';
import { PredefinedHotels } from '../components/predefined_hotels';
import { getHotelsByIATA } from '../api/get_hotels_by_IATA';
import { Loader } from '../components/loader';
import { IParams } from '../../../types/node.types';
import { clsx } from '../../../../shared/lib/clsx';

import {
  HotelsByCategoriesData,
  HotelsData,
  isBlisseyParamsWithHotelsId,
  isBlisseyParamsWithIATA,
  isBlisseyParamsWithLocationId,
  ViewType,
} from './blissey.types';
import { createBlisseyParams } from './create_blissey_params';
import { blisseyParamsContext } from './blissey_params_context';

import './styles.css';

export const Blissey = ({
  showCommonError,
  ...restWidgetParams
}: WidgetParams & IContextValues & IParams) => {
  const params = useMemo(() => createBlisseyParams(restWidgetParams), []);

  const [isLoading, setIsLoading] = useState(true);
  const [hotelsByCategories, setHotelsByCategories] = useState<HotelsByCategoriesData>({
    hotels: {},
    categories: [],
  });
  const [hotels, setHotels] = useState<HotelsData>([]);

  const byCategories = !params.hotelsId;
  const isCompact = params.viewType === ViewType.Compact;

  useEffect(() => {
    const request = async () => {
      try {
        if (isBlisseyParamsWithHotelsId(params)) {
          setHotels(await getHotelsById(params));
        } else if (isBlisseyParamsWithLocationId(params)) {
          setHotelsByCategories(await getHotelsByLocation(params));
        } else if (isBlisseyParamsWithIATA(params)) {
          setHotelsByCategories(await getHotelsByIATA(params));
        } else {
          throw Error('No locationId, hotelsId or IATA present');
        }
      } catch (error) {
        if (showCommonError) showCommonError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };

    request();
  }, []);

  return (
    <blisseyParamsContext.Provider value={params}>
      <div className={clsx('blissey', isLoading && 'blissey_loading')}>
        {isLoading && <Loader />}
        {!isLoading && byCategories && (
          <HotelsByCategories hotelsByCategories={hotelsByCategories} compact={isCompact} />
        )}
        {!isLoading && !byCategories && <PredefinedHotels hotels={hotels} compact={isCompact} />}
      </div>
    </blisseyParamsContext.Provider>
  );
};
