const CHILDREN_AGE_KEY = 'children_age';

export const isChildrenAgesKey = (key: string) => key === CHILDREN_AGE_KEY;

export const encodeChildren = (pax: Record<string, any>, total: number) => {
  const childrenAges: Record<string, number> = {
    children: 7,
    infants: 1,
    seat_infants: 1,
    lap_infants: 1,
    youth: 12,
  };

  if (pax[CHILDREN_AGE_KEY]) {
    // children_age is a string of comma separated ages
    return pax[CHILDREN_AGE_KEY];
  }

  const result = Object.keys(pax).reduce<number[]>((acc, key) => {
    const paxPropertyAgesArray = Array(pax[key]).fill(childrenAges[key]);
    return childrenAges[key] ? [...acc, ...paxPropertyAgesArray] : acc;
  }, []);

  return result.slice(0, total).join(',');
};
