import { availableCategoriesService } from './services';

export const getAvailableCategories = async (locationId: number, categories: string[] = []) => {
  const availableCategories = await availableCategoriesService.get<string[]>({
    path: '',
    params: {
      id: String(locationId),
    },
  }).request;

  const filteredCategories = categories.filter((category) =>
    availableCategories.includes(category),
  );

  return filteredCategories.length ? filteredCategories : availableCategories.slice(0, 3);
};
