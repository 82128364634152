import { useState } from 'preact/hooks';
import './radio_list.css';

type RadioItem = {
  label: string;
  value: string;
  name: string;
  defaultChecked?: boolean;
};

type RadioProps = {
  items: RadioItem[];
  translations: Record<string, string>;
};

export const RadioButtonList: React.FC<RadioProps> = ({ items, translations }) => {
  const defaultItem = items.find((item) => item.defaultChecked);
  const [selectedValue, setSelectedValue] = useState<string>(
    defaultItem ? defaultItem.value : items[0].value,
  );

  return (
    <div>
      {items.map((item) => (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
        <label key={item.label} className="radio-container">
          {translations[item.label]}
          <input
            type="radio"
            name={item.name}
            value={item.value}
            onChange={() => setSelectedValue(item.value)}
            checked={selectedValue === item.value}
          />
          <span className="checkmark" />
        </label>
      ))}
    </div>
  );
};
