import { FunctionalComponent } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Checkbox } from '../checkbox';
import { Cookies } from '../../lib/cookies';
import { encodeChildren, isChildrenAgesKey } from '../../lib/encode_children';
import { emitter } from '../../lib/event_emitter/initialized';
import { generateUrl } from '../../lib/url';
import { isNumber } from '../../../shared/lib/utils';

import { BookingProps, Form, Pax } from './booking.types';

dayjs.extend(customParseFormat);

const DEEPLINK_URL =
  'https://yasen.hotellook.com/adaptors/location_deeplink?gateId=2&skipRulerCheck=skip&utm_campaign=checkbox&';
const MAX_CHILDREN = 10;

const BOOKING_COOKIES_KEY = 'cascoon_booking';

const mapPax = (pax: Pax, form: Form) => {
  return Object.entries(pax).reduce<Pax>((acc, [key, value]) => {
    if (isNumber(value)) {
      return { ...acc, [key]: value };
    }
    if (isChildrenAgesKey(key)) {
      return { ...acc, [key]: form[value].value };
    }
    return { ...acc, [key]: parseInt(form[value].value, 10) };
  }, {});
};

export const Booking: FunctionalComponent<BookingProps> = (props) => {
  const {
    widget_id: widgetId,
    link_id: linkId,
    checked,
    passengers,
    show_hotels: showHotels,
    promo_id: promoId,
    currency,
    locale,
    shmarker,
    depart_date: departDateRaw,
    destination_name: destinationName,
    return_date: returnDateRaw,
  } = props;
  const bookingCookies = Cookies.getCookie(BOOKING_COOKIES_KEY);
  const defaultChecked: boolean = bookingCookies !== null ? bookingCookies !== 'false' : checked;

  const [innerChecked, setInnerChecked] = useState(defaultChecked);

  const handleChange = (newState: boolean) => {
    setInnerChecked(!newState);
    Cookies.setCookie(BOOKING_COOKIES_KEY, !newState ? 'true' : 'false', 1 / 24);
  };

  // тут не смовсем понятно какой типа должен быть
  const bookingRedirect = (event: { target: Form }) => {
    const form = event.target;
    if (showHotels === 'false') return;
    if (!innerChecked || !form) return;

    setTimeout(() => {
      const destination = form[destinationName].value;
      const departDate =
        form[departDateRaw.field].value ||
        dayjs(new Date()).add(7, 'day').format(departDateRaw.format);
      const returnDate =
        form[returnDateRaw.field].value ||
        dayjs(departDate).add(3, 'day').format(departDateRaw.format);

      const departDateValue = dayjs(departDate).format('YYYY-MM-DD');
      const returnDateValue = dayjs(returnDate).format('YYYY-MM-DD');
      const pax = mapPax(passengers, form);

      const params = {
        'flags%5Butm%5D': `tp_cascoon_${promoId}`,
        utm_source: 'tp_cascoon',
        utm_medium: `campaign_${promoId}`,
        destination,
        selectedHotelId: destination,
        language: locale,
        currency,
        marker: shmarker,
        adults: pax.adults,
        children: encodeChildren(pax, MAX_CHILDREN),
        checkIn: departDateValue,
        checkOut: returnDateValue,
      };

      const href = generateUrl(DEEPLINK_URL, params);

      window.location.href = href;

      Cookies.setCookie(BOOKING_COOKIES_KEY, 'false', 1 / 24);
    }, 1000);
  };

  useEffect(() => {
    emitter.addListener(`formSubmit-${widgetId}`, bookingRedirect);
    emitter.addListener(`inputDisabled-${widgetId}--${linkId}`, handleChange);

    return () => {
      emitter.removeListener(`formSubmit-${widgetId}`, bookingRedirect);
      emitter.removeListener(`inputDisabled-${widgetId}--${linkId}`, handleChange);
    };
  }, [innerChecked]);

  if (showHotels === 'false') return null;

  // eslint-disable-next-line
  // @ts-ignore
  return <Checkbox {...props} checked={innerChecked} />; // eslint-disable-line react/jsx-props-no-spreading
};
