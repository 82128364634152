import { createWidgetRequestService } from '../../../lib/widget_request';

export const widgetLocationDumpService = createWidgetRequestService({
  url: 'https://yasen.hotellook.com/tp/v1/widget_location_dump.json',
});
export const hotelsDumpService = createWidgetRequestService({
  url: 'https://yasen.hotellook.com/tp/v1/widget_hotels_dump.json',
});
export const hotellookAutocompleteService = createWidgetRequestService({
  url: `https://yasen.hotellook.com/autocomplete`,
});
export const availableCategoriesService = createWidgetRequestService({
  url: 'https://yasen.hotellook.com/tp/v1/available_selections.json',
});
