import { FunctionalComponent } from 'preact';

import { clsx } from '../../../shared/lib/clsx';

type ImageSliderBulletsProps = {
  length: number;
  currentIdx: number;
  onClickBullets: (idx: number) => void;
};

export const ImageSliderBullets: FunctionalComponent<ImageSliderBulletsProps> = ({
  length,
  currentIdx,
  onClickBullets,
}) => {
  return (
    <div className="image-slider__bullets">
      {Array.from(Array(length).keys()).map((idx: number) => (
        <button
          key={`bullet-${idx}`}
          className={clsx('image-slider__bullet', {
            'image-slider__bullet--active': idx === currentIdx,
          })}
          type="button"
          aria-label="Choose Slide"
          onClick={() => onClickBullets(idx)}
        />
      ))}
    </div>
  );
};
