import { BlisseyParamsWithHotelsId, HotelsData } from '../blissey/blissey.types';

import { normalizeHotelData } from './normalizations/normalize_hotel_data';
import { GetHotelsByIdResponse } from './types';
import { hotelsDumpService } from './services';

export const getHotelsById = async (params: BlisseyParamsWithHotelsId): Promise<HotelsData> => {
  const { hotelsId, currency, language, limit } = params;

  const response = await hotelsDumpService.get<GetHotelsByIdResponse>({
    path: '',
    params: {
      currency,
      language,
      limit: String(limit),
      ids: hotelsId.join(','),
    },
  }).request;

  return response.flatMap((location) => {
    return location.hotels
      .map((hotel) =>
        normalizeHotelData(hotel, {
          locationName: location.location_name,
          locationId: location.location_id,
          blisseyParams: params,
        }),
      )
      .sort((hotelA, hotelB) => {
        return hotelsId.indexOf(hotelA.hotel_id) - hotelsId.indexOf(hotelB.hotel_id);
      });
  });
};
