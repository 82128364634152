import { useContext } from 'preact/hooks';

import { StarsRating } from '../stars_rating';
import { Icon } from '../../icon';
import { clsx } from '../../../../../../shared/lib/clsx';
import { Hotel } from '../../../blissey/blissey.types';
import { blisseyParamsContext } from '../../../blissey/blissey_params_context';
import { useTranslations } from '../../../../../lib/use_translations';

import { formatDistance } from './format_distance';
import './styles.css';

type Props = {
  hotel: Hotel;
  compact: boolean;
  className?: string;
};

export const MainInfo = ({ hotel, className, compact }: Props) => {
  const { stars, distance, has_wifi, name, location } = hotel;
  const { linkTarget } = useContext(blisseyParamsContext);
  const { t, language } = useTranslations();
  return (
    <div className={clsx('main-info', compact && 'main-info_compact', className)}>
      <div className="main-info__stars">
        <StarsRating stars={stars} />
      </div>
      {location && <div className="main-info__location">{location}</div>}
      {distance && (
        <div className="main-info__distance">
          <Icon type="distance" height={12} />
          {formatDistance(distance, language)}
          {t('info.k_from_center')}
        </div>
      )}

      {has_wifi && <Icon type="wifi" height={8} />}

      <a className="main-info__name" href={hotel.url} target={linkTarget} rel="noreferrer">
        {name}
      </a>
    </div>
  );
};
