// eslint-disable-next-line max-classes-per-file
import { ErrorName, RequestErrorContext, RequestErrorMeta } from './types';

export class RequestError extends Error {
  meta: RequestErrorMeta = { request: null, response: null };

  constructor(message: string, context: RequestErrorContext) {
    super(message);
    this.name = ErrorName.RequestError;
    this.meta = RequestError.extractMetaFromContext(context);
  }

  static extractMetaFromContext(context: RequestErrorContext): RequestErrorMeta {
    const meta: RequestErrorMeta = { request: null, response: null };

    if (context.request) {
      const { url, headers, credentials, method, mode } = context.request;
      meta.request = {
        url,
        headers,
        credentials,
        method,
        mode,
      };
    }

    if (context.response) {
      const { url, headers, type, status, redirected } = context.response;
      meta.response = {
        url,
        type,
        status,
        redirected,
        headers: Object.fromEntries(headers.entries()),
      };
    }

    return meta;
  }
}

export class CancellationError extends Error {
  constructor(data: Error) {
    super(data.message);
    this.name = ErrorName.RequestError;
  }
}
