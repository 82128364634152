import { IRequestError } from '../../../../../shared/lib/request';
import { SubscriptionsResponseData } from '../types';

import { ActionType } from './types';

const actionCreator = <T, U extends ActionType>(type: U) => (payload: T) => ({
  type,
  payload,
});

export const subscriptionsReceived = actionCreator<
  SubscriptionsResponseData,
  ActionType.SubscriptionsReceived
>(ActionType.SubscriptionsReceived);

export const subscriptionCreated = actionCreator<
  SubscriptionsResponseData,
  ActionType.SubscriptionCreated
>(ActionType.SubscriptionCreated);

export const subscriptionUpdated = actionCreator<
  SubscriptionsResponseData,
  ActionType.SubscriptionUpdated
>(ActionType.SubscriptionUpdated);

export const subscriptionDeleted = actionCreator<
  SubscriptionsResponseData,
  ActionType.SubscriptionDeleted
>(ActionType.SubscriptionDeleted);

export const subscriptionFrozen = actionCreator<
  SubscriptionsResponseData,
  ActionType.SubscriptionFrozen
>(ActionType.SubscriptionFrozen);

export const resentEmailConfirmation = actionCreator(ActionType.EmailConfirmationResent);

export const subscribedToNews = actionCreator<boolean, ActionType.SubscribedToNews>(
  ActionType.SubscribedToNews,
);

export const emailResendError = actionCreator<IRequestError, ActionType.EmailResendError>(
  ActionType.EmailResendError,
);
export const error = actionCreator<IRequestError, ActionType.Error>(ActionType.Error);
export const loading = actionCreator<boolean, ActionType.Loading>(ActionType.Loading);
