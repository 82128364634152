export const TIME_FORMAT_MILITARY = 'military';
export const TIME_FORMAT_STANDARD = 'standard';

const isStandardTimeString = (string: string) => {
  const preparedTimeString = string.toLocaleLowerCase();
  const standardTimePostfixRegex = /(a|p)\.?\s?m\.?/;
  return standardTimePostfixRegex.test(preparedTimeString);
};

const normalizeLocale = (locale: string): string => {
  return locale.replaceAll('_', '-');
};

export const detectTimeFormat = (locale: string) => {
  try {
    const now = new Date();
    const formattedData = now.toLocaleTimeString(normalizeLocale(locale));
    return isStandardTimeString(formattedData) ? TIME_FORMAT_STANDARD : TIME_FORMAT_MILITARY;
  } catch {
    return TIME_FORMAT_STANDARD;
  }
};

const createDateFromTimeString = (timeString: string) => {
  return new Date(`Mon Apr 25 2022 ${timeString}`);
};

export const timeStringToStandardTimeString = (timeString: string) => {
  const date = createDateFromTimeString(timeString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h12' });
};

export const timeStringToMilitaryTimeString = (timeString: string) => {
  const date = createDateFromTimeString(timeString);
  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  });
};
