type Columns = number;
export enum Breakpoint {
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
}
export type Breakpoints = Breakpoint[];

export type WidgetLayoutSettings = {
  columns: Columns;
  breakpointsOrder: Breakpoints;
  breakpointsOrderRev: Breakpoints;
  breakpoints: Record<Breakpoint, number>;
};

export type Align = 'left' | 'right' | 'center';

export type WidgetLayoutNodeWithParams = {
  id: string;
  nested?: WidgetLayout;
  width?: number;
  align?: Align;
  order?: number;
};

export type WidgetLayoutNode = WidgetLayoutNodeWithParams;

export type WidgetLayoutRow = Array<WidgetLayoutNode>;

export type WidgetLayout = Array<WidgetLayoutRow>;

export type WidgetLayoutByBreakpoint = Record<Breakpoint, WidgetLayout>;
