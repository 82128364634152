export const generateUrl = (
  initialUrl: string,
  params: Record<string, string | number | boolean | undefined>,
) => {
  const url = new URL(initialUrl);

  Object.keys(params).forEach((key) => {
    const paramValue = params[key];

    if (paramValue) {
      url.searchParams.append(key, paramValue.toString());
    }
  });

  return url.href;
};

export const getParamsFromAbsoluteUrl = (initialUrl: string) => {
  const url = new URL(initialUrl);

  return Object.fromEntries(url.searchParams);
};

export const getParamsFromAnyURL = (url: string) => {
  const absoluteUrl = new URL(url, '//localhost');

  return Object.fromEntries(absoluteUrl.searchParams);
};

export const parseQueryParams = (paramsString: string) => {
  return Object.fromEntries(new URLSearchParams(paramsString));
};

export const getOrigin = (initialUrl: string) => {
  const url = new URL(initialUrl);

  return url.origin;
};

export const getOriginWithPathname = (url: string) => {
  const { origin, pathname } = new URL(url);
  const originWithPathname = origin + pathname;

  return originWithPathname;
};

export const hasHTTP = (data: string) => /^(http|https):\/\//.test(data);

enum Divider {
  QueryStart = '?',
  QueryCompound = '&',
}

export const getDivider = (index: number): Divider =>
  index === 0 ? Divider.QueryStart : Divider.QueryCompound;

export const getValidProtocolUrl = (initialUrl: string) => {
  const hasExtraHTTP = /^(http(s?)):\/\/(http(s?))/.test(initialUrl);
  let resultingUrl = initialUrl;

  if (hasExtraHTTP) {
    const extraHTTPRegEx = /(\/\/http(s?)(:?))+/;
    resultingUrl = initialUrl.replace(extraHTTPRegEx, '');
  }

  return new URL(resultingUrl).href;
};

export const stringifyParams = (params: Record<string, string>) => {
  const paramsArray: string[] = [];

  Object.keys(params).forEach((p) => {
    const uriComponent = `${p}=${encodeURIComponent(params[p])}`;
    paramsArray.push(uriComponent);
  });

  return paramsArray.join('&');
};

export const parseMultipleValue = (value: string) => value.split(',').map((v) => v.trim());
