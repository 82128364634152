import { useCallback } from 'preact/hooks';

import { createWidgetRequestService } from '../../../lib/widget_request';
import { IRequestError } from '../../../../shared/lib/request';
import { useMetrics } from '../lib/use_metrics';

import {
  SubscriptionRequest as Subscription,
  CreateSubscriptionParams,
  SubscriptionsResponseData,
  UpdateSubscriptionParams,
  SubscriptionsBaseParams,
  subscribeToNewsParams,
} from './types';
import { useDataReducer, Actions } from './reducer';

const subscriptionService = createWidgetRequestService({
  url: 'https://subscr.tp.tools',
});

const stringifySubscription = (subscription: Subscription) => {
  const preparedSubscription = { raw_rules: subscription };
  return JSON.stringify(preparedSubscription);
};

export const useSubscriptionService = (params: SubscriptionsBaseParams) => {
  const [state, dispatch] = useDataReducer();
  const {
    onAddSubscription,
    onDeleteSubscription,
    onFreezeSubscription,
    onUnfreezeSubscription,
  } = useMetrics();

  const getSubscriptions = useCallback(() => {
    dispatch(Actions.loading(true));

    subscriptionService
      .get<SubscriptionsResponseData>({
        jsonp: true,
        path: 'subscriptions.jsonp',
        params,
      })
      .request.then((data) => dispatch(Actions.subscriptionsReceived(data)))
      .catch((error: IRequestError) => dispatch(Actions.error(error)));
  }, [params]);

  const createSubscription = useCallback(
    ({ subscription }: CreateSubscriptionParams) => {
      dispatch(Actions.loading(true));

      subscriptionService
        .get<SubscriptionsResponseData>({
          jsonp: true,
          path: 'subscriptions/create.jsonp',
          params: {
            ...params,
            subscription: stringifySubscription(subscription),
          },
        })
        .request.then((data) => {
          const lastSubscription = data.subscriptions[data.subscriptions.length - 1];

          onAddSubscription({ subscription_id: lastSubscription?.id });
          dispatch(Actions.subscriptionCreated(data));
        })
        .catch((error: IRequestError) => dispatch(Actions.error(error)));
    },
    [params],
  );

  const updateSubscription = useCallback(
    (id: number, { subscription }: UpdateSubscriptionParams) => {
      dispatch(Actions.loading(true));

      subscriptionService
        .get<SubscriptionsResponseData>({
          jsonp: true,
          path: `subscriptions/${id}/update.jsonp`,
          params: {
            ...params,
            subscription: stringifySubscription(subscription),
          },
        })
        .request.then((data) => dispatch(Actions.subscriptionUpdated(data)))
        .catch((error: IRequestError) => {
          dispatch(Actions.error(error));
        });
    },
    [params],
  );

  const deleteSubscription = useCallback(
    (id: number) => {
      dispatch(Actions.loading(true));

      subscriptionService
        .get<SubscriptionsResponseData>({
          jsonp: true,
          path: `subscriptions/${id}/destroy.jsonp`,
          params,
        })
        .request.then((data) => dispatch(Actions.subscriptionDeleted(data)))
        .catch((error: IRequestError) => {
          dispatch(Actions.error(error));
        });

      onDeleteSubscription({ subscription_id: id });
    },
    [params],
  );

  const freezeSubscription = useCallback(
    (id: number, freeze = true) => {
      dispatch(Actions.loading(true));

      const subscriptionParam = { active: !freeze };
      const freezeParams = {
        ...params,
        subscription: JSON.stringify(subscriptionParam),
      };

      subscriptionService
        .get<SubscriptionsResponseData>({
          jsonp: true,
          path: `subscriptions/${id}/freeze.jsonp`,
          params: freezeParams,
        })
        .request.then((data) => dispatch(Actions.subscriptionFrozen(data)))
        .catch((error: IRequestError) => {
          dispatch(Actions.error(error));
        });

      if (freeze) {
        onFreezeSubscription({ subscription_id: id });
      } else {
        onUnfreezeSubscription({ subscription_id: id });
      }
    },
    [params],
  );

  const resendEmailConfirmation = useCallback(() => {
    dispatch(Actions.loading(true));

    subscriptionService
      .get({
        jsonp: true,
        path: 'subscribers/resend.jsonp',
        params,
      })
      .request.then((data) => dispatch(Actions.resentEmailConfirmation(data)))
      .catch((error: IRequestError) => {
        dispatch(Actions.emailResendError(error));
      });
  }, [params]);

  const subscribeToNews = useCallback(
    ({ receiveNews }: subscribeToNewsParams) => {
      dispatch(Actions.loading(true));

      subscriptionService
        .get({
          jsonp: true,
          path: 'subscribers/recieve_news.jsonp',
          params: { ...params, recieve_news: receiveNews.toString() },
        })
        .request.then(() => dispatch(Actions.subscribedToNews(receiveNews)))
        .catch((error: IRequestError) => {
          dispatch(Actions.error(error));
        });
    },
    [params],
  );

  return {
    state,
    getSubscriptions,
    createSubscription,
    updateSubscription,
    deleteSubscription,
    freezeSubscription,
    resendEmailConfirmation,
    subscribeToNews,
  };
};
