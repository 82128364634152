import { FunctionalComponent } from 'preact';
import './styles.css';

export const Loader: FunctionalComponent = () => (
  <div className="loader">
    <img
      alt="App is loading..."
      className="loader__indicator"
      width={64}
      height={64}
      src="https://tp.media/cascoon/schedule_loader.svg"
    />
  </div>
);
