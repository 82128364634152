import { makeNumber } from '../../../../shared/lib/utils';
import { WidgetFieldHiddens } from '../../../types/config/fields.types';
import { WidgetCountersSelectFields } from '../counters_select.types';

import { ListHiddens, SubFieldsCount } from './list.types';

export const prepareHiddens = (
  fields: WidgetCountersSelectFields,
  hiddens?: WidgetFieldHiddens,
) => {
  return fields.reduce(
    (acc: ListHiddens, { field_name: fieldName, sub_field: subField, value }) => {
      const fieldHiddenName = hiddens && hiddens[fieldName] ? hiddens[fieldName] : fieldName;

      const fieldHiddenValue = subField
        ? Array(makeNumber(value)).fill(subField.value).join(',')
        : value;

      return {
        ...acc,
        [fieldName]: {
          name: fieldHiddenName,
          value: fieldHiddenValue,
        },
      };
    },
    {},
  );
};

export const prepareSubFieldsCount = (fields: WidgetCountersSelectFields) => {
  return fields.reduce(
    (acc: SubFieldsCount, { field_name: fieldName, value, sub_field: subField }) => {
      return subField
        ? {
            ...acc,
            [fieldName]: Array(makeNumber(value)).fill(subField.value),
          }
        : acc;
    },
    {},
  );
};
