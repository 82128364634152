import { h, FunctionComponent } from 'preact';

type LoaderProps = {
  wrapperClassName?: string;
};

export const Loader: FunctionComponent<LoaderProps> = ({ wrapperClassName }) => (
  <div className={`${wrapperClassName || ''}`}>
    <img
      alt="Loading"
      width={100}
      height={150}
      src="https://tp.media/cascoon/schedule_loader.svg"
    />
  </div>
);
