import { FunctionalComponent } from 'preact';

import { emitter } from '../../../lib/event_emitter/initialized';

import { CloseModalLinkProps } from './close_modal_link.types';

export const CloseModalLink: FunctionalComponent<CloseModalLinkProps> = ({
  children,
  id,
  text,
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div data-testid="close-modal-link">
      <div>
        {children}
        <div className="datepicker-oneway_wrapper">
          <span className="datepicker-oneway" onClick={() => emitter.emitEvent(`clearDate-${id}`)}>
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};
