import { FunctionalComponent } from 'preact';
import { useRef, useEffect } from 'preact/hooks';

import { ScrollProps } from './scroll.types';

import './scroll.css';

export const Scroll: FunctionalComponent<ScrollProps> = ({
  children,
  id,
  widget,
  class: className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const maxHeight = widget.params.scroll_height ? `${widget.params.scroll_height}px` : 'auto';
  const maxWidth = widget.params.width ? `${widget.params.width}px` : 'auto';

  useEffect(() => {
    const { current } = ref;

    current.style.setProperty('max-height', maxHeight, 'important');
    current.style.setProperty('max-width', maxWidth, 'important');
  }, [ref]);

  return (
    <div id={id} className={`scroll ${className}`} ref={ref} data-testid={`scroll-${id}`}>
      {children}
    </div>
  );
};
