/**
 * Публичный сервис, внешнее API для использования другими микрофронтендами на странице.
 * Регистрируется в глобальном скопе с помощью сервис-локатора, скорее всего, в common.js
 * Любой другой фронтенд на странице, использущий сервис-локатор, может получить этот инстанс
 */
import { setJourneyId } from './journey_id';

export const cascoonFrontendApi = {
  setJourneyId,
};
