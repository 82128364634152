import { h, FunctionalComponent, JSX } from 'preact';

import './styles.css';

type FormProps = {
  onSubmit: () => void;
  onEmailFocus: () => void;
  onEmailChange: (value: string) => void;
  email: string;
  buttonText: string;
  emailPlaceholder: string;
};

export const Form: FunctionalComponent<FormProps> = ({
  onSubmit,
  onEmailChange,
  onEmailFocus,
  email,
  emailPlaceholder,
  buttonText,
}) => {
  const handleSubmit = (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
    event.preventDefault();
    onSubmit();
  };

  const handleEmailChange = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    onEmailChange(target.value);
  };

  return (
    <form className="sbscr-form" onSubmit={handleSubmit}>
      <input
        className="sbscr-form__email"
        type="email"
        name="email"
        value={email}
        placeholder={emailPlaceholder}
        onChange={handleEmailChange}
        onFocus={onEmailFocus}
        required
      />
      <button className="sbscr-form__subscribe-btn" type="submit">
        {buttonText}
      </button>
    </form>
  );
};
