import { useLayoutEffect, useState, useMemo, useRef } from 'preact/hooks';

/**
 * Возвращает текущую ширину и высоту картинки учитывая максимально возможную ширину
 * Предыдущее решение с memo не работало так как мемо вызывается
 * до рендера компонента и измерить его высоту так невозможно
 */
const defaultRatio = 0;
const defaultWidth = 173;

export const useMaxImageSize = (maxWidth: number = defaultWidth) => {
  const ref = useRef<HTMLImageElement>(null);
  const [initialRatio, setInitialRatio] = useState(defaultRatio);
  const height = useMemo(() => {
    const finalHeight = maxWidth / initialRatio;

    return finalHeight;
  }, [maxWidth, initialRatio]);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current !== null) {
      current.onload = () => {
        const { naturalWidth = 0, naturalHeight = 0 } = current;
        const ratio = naturalWidth / naturalHeight;

        setInitialRatio(ratio);
      };
    }
  }, [ref]);

  return { ref, width: maxWidth, height };
};
