import { FunctionalComponent } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';

import { clsx } from '../../../shared/lib/clsx';
import { noop, normalizeBoolean } from '../../../shared/lib/utils';
import { useTranslations } from '../../lib/use_translations';
import { isLightColor } from '../../lib/color_utils';

import { SubscriptionProps, ViewType } from './subscription.types';
import { Form } from './form';
import { Bell } from './bell';
import { Plane } from './plane';
import { Agreement } from './agreement';
import { PriceAlert } from './price_alert';
import { HighlightAlert } from './highlight_alert';
import { SubscriptionsPopup } from './subscriptions_popup';
import {
  getEmailFromStorage,
  getHighlightedShownFromStorage,
  setEmailToStorage,
  setHighlightedShownToStorage,
} from './lib/storage';
import Mountain from './icons/mountain.svg';

import './styles.css';

const normalizeProps = ({
  hide_alert = false,
  highlight = false,
  target_host = window.location.host,
  view_type = ViewType.Full,
  ...props
}: SubscriptionProps) => ({
  ...props,
  priceAlert: !normalizeBoolean(hide_alert),
  highlightAlert: normalizeBoolean(highlight),
  targetHost: target_host,
  view: view_type,
});

export const Subscription: FunctionalComponent<SubscriptionProps> = (props) => {
  const {
    priceAlert,
    highlightAlert,
    locale,
    targetHost,
    shmarker,
    view,
    secondary,
  } = normalizeProps(props);
  const { t } = useTranslations('subscription');

  const [showPriceAlert, setShowPriceAlert] = useState(true);
  const [showHighlightAlert, setShowHighlightAlert] = useState(false);
  const [email, setEmail] = useState(getEmailFromStorage());
  const [showSubscriptionsPopup, setShowSubscriptionsPopup] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const isFullView = view === ViewType.Full;
  const shouldRenderPriceAlert = priceAlert && ref.current && ref.current.offsetWidth > 290;
  const shouldOpenHighlightAlert = () => {
    const wlSearchCondition = window.tpSubscribeHighlightCondition
      ? Boolean(window.tpSubscribeHighlightCondition())
      : true;
    const isHighlightAlreadyShown = Boolean(getHighlightedShownFromStorage());

    return highlightAlert && !isHighlightAlreadyShown && wlSearchCondition;
  };
  const shouldRenderHighlightAlert = useMemo(shouldOpenHighlightAlert, [highlightAlert]);

  const closeHighlightAlert = () => setShowHighlightAlert(false);
  const openHighlightAlert = () => {
    if (!shouldOpenHighlightAlert()) return;

    setShowHighlightAlert(true);
    setHighlightedShownToStorage();
    ref.current?.scrollIntoView(true);
  };

  const closeAlerts = () => {
    setShowPriceAlert(false);
    closeHighlightAlert();
  };

  useEffect(() => {
    if (!shouldRenderHighlightAlert) {
      return noop;
    }
    document.body.addEventListener('mouseleave', openHighlightAlert);
    return () => {
      document.body.removeEventListener('mouseleave', openHighlightAlert);
    };
  }, []);

  const handleContainerClick = () => {
    closeAlerts();
  };

  const handleSubmit = () => {
    setEmailToStorage(email);
    setShowSubscriptionsPopup(true);
  };

  const handleSubscriptionsPopupClose = () => {
    setShowSubscriptionsPopup(false);
  };

  return (
    <div
      className={clsx('sbscr', {
        'sbscr--full': isFullView,
        'sbscr--compact': !isFullView,
        'sbscr--highlighted': showHighlightAlert,
      })}
      onClick={handleContainerClick}
      ref={ref}
    >
      {isFullView && <Mountain className="sbscr__mountain" />}
      <div className="sbscr__container">
        {!isFullView && <Bell />}
        <h3 className="sbscr__header">{t(isFullView ? 'title' : 'title_compact')}</h3>
        <p className="sbscr__description">
          {t(isFullView ? 'description' : 'description_compact')}
        </p>
        <Form
          onSubmit={handleSubmit}
          onEmailFocus={closeAlerts}
          onEmailChange={setEmail}
          email={email}
          emailPlaceholder={t('email.placeholder')}
          buttonText={t(isFullView ? 'subscribe.text' : 'subscribe.text_compact')}
        />
        <Agreement locale={locale} mainText={t('agreement.text')} linkText={t('agreement.link')} />
      </div>
      {isFullView && <Plane />}
      {shouldRenderPriceAlert && (
        <PriceAlert
          show={showPriceAlert}
          title={t('price_alert.title')}
          description={t('price_alert.description')}
        />
      )}
      {shouldRenderHighlightAlert && (
        <HighlightAlert
          show={showHighlightAlert}
          onClose={closeHighlightAlert}
          title={t('highlight_alert.title')}
          description={t('highlight_alert.description')}
        />
      )}
      {showSubscriptionsPopup && (
        <SubscriptionsPopup
          email={email}
          props={props}
          host={targetHost}
          marker={shmarker}
          useDefaultTheme={secondary ? isLightColor(secondary) : false}
          onClose={handleSubscriptionsPopupClose}
        />
      )}
    </div>
  );
};
