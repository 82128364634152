import dayjs from 'dayjs';
import { FunctionalComponent } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { useDidMount } from 'rooks/dist/esm/hooks/useDidMount';
import { useDidUpdate } from 'rooks/dist/esm/hooks/useDidUpdate';

import { emitter } from '../../../lib/event_emitter/initialized';
import { isFunction, isUndefined } from '../../../../shared/lib/utils';
import { getDisabledDays } from '../utils/get_disabled_days';
import { getSelectedDays } from '../utils/get_selected_days';
import { withMonthsNumber } from '../utils/with_months_number';
import { DatePickerInput } from '../date_picker_input';
import { CloseModalLink } from '../close_modal_link';
import { DatePickerValue } from '../date_picker.types';
import { device } from '../../../lib/device';

import { DateDirection, DatePickerFromProps } from './date_picker_from.types';
import '../index.css';

const initialFromState = (value: DatePickerValue | undefined) =>
  isFunction(value) ? value(dayjs) : value;
const DatePickerFromView: FunctionalComponent<DatePickerFromProps> = (props) => {
  const {
    id,
    name,
    value,
    required = false,
    first_day,
    translations,
    hidden,
    no_labels,
    hiddens,
    value_format,
    widget_id,
    monthsNumber,
    max_interval,
    disable_days_after,
    disable_days_before,
    color_icons,
    oneWay = false,
  } = props;
  const { firstDay } = translations.dayjs;
  const realId = `${id}_${widget_id}`;
  const [direction, setDirection] = useState(DateDirection.From);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [from, setFrom] = useState(initialFromState(value));
  const [tempFrom, setTempFrom] = useState<Date | undefined>(undefined);
  const [to, setTo] = useState<Date | undefined>(undefined);
  const [tempTo, setTempTo] = useState<Date | undefined>(undefined);
  const [disableDaysBefore, setDisableDaysBefore] = useState(disable_days_before);
  const [disableDaysAfter, setDisableDaysAfter] = useState(disable_days_after);
  const [lastFieldWasTo, setLastFieldWasTo] = useState(false);
  const [toWrapperRef, setToInputRef] = useState<HTMLInputElement | null>(null);
  const [toLabel, setToLabel] = useState<string | undefined>(undefined);
  const [calendarTitle, setCalendarTitle] = useState<string | undefined>(undefined);
  const firstDayOfWeek = useMemo(() => first_day ?? firstDay ?? 0, [first_day, firstDay]);

  const isMobile = device.isMobileSize();

  const handleEmitToChangingEvent = (newTo?: Date) => {
    emitter.emitEvent(`dateToChange-${widget_id}`, [newTo]);
  };

  const handleClearTo = () => {
    const newTo = undefined;

    handleEmitToChangingEvent(newTo);
    setTo(newTo);
    setIsPopupOpen(false);
  };

  useDidMount(() => {
    emitter.addListener(`datepickerOpen-${widget_id}`, () => {
      if (!oneWay) {
        setDirection(DateDirection.To);
        setIsPopupOpen(true);
      }
    });
    emitter.addListener(`clearDate-${widget_id}`, handleClearTo);
    emitter.addListener(`setInitialTo-${widget_id}`, (date) => setTo(date));
    emitter.addListener(`setDatepickerToWrapper-${widget_id}`, (element) => setToInputRef(element));

    // listening for label translation for "to" datepicker input
    emitter.addListener(`setDatepickerToLabel-${widget_id}`, setToLabel);
  });

  const handleToChange = (newTo?: Date) => {
    handleEmitToChangingEvent(newTo);
    setTo(newTo);
  };

  const handlePopupOpen = () => {
    setDirection(DateDirection.From);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setDirection(DateDirection.From);
  };

  const handleChange = useCallback(
    (date: Date) => {
      const isToDirection = direction === DateDirection.To;
      const isFromSet = !isUndefined(from);
      const isToSet = !isUndefined(to);
      const isDateAfterFrom = dayjs(date).isAfter(dayjs(from)) || dayjs(date).isSame(dayjs(from));
      const isDateAfterTo = dayjs(date).isAfter(dayjs(to)) || dayjs(date).isSame(dayjs(to));

      if (isToDirection) {
        handleToChange(date);

        // Если есть значение поля from
        // и текущее значение больше, чем значение из поля from
        // то закрываем попап
        if (isFromSet && isDateAfterFrom) {
          if (!isMobile) handlePopupClose();

          // Если есть значение поля from,
          // но текущее значение меньше, чем поле from,
          // то сбрасываем значение поля from и меняем флаг,
          // отвечающий за то, что начали ввод с поля to
        } else if (isFromSet && !isDateAfterFrom) {
          setFrom(undefined);
          setLastFieldWasTo(true);
        } else {
          setLastFieldWasTo(true);
        }

        setDirection(DateDirection.From);
      } else {
        setFrom(date);

        // Если есть значение поля to
        // и текущая дата больше, чем значение из поля to
        // то сбрасываем to
        if (isToSet && isDateAfterTo) {
          handleToChange(undefined);

          // Если есть значение поля to
          // и текущая дата меньше, чем значение из поля to
          // или последним измененным полем было поле to
          // то закрываем попап и ставим флаг, что поледним полем меняли from
        } else if ((isToSet && !isDateAfterTo) || lastFieldWasTo) {
          if (!isMobile) handlePopupClose();
          setLastFieldWasTo(false);
        }

        if (!oneWay) {
          setDirection(DateDirection.To);
        } else {
          setIsPopupOpen(false);
        }
      }
    },
    [from, to, direction, lastFieldWasTo],
  );

  const handleMouseEnter = (date: Date) => {
    const isToDirection = direction === DateDirection.To;

    if (isToDirection) {
      setTempTo(date);
      handleEmitToChangingEvent(date);
    } else {
      setTempFrom(date);
    }
  };

  const handleMouseLeave = () => {
    const isToDirection = direction === DateDirection.To;
    const isTempToAfterFrom =
      dayjs(tempTo).isAfter(dayjs(from)) || dayjs(tempTo).isSame(dayjs(from));

    if (isMobile && tempTo && isToDirection && isTempToAfterFrom) {
      handleToChange(tempTo);
    } else {
      handleEmitToChangingEvent(to);
    }

    setTempTo(undefined);
    setTempFrom(undefined);
  };

  useDidUpdate(() => {
    setDisableDaysBefore(disable_days_before);
  }, [disable_days_before]);

  useDidUpdate(() => {
    setDisableDaysAfter(disable_days_after);
  }, [disable_days_after]);

  useEffect(() => {
    const isFromDirection = direction === DateDirection.From;
    const isToDirection = direction === DateDirection.To;
    setDisableDaysBefore(disable_days_before);
    setDisableDaysAfter(disable_days_after);

    if (isFromDirection && to && max_interval) {
      const newDisabledBefore = dayjs(to).add(-max_interval, 'day').toDate();
      const isBeforeTotallyDisabled =
        disable_days_before && dayjs(newDisabledBefore).isBefore(dayjs(disable_days_before));

      if (!isBeforeTotallyDisabled) {
        setDisableDaysBefore(newDisabledBefore);
      }
    }

    if (isToDirection && from && max_interval) {
      const newDisabledAfter = dayjs(from).add(max_interval, 'day').toDate();
      const isAfterTotallyDisabled =
        disable_days_after && dayjs(newDisabledAfter).isAfter(dayjs(disable_days_after));

      if (!isAfterTotallyDisabled) {
        setDisableDaysAfter(newDisabledAfter);
      }
    }
  }, [direction, to]);

  const selected = useMemo(() => {
    return getSelectedDays(from, to, tempFrom, tempTo);
  }, [from, to, direction, tempFrom, tempTo]);

  useEffect(() => {
    const fromLabel = translations.label || translations.placeholder;
    const title = direction === DateDirection.From ? fromLabel : toLabel;
    setCalendarTitle(title);
  }, [direction]);

  return (
    <DatePickerInput
      id={realId}
      className={
        firstDayOfWeek === 1 ? 'form-datepicker--normal-weekdays' : 'form-datepicker--sunday_first'
      }
      value={tempFrom ?? from}
      translations={translations}
      name={name}
      hidden={hidden}
      required={required}
      hiddens={hiddens}
      showLabel={!no_labels}
      isPopupOpen={isPopupOpen}
      onPopupOpen={handlePopupOpen}
      onPopupClose={handlePopupClose}
      valueFormat={value_format}
      iconColor={color_icons}
      additionalInputRef={toWrapperRef}
      calendarTitle={calendarTitle}
      dayPickerProps={{
        mode: 'range',
        selected,
        disabled: getDisabledDays(disableDaysBefore, disableDaysAfter),
        numberOfMonths: monthsNumber,
        onDayClick: handleChange,
        onDayMouseEnter: handleMouseEnter,
        onDayMouseLeave: handleMouseLeave,
        weekStartsOn: firstDayOfWeek,
      }}
    >
      {!oneWay && translations.one_way && (
        <CloseModalLink id={widget_id} text={translations.one_way} />
      )}
    </DatePickerInput>
  );
};

export const DatePickerFrom = withMonthsNumber(DatePickerFromView);
