import { InjectedProps } from '../date_picker.types';

export type DatePickerFromProps = InjectedProps & {
  max_interval?: number;
};

export enum DateDirection {
  To = 'to',
  From = 'from',
}
