import dayjs from 'dayjs';

import { isUndefined } from '../../../../shared/lib/utils';

type SelectedDay = Date | undefined;

export const getSelectedDays = (
  from: SelectedDay,
  to: SelectedDay,
  tempFrom: SelectedDay,
  tempTo: SelectedDay,
) => {
  if (tempFrom) {
    const dayjsTo = dayjs(to);
    const isTempFromAfterTo = dayjs(tempFrom).isAfter(dayjsTo);

    if (isTempFromAfterTo) {
      return { to, from };
    }

    return { from: tempFrom, to };
  }

  if (tempTo) {
    const dayjsFrom = dayjs(from);
    const isTempToBeforeFrom = dayjs(tempTo).isBefore(dayjsFrom);

    if (isTempToBeforeFrom) {
      return { to, from };
    }

    return { to: tempTo, from };
  }

  if (isUndefined(from) && to) {
    return { from: to };
  }

  return { from, to };
};
