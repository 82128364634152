import { cloneElement } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { useDidMount } from 'rooks/dist/esm/hooks/useDidMount';
import { useDidUpdate } from 'rooks/dist/esm/hooks/useDidUpdate';
import { useWillUnmount } from 'rooks/dist/esm/hooks/useWillUnmount';

import { isNonEmptyString, isNull, isNumber } from '../../../../../shared/lib/utils';

import { AutocompleteMeta, AutocompleteProps, KeyDownHandlers } from './autocomplete.types';
import { useCallbackState } from './utils/use_callback_state';
import { composeEventHandlers } from './utils/compose_event_handlers';
import { getScrollOffset } from './utils/get_scroll_offset';

export const Autocomplete = <T,>(props: AutocompleteProps<T>) => {
  const {
    value = '',
    items,
    open = false,
    onChange,
    onSelect,
    onMenuVisibilityChange,
    shouldItemRender,
    sortItems,
    getItemValue,
    renderItem,
    className,
    wrapperProps = {},
    inputProps = {},
    autoHighlight = true,
    selectOnBlur = true,
    isItemSelectable = () => true,
    // eslint-disable-next-line react/jsx-props-no-spreading
    renderInput = (renderInputProps) => <input {...renderInputProps} />,
  } = props;

  const [isOpen, setIsOpen] = useCallbackState(open);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [menuWidth, setMenuWidth] = useState<number>(0);
  const autocompleteMetaRef = useRef<AutocompleteMeta>({
    ignoreBlur: false,
    ignoreFocus: false,
    scrollOffset: null,
    scrollTimer: null,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const setMenuPositions = () => {
    const inputEl = inputRef.current;

    if (inputEl) {
      const rect = inputEl.getBoundingClientRect();
      const computedStyle = global.window.getComputedStyle(inputEl);
      const marginLeft = parseInt(computedStyle.marginLeft, 10) || 0;
      const marginRight = parseInt(computedStyle.marginRight, 10) || 0;
      const width = rect.width + marginLeft + marginRight;

      setMenuWidth(width);
    }
  };

  const setIgnoreBlur = (ignore: boolean) => {
    autocompleteMetaRef.current.ignoreBlur = ignore;
  };

  const getFilteredItems = () => {
    let filteredItems = [...items];

    if (shouldItemRender) {
      filteredItems = filteredItems.filter((item) => shouldItemRender(item, value));
    }

    if (sortItems) {
      filteredItems.sort((a, b) => sortItems(a, b, value));
    }

    return filteredItems;
  };

  const ensureHighlightedIndex = () => {
    const listLength = getFilteredItems().length;

    if (isNumber(highlightedIndex) && highlightedIndex >= listLength) {
      setHighlightedIndex(null);
    }
  };

  const maybeAutoCompleteText = () => {
    let index = isNull(highlightedIndex) ? 0 : highlightedIndex;
    const filteredItems = getFilteredItems();

    for (let i = 0; i < filteredItems.length; i += 1) {
      if (isItemSelectable(filteredItems[index])) {
        break;
      }

      index = (index + 1) % filteredItems.length;
    }

    const matchedItem =
      filteredItems[index] && isItemSelectable(filteredItems[index]) ? filteredItems[index] : null;

    if (isNonEmptyString(value) && matchedItem) {
      const itemValue = getItemValue(matchedItem);
      const itemValueDoesMatch = itemValue.toLowerCase().indexOf(value.toLowerCase()) === 0;
      if (itemValueDoesMatch) {
        setHighlightedIndex(index);
      }
    }
  };

  const keyDownHandlers: KeyDownHandlers = {
    ArrowDown: (event) => {
      event.preventDefault();
      const filteredItems = getFilteredItems();

      if (!filteredItems.length) return;

      let index = isNull(highlightedIndex) ? -1 : highlightedIndex;

      for (let i = 0; i < filteredItems.length; i += 1) {
        const p = (index + i + 1) % filteredItems.length;
        if (isItemSelectable(filteredItems[p])) {
          index = p;
          break;
        }
      }

      if (index > -1 && index !== highlightedIndex) {
        setHighlightedIndex(index);
        setIsOpen(true);
      }
    },

    ArrowUp: (event) => {
      event.preventDefault();
      const filteredItems = getFilteredItems();
      if (!filteredItems.length) return;

      let index = isNull(highlightedIndex) ? filteredItems.length : highlightedIndex;

      for (let i = 0; i < filteredItems.length; i += 1) {
        const p = (index - (1 + i) + filteredItems.length) % filteredItems.length;
        if (isItemSelectable(filteredItems[p])) {
          index = p;
          break;
        }
      }

      if (index !== filteredItems.length) {
        setHighlightedIndex(index);
        setIsOpen(true);
      }
    },

    Enter: (event) => {
      // Key code 229 is used for selecting items from character selectors (Pinyin, Kana, etc)
      if (event.keyCode !== 13) return;
      // In case the user is currently hovering over the menu
      setIgnoreBlur(false);
      // menu is closed so there is no selection to accept -> do nothing
      if (!isOpen) return;

      if (isNull(highlightedIndex)) {
        // input has focus but no menu item is selected + enter is hit -> close the menu, highlight whatever's in input
        setIsOpen(false, () => {
          const inputEl = inputRef.current;

          if (inputEl) {
            inputEl.setSelectionRange(0, 9999);
          }
        });
      } else {
        // text entered + menu item has been highlighted + enter is hit -> update value to that of selected menu item, close the menu
        event.preventDefault();
        const item = getFilteredItems()[highlightedIndex];
        const itemValue = getItemValue(item);

        setIsOpen(false, () => {
          const inputEl = inputRef.current;

          if (inputEl) {
            inputEl.setSelectionRange(itemValue.length, itemValue.length);
            if (onSelect) {
              onSelect(itemValue, item);
              inputEl.blur();
            }
          }
        });
      }
    },

    Escape: () => {
      // In case the user is currently hovering over the menu
      setIgnoreBlur(false);
      setHighlightedIndex(null);
      setIsOpen(false);
    },

    Tab: () => {
      // In case the user is currently hovering over the menu
      setIgnoreBlur(false);
    },
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    const keyDownEventHandler = keyDownHandlers[event.key];
    if (keyDownEventHandler) {
      keyDownEventHandler(event);
    } else if (!isOpen) {
      setIsOpen(true);
    }
  };

  const selectItemFromMouse = (item: T) => {
    const itemValue = getItemValue(item);
    // The menu will de-render before a mouseLeave event
    // happens. Clear the flag to release control over focus
    setIgnoreBlur(false);
    setIsOpen(false, () => onSelect && onSelect(itemValue, item));
  };

  const renderMenu = () => {
    const filteredItems = getFilteredItems().map((item, index) => {
      const element = renderItem(item, highlightedIndex === index, {
        cursor: 'default',
      });
      return cloneElement(element, {
        onMouseEnter: isItemSelectable(item) ? () => setHighlightedIndex(index) : null,
        onClick: isItemSelectable(item) ? () => selectItemFromMouse(item) : null,
      });
    });
    const menu = props.renderMenu(filteredItems, value, {
      minWidth: menuWidth,
    });
    return cloneElement(menu, {
      // Ignore blur to prevent menu from de-rendering before we can process click
      onTouchStart: () => setIgnoreBlur(true),
      onMouseEnter: () => setIgnoreBlur(true),
      onMouseLeave: () => setIgnoreBlur(false),
    });
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (autocompleteMetaRef.current.ignoreBlur) {
      event.preventDefault();
      autocompleteMetaRef.current.ignoreFocus = true;
      autocompleteMetaRef.current.scrollOffset = getScrollOffset();
    }
    let setStateCallback;

    if (selectOnBlur) {
      const filteredItems = getFilteredItems();

      if (filteredItems.length) {
        const index = isNull(highlightedIndex) ? 0 : highlightedIndex;
        const item = filteredItems[index];
        const itemValue = getItemValue(item);
        setStateCallback = () => onSelect && onSelect(itemValue, item);
        setHighlightedIndex(index);
      }
    }

    return setIsOpen(false, setStateCallback);
  };

  const handleInputFocus = () => {
    if (autocompleteMetaRef.current.ignoreFocus && autocompleteMetaRef.current.scrollOffset) {
      autocompleteMetaRef.current.ignoreFocus = false;
      const { x, y } = autocompleteMetaRef.current.scrollOffset;
      autocompleteMetaRef.current.scrollOffset = null;
      // Focus will cause the browser to scroll the <input> into view.
      // This can cause the mouse coords to change, which in turn
      // could cause a new highlight to happen, cancelling the click
      // event (when selecting with the mouse)
      window.scrollTo(x, y);
      // Some browsers wait until all focus event handlers have been
      // processed before scrolling the <input> into view, so let's
      // scroll again on the next tick to ensure we're back to where
      // the user was before focus was lost. We could do the deferred
      // scroll only, but that causes a jarring split second jump in
      // some browsers that scroll before the focus event handlers
      // are triggered.
      if (!isNull(autocompleteMetaRef.current.scrollTimer)) {
        clearTimeout(autocompleteMetaRef.current.scrollTimer);

        autocompleteMetaRef.current.scrollTimer = Number(
          setTimeout(() => {
            autocompleteMetaRef.current.scrollTimer = null;
            window.scrollTo(x, y);
          }, 0),
        );
      }
      return;
    }
    setIsOpen(true);
  };

  const isInputFocused = () => {
    const inputEl = inputRef.current;
    return inputEl?.ownerDocument && inputEl === inputEl.ownerDocument.activeElement;
  };

  const handleInputClick = () => {
    // Input will not be focused if it's disabled
    if (isInputFocused() && !isOpen) {
      setIsOpen(true);
    }
  };

  const onComponentRender = (element: HTMLDivElement | null) => {
    if (element) {
      element.style.setProperty('display', 'block', 'important');
    }
  };

  useDidMount(() => {
    if (isOpen) {
      setMenuPositions();
    }
  });

  useWillUnmount(() => {
    const { scrollTimer } = autocompleteMetaRef.current;

    if (isNumber(scrollTimer)) {
      clearTimeout(scrollTimer);
      autocompleteMetaRef.current.scrollTimer = null;
    }
  });

  useDidUpdate(() => {
    ensureHighlightedIndex();

    if (autoHighlight && isNull(highlightedIndex)) {
      maybeAutoCompleteText();
    }
  });

  useDidUpdate(() => {
    if (autoHighlight && isNumber(highlightedIndex)) {
      maybeAutoCompleteText();
    }
  }, [value]);

  useDidUpdate(() => {
    setIsOpen(open);
  }, [open]);

  useDidUpdate(() => {
    if (isOpen) {
      setMenuPositions();
    }

    if (onMenuVisibilityChange) {
      onMenuVisibilityChange(isOpen);
    }
  }, [isOpen]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={onComponentRender} className={className} {...wrapperProps}>
      {renderInput({
        ...inputProps,
        role: 'combobox',
        'aria-autocomplete': 'list',
        'aria-expanded': isOpen,
        autoComplete: 'off',
        inputRef,
        onChange,
        onFocus: composeEventHandlers(handleInputFocus, inputProps.onFocus),
        onBlur: composeEventHandlers(handleInputBlur, inputProps.onBlur),
        onKeyDown: composeEventHandlers(handleKeyDown, inputProps.onKeyDown),
        onClick: composeEventHandlers(handleInputClick, inputProps.onClick),
        value,
      })}
      {isOpen && Boolean(items.length) && renderMenu()}
    </div>
  );
};
