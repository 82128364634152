/**
 * Глобальный сервис-синглтон. Лежит в window.CASCOON_GLOBAL и предоставляет виджетам
 * апи, необходимый для их запуска на странице.
 */

import { EventEmitter } from '../event_emitter';
import { BUILD_DIRECTORY, IS_PRODUCTION, WEBPACK_HASH } from '../../constants';

import { InitFn, LaunchFn, LaunchParams } from './types';

const createCommonJSURL = (host: string) => {
  const hashAppendix = WEBPACK_HASH ? `.${WEBPACK_HASH}` : '';

  if (IS_PRODUCTION) {
    const commonUrl = `${host}/${BUILD_DIRECTORY}/common${hashAppendix}.js`;
    return commonUrl;
  }

  return `http://localhost:8080/common${hashAppendix}.js`;
};

export class CascoonFrontendService {
  private widgetsCount: Record<string, number> = {};

  private launchQueue: LaunchParams[] = [];

  private commonJSInjected = false;

  emitter: EventEmitter | null = null;

  init: InitFn | null = null;

  registerEmitter(emitter: EventEmitter) {
    if (!this.emitter) this.emitter = emitter;
  }

  registerLaunchFn(launchFn: LaunchFn) {
    this.launch = launchFn;
    this.launchQueue.forEach((widget) => launchFn(widget));
    this.launchQueue = [];
  }

  registerInitFn(initFn: InitFn) {
    if (!this.init) this.init = initFn;
  }

  launch(params: LaunchParams) {
    this.launchQueue.push(params);
  }

  injectCommonJS(host: string) {
    if (!this.commonJSInjected) {
      const script = document.createElement('script');
      script.src = createCommonJSURL(host);
      document.head.appendChild(script);
      this.commonJSInjected = true;
    }
  }

  createNextWidgetId(id: string) {
    const newCount = id in this.widgetsCount ? this.widgetsCount[id] + 1 : 0;
    this.widgetsCount[id] = newCount;
    return `${id}_${newCount}`;
  }
}

export const cascoonInitService = window.CASCOON_GLOBAL || new CascoonFrontendService();

window.CASCOON_GLOBAL = cascoonInitService;
