import { h, FunctionalComponent } from 'preact';

import { Range } from '../../range';
import { useTranslations } from '../../../../../lib/use_translations';
import { DefaultFormData, RangeValues } from '../form.types';
import { getPriceText, getDurationText, getStopsText } from '../../../lib/get_range_translation';

import './styles.css';

type SubmitWrapperProps = RangeWrapperProps & {
  handleOnCancel: () => void;
};

type RangeWrapperProps = {
  preparedFormData: DefaultFormData;
  maxPriceRange: RangeValues;
  maxFlightDurationRange: RangeValues;
  maxStopsRange: RangeValues;
  priceRangeValue?: number;
  stopRangeValue?: number;
  flightDurationRangeValue?: number;
  currency: string;
};
const RangeWrapper: FunctionalComponent<RangeWrapperProps> = ({
  currency,
  maxPriceRange,
  maxFlightDurationRange,
  maxStopsRange,
  preparedFormData,
}) => {
  const { t } = useTranslations('subscription.popup');
  const { maxPrice, maxFlightDuration, maxStops } = preparedFormData;
  return (
    <div className="range-wrapper">
      {/* eslint-disable react/jsx-props-no-spreading */}
      {/* eslint-disable react/jsx-curly-newline */}
      <Range
        id="max_price"
        initialValue={maxPrice}
        getText={(value: number) =>
          getPriceText(value === maxPriceRange.max ? undefined : value, currency, t)
        }
        label={t('subscription_card.info.price')}
        {...maxPriceRange}
      />
      <Range
        id="max_flight_duration"
        initialValue={maxFlightDuration}
        getText={(value: number) =>
          getDurationText(value === maxFlightDurationRange.max ? undefined : value, t)
        }
        label={t('subscription_card.info.flight_duration')}
        {...maxFlightDurationRange}
      />
      <Range
        id="max_stops"
        initialValue={maxStops}
        getText={(value: number) =>
          getStopsText(value === maxStopsRange.max ? undefined : value, t)
        }
        label={t('subscription_card.info.stops')}
        {...maxStopsRange}
      />
    </div>
  );
};

const ButtonWrapper = ({ handleOnCancel }: { handleOnCancel: () => void }) => {
  const { t } = useTranslations('subscription.form');
  return (
    <div className="button-wrapper">
      <button
        type="button"
        onClick={handleOnCancel}
        className="subscription-button subscription-button--cancel"
      >
        {t('cancel')}
      </button>
      <button type="submit" className="subscription-button subscription-button--submit">
        {t('save')}
      </button>
    </div>
  );
};

export const SubmitWrapper: FunctionalComponent<SubmitWrapperProps> = ({
  currency,
  maxPriceRange,
  maxFlightDurationRange,
  maxStopsRange,
  preparedFormData,
  handleOnCancel,
}) => (
  <div className="submit-wrapper">
    <RangeWrapper
      currency={currency}
      maxPriceRange={maxPriceRange}
      maxFlightDurationRange={maxFlightDurationRange}
      maxStopsRange={maxStopsRange}
      preparedFormData={preparedFormData}
    />
    <ButtonWrapper handleOnCancel={handleOnCancel} />
  </div>
);
