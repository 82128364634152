import { h, FunctionalComponent, Fragment } from 'preact';
import { useMemo, useEffect } from 'preact/hooks';

import { Breakpoint, WidgetLayout } from '../../types/config/layout.types';
import { IWidget } from '../../types/widget.types';
import { WidgetConfig } from '../../types/config/config.types';
import { customStyleGenerator } from '../styles_common';

import { ComponentWrapperProps, createLayoutTree } from './create_layout_tree';

export type LayoutGridProps = {
  componentWrapper?: FunctionalComponent<ComponentWrapperProps>;
  layout: WidgetLayout;
  breakpoint: Breakpoint;
  config: WidgetConfig;
  widget: IWidget;
};

export const LayoutGrid = (props: LayoutGridProps) => {
  const { layout, breakpoint, componentWrapper, config, widget } = props;

  const layoutTree = useMemo(
    () => createLayoutTree(layout, config.fields, breakpoint, config, widget, componentWrapper),
    [config.fields, layout],
  );

  useEffect(() => {
    customStyleGenerator(layoutTree, widget);
  }, [layoutTree, widget]);

  return <Fragment>{layoutTree}</Fragment>;
};
