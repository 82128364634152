import { h, FunctionalComponent } from 'preact';

import { useTranslations } from '../../../../../lib/use_translations';
import { DateRangePicker, DatePicker } from '../../../../datepicker';
import { FuzzyDatesSelect } from '../../../fuzzy_dates/fuzzy_dates';
import { WidgetLayoutSettings } from '../../../../../types/config/layout.types';
import { IDayjsTranslations } from '../../../../../types/config/translations.types';
import { DefaultFormData } from '../form.types';

import './styles.css';

type DateRangeWrapperProps = {
  preparedFormData: DefaultFormData;
  oneWay: boolean;
  showFuzzyDate: boolean;
  translations: { dayjs: IDayjsTranslations };
  layoutConfig: WidgetLayoutSettings;
  widget_id: string;
  wrapper: HTMLElement;
  id: string;
};

export const DateRangeWrapper: FunctionalComponent<DateRangeWrapperProps> = ({
  preparedFormData,
  oneWay,
  showFuzzyDate,
  translations,
  layoutConfig,
  widget_id,
  wrapper,
  id,
}) => {
  const { t } = useTranslations('subscription.form');
  const { departDate, returnDate, months, vacationDuration } = preparedFormData;
  const dateRangePickerTranslations = {
    to_label: t('from'),
    from_label: t('to'),
    date_format: 'D MMM YYYY',
    dayjs: translations.dayjs,
    from_placeholder: t('depart'),
    to_placeholder: t('return'),
  };
  return (
    <div className="datepicker-wrapper">
      {showFuzzyDate ? (
        <FuzzyDatesSelect months={months} duration={vacationDuration} oneWay={oneWay} />
      ) : (
        <div className="datepicker-range">
          {oneWay ? (
            <DatePicker
              id={id}
              hidden={{ format: 'YYYY-MM-DD', name: 'depart_date' }}
              widget_id={widget_id}
              value={departDate as Date}
              layoutConfig={layoutConfig}
              wrapper={wrapper}
              required={!!true}
              name="from"
              translations={{
                label: t('to'),
                placeholder: t('return'),
                date_format: 'D MMM YYYY',
                dayjs: translations.dayjs,
              }}
            />
          ) : (
            <DateRangePicker
              to_required={!!true}
              from_required={!!true}
              to_name="to"
              from_name="from"
              from_value={departDate as Date}
              to_value={returnDate as Date}
              layoutConfig={layoutConfig}
              widget_id={widget_id}
              wrapper={wrapper}
              id={id}
              translations={dateRangePickerTranslations}
              hiddens={{ format: 'YYYY-MM-DD', from_name: 'depart_date', to_name: 'return_date' }}
            />
          )}
        </div>
      )}
    </div>
  );
};
