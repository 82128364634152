import { h, FunctionalComponent } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';

import { useTranslations } from '../../../../lib/use_translations';
import { Checkbox } from '../checkbox';
import { SubscriptionProps } from '../../subscription.types';

import { MAX_FLIGHT_DURATION_RANGE, MAX_PRICE_RANGE, MAX_STOPS_RANGE } from './constants';
import { PreparedData, CurrentData } from './form.types';
import { SubmitWrapper } from './submit_wrapper';
import { MainBlock } from './main_block';
import { DateRangeWrapper } from './date_range_wrapper';
import { getDefaultFormData, extractFormData, normalizeCurrencyParam } from './utils';
import './styles.css';

export const Form: FunctionalComponent<{
  props: SubscriptionProps;
  currentData: CurrentData;
  handleSubmitForm: (params: PreparedData) => void;
  handleOnCancel: () => void;
}> = ({ props, handleSubmitForm, currentData, handleOnCancel }) => {
  const { t } = useTranslations('subscription.form');
  const { originIata, destinationIata, shmarker, currency: currencyProps, locale } = props;

  const [oneWay, setOneWay] = useState<boolean>(currentData?.one_way ?? true);
  const [preparedFormData, setPreparedFormData] = useState(
    getDefaultFormData(currentData, originIata, destinationIata),
  );
  const [showFuzzyDate, setShowFuzzyDate] = useState(!!currentData?.months);
  const formRef = useRef<HTMLFormElement>(null);

  const currency = normalizeCurrencyParam(currencyProps, locale);

  useEffect(() => {
    setPreparedFormData(getDefaultFormData(currentData, originIata, destinationIata));
    setOneWay(!!currentData?.one_way);
    setShowFuzzyDate(!!currentData?.months);
  }, [currentData]);

  const toggleFuzzyDate = () => setShowFuzzyDate(!showFuzzyDate);

  const handleSubmit = (event: h.JSX.TargetedEvent) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const prepareData = extractFormData(formData, showFuzzyDate, shmarker);

    handleSubmitForm(prepareData);
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef} className="subscription-form">
      <div className="checkbox-wrapper">
        <Checkbox id="one_way" label={t('one_way')} checked={oneWay} onChange={setOneWay} />
      </div>
      <MainBlock
        preparedFormData={preparedFormData}
        toggleFuzzyDate={toggleFuzzyDate}
        showFuzzyDate={showFuzzyDate}
        props={props}
      />
      {/* eslint-disable react/jsx-props-no-spreading */}
      <DateRangeWrapper
        oneWay={oneWay}
        preparedFormData={preparedFormData}
        showFuzzyDate={showFuzzyDate}
        {...props}
      />
      <SubmitWrapper
        currency={currency}
        handleOnCancel={handleOnCancel}
        preparedFormData={preparedFormData}
        maxPriceRange={MAX_PRICE_RANGE}
        maxFlightDurationRange={MAX_FLIGHT_DURATION_RANGE}
        maxStopsRange={MAX_STOPS_RANGE}
      />
    </form>
  );
};
