import { countBy, get, isObjectLike as _isObjectLike } from 'lodash-es';

export {
  every,
  isEmpty,
  some,
  uniq,
  differenceBy,
  isEqual,
  set as setPropertyByPath,
  keyBy,
  merge,
  isEqualWith,
  unionBy,
  isFunction,
  isBoolean,
  isNull,
  isNil,
  invert,
  debounce,
  mapKeys,
  shuffle,
  noop,
} from 'lodash-es';

export const normalizeBoolean = (value: string | boolean | null | undefined): boolean =>
  typeof value === 'boolean' ? value : value?.toLowerCase() === 'true';

export const getPropertyByPath = <T>(...args: Parameters<typeof get>): T => get(...args);

export const isString = (value: any): value is string => typeof value === 'string';

export const isNonEmptyString = (value: any): value is string =>
  isString(value) && value.length > 0;

export const isNumber = (value: any): value is number => typeof value === 'number';

export const isUndefined = (value: any): value is undefined => value === undefined;

export const notNullish = <T>(value: T | undefined | null): value is T =>
  value !== undefined && value !== null;

export const isObjectLike = <T>(value: Parameters<typeof _isObjectLike>[0]): value is T =>
  _isObjectLike(value);

export const isArrayOfType = <T>(value: any, type: (value: any) => value is T): value is T[] =>
  Array.isArray(value) && value.every(type);

/**
 * Преобразует массив из строковых или числовых элементов в массив где каждому из
 * элеметов дан порядковый номер (если элемент встречается больше одного раза)
 * ['a','a','a','b','d','b','c',] ==> ['a_1','a_2','a_3','b_1','d','b_2','c'] (delimiter = '_')
 * @param array
 */
export const enumerateEqualValues = (
  array: Array<string | number>,
  countDelimiter = '_',
): Array<string> => {
  const countByValue = countBy(array);
  const enumerationStack = { ...countByValue };

  return array.map((element) => {
    if (countByValue[element] > 1) {
      enumerationStack[element] -= 1;
      const elementOccurrence = countByValue[element] - enumerationStack[element];
      return [element, elementOccurrence].join(countDelimiter);
    }

    return String(element);
  });
};

export const makeNumber = (value: string | number) =>
  isString(value) ? parseInt(value, 10) : value;

export const mergePartials = <T>(target: Partial<T>, source: Partial<T>): Partial<T> => {
  return { ...target, ...source };
};
