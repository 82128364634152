import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import './styles.css';

type RangeProps = {
  id: string;
  label: string;
  min: number;
  max: number;
  step: number;
  getText: (value: number) => void;
  initialValue: number;
};

export const Range: React.FC<RangeProps> = ({
  id,
  label,
  min,
  max,
  step,
  initialValue,
  getText,
}) => {
  const [value, setValue] = useState<number>(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    setValue(Number(target.value));
  };

  const valueMap = {
    [min]: min,
    [max]: max,
  };

  const displayValue = valueMap[value] || value;

  return (
    <div className="subscription-range">
      <p className="subscription-range__label">{`${label}: ${getText(displayValue)}`}</p>
      <input
        id={id}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className="subscription-range__input"
      />
      <input type="hidden" name={id} value={value} />
    </div>
  );
};
