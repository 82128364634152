import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import { clsx } from '../../../../shared/lib/clsx';
import { makeNumber } from '../../../../shared/lib/utils';

import { ListItemProps } from './list.types';

export const ListItem: FunctionalComponent<ListItemProps> = ({
  value,
  maxValue,
  minValue = 0,
  handleChange,
  fieldName,
  translations,
  hidden,
  total = Infinity,
  maxTotal = Infinity,
  iconColor,
}) => {
  const defaultValue = makeNumber(value) || 0;
  const [innerValue, setInnerValue] = useState(defaultValue);

  const handleIncrease = () => {
    const increaser = 1;
    const newValue = innerValue + increaser;
    const maxValueNumber = makeNumber(maxValue);

    if (newValue <= maxValueNumber && total + increaser <= maxTotal) {
      handleChange(fieldName, newValue, 1);
      setInnerValue(newValue);
    }
  };

  const handleDecrease = () => {
    if (innerValue) {
      const newValue = innerValue - 1;
      const minValueNumber = makeNumber(minValue);

      if (newValue >= minValueNumber) {
        handleChange(fieldName, newValue, -1);
        setInnerValue(newValue);
      }
    }
  };

  return (
    <li className="select-list-item" data-testid={`counters-select-list-item_${fieldName}`}>
      <div className="select-list-item-title">
        {translations.title}
        <span className="select-list-item-title__subtitle">{translations.subtitle}</span>
      </div>
      <div className="select-list-item-input">
        <button
          onClick={handleDecrease}
          type="button"
          className={clsx(
            'select-list-item-input__control',
            'select-list-item-input__control--dicrease',
            {
              'fill-default': iconColor,
            },
          )}
          data-testid={`counters-select-list-item_${fieldName}_decrease`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            color={iconColor}
          >
            <path d="M1 7C1 6.72386 1.22386 6.5 1.5 6.5H14.5C14.7761 6.5 15 6.72386 15 7V9C15 9.27614 14.7761 9.5 14.5 9.5H1.5C1.22386 9.5 1 9.27614 1 9V7Z" />
          </svg>
        </button>
        <span
          className="select-list-item-input__value"
          data-testid={`counters-select-list-item_${fieldName}_quantity`}
        >
          {innerValue}
        </span>
        <button
          onClick={handleIncrease}
          type="button"
          className={clsx(
            'select-list-item-input__control',
            'select-list-item-input__control--increase',
            {
              'fill-default': iconColor,
            },
          )}
          data-testid={`counters-select-list-item_${fieldName}_increase`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            color={iconColor}
          >
            <path d="M9.5 1.5C9.5 1.22386 9.27614 1 9 1H7C6.72386 1 6.5 1.22386 6.5 1.5V6C6.5 6.27614 6.27614 6.5 6 6.5H1.5C1.22386 6.5 1 6.72386 1 7V9C1 9.27614 1.22386 9.5 1.5 9.5H6C6.27614 9.5 6.5 9.72386 6.5 10V14.5C6.5 14.7761 6.72386 15 7 15H9C9.27614 15 9.5 14.7761 9.5 14.5V10C9.5 9.72386 9.72386 9.5 10 9.5H14.5C14.7761 9.5 15 9.27614 15 9V7C15 6.72386 14.7761 6.5 14.5 6.5H10C9.72386 6.5 9.5 6.27614 9.5 6V1.5Z" />
          </svg>
        </button>
      </div>
      {hidden && <input name={hidden.name} type="hidden" value={hidden.value} />}
    </li>
  );
};
