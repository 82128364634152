import { h, ComponentType, Component } from 'preact';
import dayjs from 'dayjs';

import { IUtilities } from '../types/utilities.types';

import { tpMediaLinkWrapper } from './tp_media_link_wrapper';
import { pluralisation } from './pluralisation';
import { Formatter } from './formatter';
import { emitter } from './event_emitter/initialized';

type Translations = string | Record<string, string>;

type Data = Record<string, any> | string | number | Array<any> | boolean;

export type ReceivedData = Record<string, Data>;

export interface IWrapperProps {
  dataSource?: string[] | string;
  componentData?: Record<string, Data>;
  data?: Record<string, Data>;
  computed_params?: Record<
    string,
    (
      params: IWrapperProps,
      data: Data,
      translations: Translations,
      utilities: IUtilities,
    ) => string | boolean
  >;
  widget_id: string;
  translations: Translations;
}

interface IWrapperState {
  data: Record<string, Data>;
  [key: string]: Data;
}

export type ComponentProps = IWrapperProps & {
  receivedData: Record<string, Data>;
  updateData: UpdateData;
};

export type UpdateData = (data: Record<string, Data>) => void;

const getStateWithComputedParams = <P extends IWrapperProps>(params: P, state: IWrapperState) => {
  const newState = state;
  const { computed_params, translations, dataSource } = params;

  if (computed_params) {
    Object.keys(computed_params).forEach((key) => {
      // Если в dataSource не массив, то засовывам его в массив иначе оставляем как было
      let preparedDataSource: string[] = [];
      if (dataSource) {
        preparedDataSource = Array.isArray(dataSource) ? dataSource : [dataSource];
      }

      const utilities = { formatter: Formatter, dayjs, plurals: pluralisation, tpMediaLinkWrapper };
      const paramsMerge = { ...params, ...state };

      // Собираем все значения
      let data = preparedDataSource.reduce<Record<string, any>>((acc, source) => {
        const dataKey = [params.widget_id, source].join('_');

        if (newState.data) {
          const newData = newState.data[dataKey] || newState.data[source];
          acc[source] = newData;
        }

        return acc;
      }, {});
      // Если в dataSource массив, то отправляем в конфиг весь объект, иначе только одно значение
      const firstDataKey = Object.keys(data)[0];
      data = Array.isArray(dataSource) ? data : data[firstDataKey];
      newState[key] = computed_params[key](paramsMerge, data, translations, utilities);
    });
  }

  return newState;
};

const initialize = <P extends IWrapperProps>(props: P, state: IWrapperState): IWrapperState => {
  let { dataSource: sources } = props;
  const { widget_id: wid } = props;

  if (!sources && !props.componentData) return state;
  if (typeof sources === 'string') sources = [sources];
  const newState = state;

  if (sources) {
    sources.forEach((source) => {
      const key = `${wid}_${source}`;
      if (newState.data) {
        newState.data[key] = (props.data && props.data[key]) || props.componentData || {};
      }
    });
  }

  return getStateWithComputedParams(props, newState);
};

export const withDataReceiver = <P extends IWrapperProps>(
  WrappedComponent: ComponentType<P & ComponentProps>,
) => {
  return class Wrapper extends Component<P, IWrapperState> {
    constructor(props: P) {
      super(props);
      this.state = {
        data: {},
      };
      this.state = initialize(props, this.state);
      this.initReceiver();
    }

    updateData: UpdateData = (data) => {
      this.setState(data);
    };

    initReceiver() {
      const { dataSource, widget_id: wid } = this.props;
      const { data: stateData } = this.state;
      const source = Array.isArray(dataSource) ? dataSource : [dataSource];

      if (source) {
        source.forEach((requestId) => {
          emitter.addListener(`${wid}_${requestId}_data_updated`, (data: Record<string, Data>) => {
            const newState = {
              data: stateData,
            };
            const key = `${wid}_${requestId}`;
            if (newState.data) {
              newState.data[key] = data;
            }

            this.setState(getStateWithComputedParams(this.props, newState));
          });
        });
      }
    }

    render() {
      const { data, ...restState } = this.state;

      return (
        <WrappedComponent
          receivedData={restState}
          updateData={this.updateData}
          {...this.props} // eslint-disable-line react/jsx-props-no-spreading
        />
      );
    }
  };
};
