import { FunctionalComponent } from 'preact';

import { Icon } from '../../../../subscriptions_popup/icon';
import { clsx } from '../../../../../../../shared/lib/clsx';

import './styles.css';

type Props = {
  isSingleActive: boolean;
  active: boolean;
  onClick: () => void;
};

export const Month: FunctionalComponent<Props> = ({
  active,
  isSingleActive,
  onClick,
  children,
}) => (
  <div
    onClick={onClick}
    className={clsx('month', active && 'month_active', isSingleActive && 'month_single')}
  >
    <div className="month__icon">
      <Icon type="plus-sign" />
      <Icon type="minus-sign" />
      <Icon type="checkmark" />
    </div>
    {children}
  </div>
);
