import { useTranslations } from '../../../../../lib/use_translations';

import { Month } from './month/month';

import './styles.css';

type MonthsSelectProps = {
  monthsIndex: Array<number>;
  activeMonthsIndex: Array<number>;
  onActiveMonthsChange: (activeMonths: Array<number>) => void;
};

export const MonthsSelect = ({
  monthsIndex,
  activeMonthsIndex,
  onActiveMonthsChange,
}: MonthsSelectProps) => {
  const { dayjs } = useTranslations();

  const onMonthClick = (index: number) => {
    if (activeMonthsIndex.includes(index)) {
      if (activeMonthsIndex.length > 1) {
        onActiveMonthsChange(activeMonthsIndex.filter((i) => i !== index));
      }
    } else {
      onActiveMonthsChange([...activeMonthsIndex, index]);
    }
  };

  return (
    <div className="months-select">
      {monthsIndex.map((index) => (
        <Month
          isSingleActive={activeMonthsIndex.length === 1}
          active={activeMonthsIndex.includes(index)}
          onClick={() => onMonthClick(index)}
        >
          {dayjs?.monthsShort[index]}
        </Month>
      ))}
    </div>
  );
};
