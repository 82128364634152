export enum Method {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

export interface IRequestError extends Error {
  meta: RequestErrorMeta;
}

type BaseParams = {
  path: string;
  jsonp?: boolean;
  params?: Record<string, string>;
  headers?: HeadersInit;
  attempts?: number;
  timeout?: number;
};

type Body = Record<string, any>;
type BodyParam = {
  body: Body;
};

type GetParams = BaseParams;
type PostParams = BaseParams & BodyParam;
type PutParams = BaseParams & BodyParam;

type HandleError = (data: IRequestError) => void;
export type RequestServiceData = {
  url: string;
  jsonp?: boolean;
  headers?: HeadersInit;
  credentials?: RequestCredentials;
  handleError?: HandleError;
};

export type RequestParams = GetParams & {
  body?: Body;
  method: Method;
  jsonp?: boolean;
};

type FabricResult = {
  readonly get: Get;
  readonly post: Post;
  readonly put: Put;
};

export type Get = <T>(data: GetParams) => ServiceResult<T>;
export type Post = <T>(data: PostParams) => ServiceResult<T>;
export type Put = <T>(data: PutParams) => ServiceResult<T>;
export type RequestService = (data: RequestServiceData) => FabricResult;
export type FetchData = <T>(data: {
  href: string;
  jsonp?: boolean;
  options: RequestInit;
  attempts?: number;
  handleError?: HandleError;
  timeout?: number;
}) => Promise<T>;

export type ServiceResult<T> = {
  readonly cancel: () => void;
  readonly request: Promise<T>;
};
export type RequestErrorMeta = {
  request: {
    url: string;
    headers?: HeadersInit;
    credentials?: Request['credentials'];
    method?: Request['method'];
    mode?: Request['mode'];
  } | null;
  response: {
    url: Response['url'];
    headers: Record<string, string>;
    type: Response['type'];
    status: Response['status'];
    redirected: Response['redirected'];
  } | null;
};
export type RequestErrorContext = {
  request: ({ url: string } & RequestInit) | null;
  response: Response | null;
};

export enum ErrorName {
  RequestError = 'RequestError',
}
