import { GetAutocompleteValueByIATAResponse } from './types';
import { hotellookAutocompleteService } from './services';

export const getLocationIdByIATA = async (IATA: string) => {
  return hotellookAutocompleteService
    .get<GetAutocompleteValueByIATAResponse>({
      path: '',
      params: {
        term: IATA.toUpperCase(),
        limit: String(5),
      },
    })
    .request.then((data) => {
      const firstCityWithThatIATA = data.cities.find((city) => city.iata?.includes(IATA));
      return firstCityWithThatIATA?.id || data.cities[0].id;
    });
};
