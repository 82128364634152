import { FunctionalComponent } from 'preact';

import { withDataReceiver } from '../../lib/with_data_receiver';
import { isNonEmptyString } from '../../../shared/lib/utils';

import { TitleProps, TitleViewProps } from './title.types';
import './index.css';

const TitleView: FunctionalComponent<TitleViewProps> = ({
  id,
  class: className,
  translations,
  title_size,
  subtitle_size,
  subtitle_opacity,
  receivedData: { title, subtitle },
}) => {
  const titleValue = isNonEmptyString(title) ? title : translations.title;
  const subtitleValue = isNonEmptyString(subtitle) ? subtitle : translations.subtitle;

  const onTitleRender = (element: HTMLDivElement | null) => {
    if (element) {
      element.style.setProperty('font-size', `${title_size}px`, 'important');
    }
  };

  const onSubtitleRender = (element: HTMLDivElement | null) => {
    if (element) {
      element.style.setProperty('font-size', `${subtitle_size}px`, 'important');
      element.style.setProperty('opacity', `${subtitle_opacity || 0.5}`, 'important');
    }
  };

  return (
    <div data-testid={`title-container-${id}`} id={id} className={`form-title ${className}`}>
      <div
        className="form-title__title"
        ref={onTitleRender}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: titleValue }}
        data-testid={`title-${id}`}
      />

      {subtitleValue && (
        <div
          className="form-title__subtitle"
          ref={onSubtitleRender}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: subtitleValue }}
          data-testid={`subtitle-${id}`}
        />
      )}
    </div>
  );
};

export const Title = withDataReceiver<TitleProps>(TitleView);
