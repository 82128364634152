import { IParams } from '../../types/node.types';
import { Pluralisation } from '../../lib/pluralisation';
import { WidgetGlobals } from '../../types/config/globals.types';
import { WidgetLayoutSettings } from '../../types/config/layout.types';
import { IDayjsTranslations } from '../../types/config/translations.types';

export enum ViewType {
  Full = 'full',
  Compact = 'compact',
}

export type SubscriptionProps = IParams & {
  view_type?: ViewType;
  no_labels: boolean;
  originIata?: string;
  destinationIata?: string;
  wrapper: HTMLElement;
  widget_id: string;
  layoutConfig: WidgetLayoutSettings;
  globals: WidgetGlobals;
  id: string;
  locale: string;
  hide_alert?: boolean | string;
  highlight?: boolean | string;
  shmarker: string;
  currency: string;
  target_host?: string;
  secondary?: string;
  plurals: Pluralisation;
  translations: {
    dayjs: IDayjsTranslations;
    title: string;
    description: string;
    email: { placeholder: string };
    subscribe: { text: string };
    agreement: { text: string; link: string };
    price_alert: { description: string; title: string };
    highlight_alert: { description: string; title: string };
  };
};
