import { useReducer } from 'preact/hooks';

import { InitialState, Action } from './chansey.types';

type Dispatch = (action: Action) => void;

const initialState: InitialState = {
  data: null,
  dates: { startDate: new Date(), endDate: new Date() },
  result: null,
  loading: true,
  resultLoading: false,
  error: null,
};

const reducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_DATA':
      return { ...state, data: action.payload, loading: false };
    case 'SET_DATES':
      return { ...state, dates: action.payload };
    case 'SET_RESULT':
      return { ...state, result: action.payload, resultLoading: false };
    case 'SET_LOADING':
      return { ...state, loading: true };
    case 'SET_RESULT_LOADING':
      return { ...state, resultLoading: true };
    default:
      return state;
  }
};

export const useChanseyReducer = (): [InitialState, Dispatch] => {
  return useReducer(reducer, initialState);
};
