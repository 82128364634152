import { FunctionalComponent } from 'preact';
import { useRef, useEffect, useCallback } from 'preact/hooks';

import { withDataReceiver } from '../../lib/with_data_receiver';
import { emitter } from '../../lib/event_emitter/initialized';

import { LoaderProps, LoaderViewProps } from './loader.types';

import './index.css';

const LoaderView: FunctionalComponent<LoaderViewProps> = ({
  widget_id: widgetId,
  request_id: requestId,
  id,
  receivedData,
  updateData,
  width,
  height,
  url,
}) => {
  const widgetWrapperRef = useRef<HTMLElement | null>(null);
  const handleChange = useCallback(() => {
    updateData({ hide: true });
  }, []);

  useEffect(() => {
    const eventName = `${widgetId}_${requestId}_data_error`;
    widgetWrapperRef.current = document.getElementById(widgetId);

    emitter.addListener(eventName, handleChange);

    return () => {
      emitter.removeListener(eventName, handleChange);
    };
  }, []);

  useEffect(() => {
    const { current } = widgetWrapperRef;

    if (receivedData.hide && current) {
      current.classList.remove('cascoon--loading');
    }
  }, [widgetWrapperRef, receivedData]);

  return (
    <div className={`loader-wrapper ${receivedData.hide ? 'loader-wrapper--hide' : ''}`}>
      <img
        id={id}
        alt="loader"
        className="loader super_loader"
        width={width}
        height={height}
        src={url}
      />
    </div>
  );
};

export const Loader = withDataReceiver<LoaderProps>(LoaderView);
