type ThrottleEventTarget<T> = T & {
  lastEvent?: number;
};

type ThrottleEvent<E, T> = E & {
  target: ThrottleEventTarget<T> | null;
};

// 100ms throttle rate which is too fast for human interaction
// Fastest gamers can button mash around 100ms for reference
const throttleDuration = 100;

// Prevent second and subsequent events of this type from firing within the throttleDuration
export const throttleEvent = <E extends React.SyntheticEvent, T extends EventTarget>(
  event: ThrottleEvent<E, T>,
  fn: (evnt: ThrottleEvent<E, T>) => void,
): boolean => {
  const element = event.target;
  const { timeStamp } = event;

  // set the last event time to a safe default for this element
  if (element && !element.lastEvent) {
    element.lastEvent = 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (element && timeStamp < element.lastEvent! + throttleDuration) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  if (element) {
    // Only set the new time stamp if the event is valid
    element.lastEvent = timeStamp;
  }

  if (fn) fn(event);

  return true;
};
