import { Translator } from '../../../lib/use_translations';

export const getPriceText = (price: number | undefined, currency: string, t: Translator) => {
  if (!price) {
    return t('price.any');
  }

  return t('price.capped', { price, currency });
};

export const getDurationText = (duration: number | undefined, t: Translator) => {
  return duration ? t('flight_duration.capped', { count: duration }) : t('flight_duration.any');
};

export const getStopsText = (stops: number | undefined, t: Translator) => {
  if (!stops) {
    return t('stops.any');
  }

  if (stops === 0) {
    return t('stops.none');
  }

  return t('stops.capped', { stops });
};
