import { FunctionalComponent } from 'preact';
import { useCallback, useRef, useState } from 'preact/hooks';
import { useDidMount } from 'rooks/dist/esm/hooks/useDidMount';
import { useDidUpdate } from 'rooks/dist/esm/hooks/useDidUpdate';
import { useDebounce } from 'rooks/dist/esm/hooks/useDebounce';

import { createWidgetRequestService } from '../../lib/widget_request';
import { generateUrl } from '../../lib/url';
import { isEqual, isFunction, isUndefined } from '../../../shared/lib/utils';
import { throttleEvent } from '../../lib/throttle_event';
import { withDataReceiver } from '../../lib/with_data_receiver';
import { getWhereami } from '../../api/whereami/api';

import { withSwitchedHiddens } from './utils/with_switched_hiddens';
import { Autocomplete as AC } from './components/autocomplete';
import {
  AutocompleteProps,
  AutocompleteState,
  AutocompleteViewProps,
  Item,
  Items,
} from './autocomplete.types';
import { api } from './utils/api';

import './index.css';

let AC_COUNT = 0;

const AutocompleteView: FunctionalComponent<AutocompleteViewProps> = ({
  id,
  widget_id,
  value: propsValue = '',
  url = '',
  custom_url = '',
  locale = 'ru',
  class: className,
  name,
  required,
  getUrl,
  receivedData,
  fetch_on_focus,
  fetch_default,
  geoip_default,
  no_labels,
  inverted,
  hiddens,
  translations,
  onSelected = () => null,
}) => {
  const [value, setValue] = useState(propsValue);
  const [items, setItems] = useState<Items>([]);
  const [item, setItem] = useState<Item>({});
  const [disabled, setDisabled] = useState(false);
  const uidRef = useRef(`cascoon-autocomplete_${(AC_COUNT += 1)}`);
  const itemRef = useRef<Item>(null);

  if (!isEqual(itemRef.current, item)) {
    itemRef.current = item;
  }

  const dataRequest = useCallback(
    (term: string, callback: (data: Items) => void) => {
      let preparedUrl = '';
      if (!getUrl) {
        try {
          preparedUrl = generateUrl(url, {
            term,
            locale,
          });
        } catch (_) {
          return;
        }
      } else {
        preparedUrl = getUrl({
          term,
          locale,
          receivedData,
        });
      }

      const { get } = createWidgetRequestService({ url: preparedUrl });

      get<Items>({ path: '/' }).request.then((data) => {
        if (!isUndefined(data)) {
          callback(data);
        }
      });
    },
    [receivedData, locale, getUrl],
  );

  const requestDefault = useCallback(() => {
    const callback = (data: Items) => {
      if (data && data.length) {
        const exactIndex = data.findIndex((newItem) => newItem.title === propsValue);
        const index = exactIndex < 0 ? 0 : exactIndex;
        setItems(data);
        setItem(data[index]);
        setValue(data[index].title);
      }
    };

    dataRequest(propsValue, callback);
  }, [dataRequest]);

  const requestDefaultGeoIp = useCallback(() => {
    getWhereami({ locale }).then((data) => {
      if (data) {
        setItem({ slug: data.iata, title: data.name, subtitle: data.country_name });
        setValue(data.name);
      }
    });
  }, []);

  const fetchData = useCallback(
    (term: string) => {
      if ((!term || term.length < 2) && !fetch_on_focus) {
        return false;
      }
      const callback = (newItems: Items) => setItems(newItems);

      return dataRequest(term, callback);
    },
    [dataRequest, fetch_on_focus],
  );

  const fetchDataDebounced = useDebounce(fetchData, 300);

  const handleChange = (newValue: string, newItem: Item) => {
    setValue(newValue);
    setItem(newItem);
  };

  const termChanged: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setItem({});
    fetchDataDebounced(newValue);
  };

  const onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (value) {
      setTimeout(event.target.setSelectionRange.bind(event.target, 0, 9999), 1);
    } else if (fetch_on_focus) {
      fetchData(value);
    }
  };

  const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const callback = () => setItems([]);

    return throttleEvent(event, callback);
  };

  const onFocusCapture: React.FocusEventHandler<HTMLInputElement> = (event) => {
    return throttleEvent(event, onFocus);
  };

  useDidMount(() => {
    if (!propsValue && geoip_default) {
      requestDefaultGeoIp();
    }

    if (propsValue && fetch_default) {
      requestDefault();
    }

    api.inputDisabled<boolean>(id, widget_id, (isDisabled) => setDisabled(isDisabled));
    api.setValue<string>(id, widget_id, (newValue) => setValue(newValue));
    api.getValue<(state: AutocompleteState) => void>(id, widget_id, (resolve) => {
      resolve({ value, item, items, disabled });
    });
  });

  useDidUpdate(() => {
    const state = { value, item, items, disabled };
    api.dataUpdated<AutocompleteState>(id, widget_id, state);

    if (propsValue !== item.slug && propsValue !== item.title) {
      onSelected(item.slug);
    }
  }, [itemRef.current]);

  useDidUpdate(() => {
    if (propsValue && propsValue !== item.slug && propsValue !== item.title) {
      requestDefault();
    }
  }, [propsValue]);

  return (
    <AC
      getItemValue={(acItem) => acItem.title}
      items={items}
      inputProps={{
        onFocus,
        onBlur,
        onFocusCapture,
        placeholder: translations.placeholder,
        name: name || widget_id,
        id: uidRef.current,
        required,
        disabled: Boolean(receivedData?.disabled) || disabled,
      }}
      renderItem={({ title, subtitle, slug }, isHighlighted, style) => (
        <div
          className={`autocomplete-items-item ${
            isHighlighted ? 'autocomplete-items-item--active' : ''
          }`}
          style={{ ...style }}
        >
          <span className="autocomplete-items-item__title">{title}</span>
          <span className="autocomplete-items-item__subtitle">{subtitle}</span>
          <span className="autocomplete-items-item__slug">{slug}</span>
        </div>
      )}
      renderMenu={(acItems, __, style) => (
        <div className="autocomplete-items" style={{ ...style }}>
          {acItems}
        </div>
      )}
      renderInput={({ inputRef, ...props }) => (
        <div className="autocomplete">
          {translations.label && !no_labels && (
            <label
              htmlFor={props.id}
              className={`autocomplete__label ${inverted ? 'autocomplete__label--inverted' : ''}`}
            >
              {translations.label}
            </label>
          )}
          <div className="autocomplete__field">
            <input
              type="text"
              className="autocomplete__input"
              ref={inputRef}
              data-testid={`autocomplete-input-${id}`}
              data-lpignore
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
            {item.slug && (
              <span className="autocomplete__slug input-before-gradient">
                <span>{item.slug}</span>
              </span>
            )}
          </div>
          {hiddens &&
            Object.keys(hiddens).map((key) => {
              const hidden = hiddens[key];
              const hiddenValue = isFunction(hidden) ? hidden(item) : item[hidden];

              return (
                <input
                  type="hidden"
                  name={key}
                  value={hiddenValue || custom_url}
                  data-testid={`autocomplete-hidden-${id}`}
                />
              );
            })}
        </div>
      )}
      value={value}
      onChange={termChanged}
      onSelect={handleChange}
      className={className}
    />
  );
};

export const Autocomplete = withDataReceiver<AutocompleteProps>(
  withSwitchedHiddens(AutocompleteView),
);
