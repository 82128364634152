export const currencies = {
  left: {
    usd: '$',
    cad: 'C$',
    eur: '€',
    amd: 'Դ',
    ars: '$',
    aud: 'A$',
    azn: '₼',
    bdt: 'BDT',
    brl: 'R$',
    chf: 'CHF',
    clp: 'C$',
    cny: '¥',
    cop: 'COL$',
    egp: 'E£',
    gbp: '£',
    hkd: 'HK$',
    huf: 'Ft',
    idr: 'Rp',
    ils: '‏₪',
    inr: '₹',
    jpy: '¥',
    krw: '₩',
    lkr: 'Rs',
    mur: 'Rs',
    mxn: 'Mex$',
    myr: 'RM',
    ngn: '₦',
    npr: 'रु',
    nzd: 'NZ$',
    pen: 'S/.',
    php: '₱',
    pkr: 'Rs',
    sgd: 'S$',
    thb: '฿',
    try: '₺',
    xof: 'FCFA',
    zar: 'R',
    zmw: 'ZK',
    vef: 'Bs.',
    mnt: '₮',
  },
  right: {
    aed: 'د.إ',
    afn: '؋',
    all: 'Lek',
    bam: 'KM',
    bgn: 'лв.',
    bhd: '.د.ب',
    bif: 'FBu',
    bnd: '$',
    bob: 'Bs',
    bwp: 'P',
    byn: 'Br',
    bzd: '$',
    cdf: 'FrCD',
    crc: '₡',
    cve: 'CV$',
    czk: 'Kč',
    djf: 'Fdj',
    dkk: 'kr',
    dop: 'RD$',
    dzd: 'د.ج.‏',
    eek: 'kr',
    ern: 'Nfk',
    etb: 'Br',
    gel: '₾',
    ghs: 'GH₵',
    gnf: 'FG',
    gtq: 'Q',
    hnl: 'L',
    hrk: 'kn',
    iqd: 'د.ع.‏',
    isk: 'kr.',
    jmd: '$',
    jod: 'د.ا.‏',
    kes: 'Ksh',
    khr: '៛',
    kmf: 'FC',
    kwd: 'د.ك.‏',
    kzt: '₸',
    kgs: 'com',
    lbp: 'ل.ل.‏',
    lvl: 'Ls',
    lyd: 'د.ل.‏',
    mad: 'د.م.‏',
    mdl: 'MDL',
    mga: 'MGA',
    mkd: 'MKD',
    tjs: 'TJS',
    mmk: 'K',
    mop: 'MOP$',
    mzn: 'MTn',
    nad: 'N$',
    nio: 'C$',
    nok: 'kr',
    omr: 'ر.ع.‏',
    pab: 'B/.',
    pln: 'zł',
    pyg: '₲',
    qar: 'ر.ق.‏',
    ron: 'lei',
    rsd: 'din',
    rub: String.fromCharCode(8381),
    rwf: 'FR',
    sar: 'ر.س.',
    sdg: 'SDG',
    sek: 'kr',
    sos: 'Ssh',
    syp: 'ل.س.‏',
    tnd: 'د.ت.‏',
    top: 'T$',
    ttd: '$',
    twd: 'NT$',
    tzs: 'TSh',
    uah: '₴',
    ugx: 'USh',
    uyu: '$',
    uzs: 'UZS',
    vnd: '₫',
    xaf: 'FCFA',
    yer: 'ر.ي.‏',
  },
};

export const defaultCurreny = 'usd';

export const availableCurrencies = (Object.keys(currencies) as Array<
  keyof typeof currencies
>).reduce((acc: string[], key) => {
  const currenciesKeys = Object.keys(currencies[key]);
  return [...acc, ...currenciesKeys];
}, []);
