import { FunctionalComponent } from 'preact';

import { IWidget } from '../types/widget.types';
import { WidgetConfig } from '../types/config/config.types';
import { Breakpoint, WidgetLayout } from '../types/config/layout.types';
import { LayoutGrid } from '../lib/layout_grid/layout_grid';
import { ComponentWrapperProps } from '../lib/layout_grid/create_layout_tree';

import { connectedComponents } from './index';

const FormWrapper: FunctionalComponent<{ id: string }> = ({ children, id }) => (
  <connectedComponents.Form id={id}>{children}</connectedComponents.Form>
);

const CommonWrapper: FunctionalComponent<{ globalStyles: WidgetConfig['globals']['styles'] }> = ({
  globalStyles,
  children,
}) => (
  <div className="root" style={globalStyles} data-testid="root">
    {children}
  </div>
);

/**
 * Корневой компонент вьюхи виджета
 * Рендерит непосредственно сам виджет, начиная от Form (или root-дива) и ниже
 * */
export const LayoutRoot = ({
  widget,
  config,
  currentLayout,
  activeBreakpoint,
  layoutComponentWrapper,
}: {
  widget: IWidget;
  config: WidgetConfig;
  currentLayout: WidgetLayout;
  activeBreakpoint: Breakpoint;
  layoutComponentWrapper?: FunctionalComponent<ComponentWrapperProps>;
}) => {
  const { id } = config;

  const layout = (
    <LayoutGrid
      layout={currentLayout}
      breakpoint={activeBreakpoint}
      widget={widget}
      config={config}
      componentWrapper={layoutComponentWrapper}
    />
  );

  if (config.globals.method) return <FormWrapper id={id}>{layout}</FormWrapper>;

  return <CommonWrapper globalStyles={config.globals.styles}>{layout}</CommonWrapper>;
};
