import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';

import { Category } from '../../constants/categories';
import { clsx } from '../../../shared/lib/clsx';
import { generateUrl, hasHTTP } from '../../lib/url';
import { templateReplacer } from '../../lib/template_replacer';

import { ErrorBannerProps } from './error_banner.types';

import './styles.css';

const IMG_CDN_URL = 'https://pics.avs.io/travelpayouts/128/30/';

const generateImageUrl = (category: Category) => {
  if (process.env.NODE_ENV === 'development') {
    return `url('http://localhost:8080/${category}.svg')`;
  }

  return `url('https://tp.media/cascoon/${category}.svg')`;
};

export const ErrorBanner: FunctionalComponent<ErrorBannerProps> = ({
  campaignId,
  host,
  globalUrl,
  journeyId,
  marker,
  promoId,
  traceId,
  locale,
  translation,
  showLogo = true,
  onClick,
  category,
}) => {
  const link = useMemo(() => {
    const withHost = hasHTTP(host);

    return generateUrl(globalUrl, {
      marker,
      p: promoId,
      u: withHost ? host : `https://${host}`,
      campaign_id: campaignId,
      trace_id: traceId,
      type: 'click',
      journey_id: journeyId,
      locale,
    });
  }, [host]);

  const certainTranslation = translation[category];
  const { subtitle, title, button } = certainTranslation;
  const replacedSubtitle = useMemo(() => {
    let website = host;

    // Пытаемся привести все ссылки в виду https://website.com
    if (/^\/\//.test(website)) {
      website = `https:${website}`;
    } else if (!hasHTTP(website)) {
      website = `https://${website}`;
    }

    try {
      // get domain from url string with regexp
      const domain = website.match(/^https?:\/\/([^/]+)/)?.[1];
      website = domain ?? new URL(website).hostname;
    } catch (_) {} //eslint-disable-line

    // Убираем www, он не нужен в описании
    website = website.replace(/^www./, '');

    // Kostyl: remove hydra from hydra.aviasales.ru
    website = website.replace(/(^hydra\.)(aviasales\.(.*)$)/, ($1, $2, truncatedDomain) => {
      return truncatedDomain;
    });

    return templateReplacer(subtitle, { website });
  }, [host]);

  return (
    <div data-testid="error-banner" className="error-banner">
      <section className="error-banner__image-wrapper">
        <div
          className="error-banner__image"
          style={{ backgroundImage: generateImageUrl(category) }}
        />
      </section>
      <section
        className={clsx('error-banner__content', {
          'error-banner__content--padding-top': !showLogo,
        })}
      >
        {showLogo && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="error-banner-logo"
            className="error-banner__logo"
            onClick={onClick}
          >
            <img
              srcSet={`${IMG_CDN_URL}${campaignId}@2x.png 2x`}
              src={`${IMG_CDN_URL}${campaignId}.png`}
              alt="logo"
            />
          </a>
        )}
        <span className="error-banner__title">{title}</span>
        <span className="error-banner__subtitle">{replacedSubtitle}</span>
        <div className="error-banner__button-wrapper">
          <a
            href={link}
            target="_blank"
            data-testid="error-banner-link"
            rel="noopener noreferrer"
            className="error-banner__button"
            onClick={onClick}
          >
            {button}
          </a>
        </div>
      </section>
    </div>
  );
};
