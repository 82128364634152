import { IMetrics, Meta, Params } from '../../types/metric.types';
import { WidgetParams } from '../../types/config/params.types';
import { WidgetGlobals } from '../../types/config/globals.types';
import { getPerformanceMetric } from '../performance';

import { Mamka } from './mamka';

interface IOptions {
  root: null | HTMLElement;
  threshold: number;
}

export enum ErrorCode {
  COMMON = 100,
  NOTHING_FOUND = 101,
}

export enum ErorrType {
  LEAD = 'error_lead',
  ERROR = 'error',
}

// Объекты ошибок не сериализуются так как не имеют перечисляемых свойств, эта функция конвертит объект ошибки в сериализуемый
const getSerializableError = (error: Error): string => {
  return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
};

const findNearestA = (
  node: HTMLAnchorElement,
  lastNode: HTMLAnchorElement,
): HTMLAnchorElement | null => {
  if (node.nodeName === 'A' && node.href) {
    return node;
  }

  if (node === lastNode || node.parentNode === null) {
    return null;
  }

  return findNearestA(node.parentNode as HTMLAnchorElement, lastNode);
};

export class Metrics implements IMetrics {
  private metricsHandler: Mamka;

  params: Params = {};

  widgetInfo = {};

  executed = false;

  wrapper: HTMLElement | null = null;

  constructor() {
    this.metricsHandler = new Mamka(`sp-cascoon${Math.random()}`, 'cascoon');
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onShow(element: HTMLElement, callback: () => void): void {
    const options = {
      root: null,
      threshold: 0.25,
    };
    function observeElement(el: HTMLElement, cb: () => void, op: IOptions) {
      let observer: null | IntersectionObserver = null;
      function handleObserve(entries: IntersectionObserverEntry[]) {
        const entry = entries[0];
        if (entry.isIntersecting) {
          cb();
          if (observer !== null) observer.unobserve(el);
        }
      }
      observer = new window.IntersectionObserver(handleObserve, op);
      observer.observe(el);
    }
    function observeElementFallback(el: HTMLElement, cb: () => void) {
      let throttledScrollHandler: null | (() => void) = null;
      function throttle(func: () => void, timeFrame: number) {
        let lastTime = 0;
        return () => {
          const now = Date.now();
          if (now - lastTime >= timeFrame) {
            func();
            lastTime = now;
          }
        };
      }
      function handleScroll() {
        const rect = el.getBoundingClientRect();
        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;
        const visibleHeight = rect.top + rect.height;
        const visibleWidth = rect.left + rect.width;
        const verticalVisible = rect.top <= windowHeight && visibleHeight >= 0;
        const horizontalVisible = rect.left <= windowWidth && visibleWidth >= 0;
        if (verticalVisible && horizontalVisible) {
          cb();
          if (throttledScrollHandler !== null)
            window.removeEventListener('scroll', throttledScrollHandler);
        }
      }
      const delay = 200;
      throttledScrollHandler = throttle(handleScroll, delay);
      window.addEventListener('scroll', throttledScrollHandler);
    }
    if (window.IntersectionObserver) {
      observeElement(element, callback, options);
    } else {
      observeElementFallback(element, callback);
    }
  }

  onInteract(element: HTMLElement, callback: () => void): void {
    const callbackFn = callback;
    const delay = 1000;
    const callbackWrapper = () => {
      if (!this.executed) {
        callbackFn();
        this.executed = true;
      }
    };
    const mouseOverDelay = (el: HTMLElement, cb: () => void) => {
      let timeout: number;
      el.addEventListener('mouseover', () => {
        timeout = window.setTimeout(() => cb(), delay);
      });
      el.addEventListener('mouseout', () => window.clearTimeout(timeout));
    };
    const touchOverDelay = (el: HTMLElement, cb: () => void) => {
      let timeout: number;
      el.addEventListener(
        'touchstart',
        () => {
          timeout = window.setTimeout(() => cb(), delay);
        },
        { passive: true },
      );
      el.addEventListener('touchend', () => window.clearTimeout(timeout));
    };
    mouseOverDelay(element, callbackWrapper);
    touchOverDelay(element, callbackWrapper);
    element.addEventListener('click', () => callbackWrapper());
  }

  getMetaData(element: HTMLElement) {
    const isTouch = () => {
      try {
        document.createEvent('TouchEvent');
        return true;
      } catch (e) {
        return false;
      }
    };
    const isIframe = window.self !== window.top;
    const x = element.getBoundingClientRect().left + window.pageXOffset;
    const y = element.getBoundingClientRect().top + window.pageYOffset;
    const deviceWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const deviceHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const width = element.clientWidth;
    const height = element.clientHeight;
    return {
      x,
      y,
      device_width: deviceWidth,
      device_height: deviceHeight,
      width,
      height,
      is_touch: isTouch(),
      is_iframe: isIframe,
    };
  }

  onClickHandler(event: MouseEvent) {
    const { target } = event;
    const aNode = findNearestA(target as HTMLAnchorElement, this.wrapper as HTMLAnchorElement);
    if (aNode) {
      this.send('lead', { href: aNode.href });
    }
  }

  onTabClick(promoId: number) {
    this.send('tab_click', { openned_tab_promo_id: promoId });
  }

  init(params: WidgetParams, globals: WidgetGlobals, wrapper: HTMLElement, scriptUrl = '') {
    const { hiddens, meta: { user_id, ...meta } = {}, ab_test } = globals;
    const { trace_id } = hiddens;
    const { promo_id, locale, shmarker, campaign_id, ...otherParams } = params;
    const origin_promo_id = ab_test?.ab_origin_promo_id || promo_id;
    const { webdriver, platform, vendor, language, userAgent } = window.navigator;

    const navigatorMetric = {
      navigator_webdriver: webdriver,
      navigator_platform: platform,
      navigator_vendor: vendor,
      navigator_language: language,
      useragent_info_header: userAgent,
    };
    this.wrapper = wrapper;
    this.wrapper.addEventListener('click', this.onClickHandler);
    this.params = {
      origin: window.location.href,
      script_url: scriptUrl,
      marker: shmarker,
      trace_id,
      promo_id,
      locale,
      campaign_id,
      ab_test,
      user_id,
      origin_promo_id,
      openned_tab_promo_id: promo_id,
    };

    this.widgetInfo = { ...otherParams, ...meta, ...navigatorMetric };
  }

  setParams(params: Params) {
    this.params = { ...this.params, ...params };
  }

  send(name: string, meta: Meta) {
    const viewportData = this.wrapper ? this.getMetaData(this.wrapper) : {};
    const promoId = this.params.promo_id;
    const performance = promoId ? getPerformanceMetric(promoId.toString()) : null;
    const widgetInfo = { ...this.widgetInfo, ...performance };
    const params = {
      ...this.params,
      ...viewportData,
      widget_info: { ...meta, ...widgetInfo },
    };

    this.metricsHandler.send(name, params);
  }

  sendError(code: ErrorCode, error: Error) {
    const meta = {
      error_code: code,
      error_description: error.message,
      error_object: getSerializableError(error),
    };

    this.send('error', meta);
  }

  sendErrorLead(code: ErrorCode, error: Error) {
    const meta = {
      error_code: code,
      error_description: error.message,
      error_object: getSerializableError(error),
    };

    this.send('error_lead', meta);
  }
}
