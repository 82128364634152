import { cloneElement, FunctionalComponent, toChildArray, VNode } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { emitter } from '../../lib/event_emitter/initialized';
import { ErrorCode } from '../../lib/metrics';
import { getPropertyByPath, isFunction, isString, makeNumber } from '../../../shared/lib/utils';
import { createWidgetRequestService } from '../../lib/widget_request';

import { ListData, ListProps, ReceivedData } from './list.types';

const getFormattingData = (data: ListData, length: number, listState?: boolean) => {
  if (!listState && length) {
    return data.slice(0, length);
  }

  return data;
};

export const List: FunctionalComponent<ListProps> = ({
  id,
  field,
  class: className,
  widget,
  metrics,
  settings,
  showCommonError,
  showNothingFoundError,
  children,
}) => {
  const length = makeNumber(
    widget.params.min_lines ? widget.params.min_lines : field.params.min_lines,
  );
  const sources = isString(field.params.dataSource)
    ? [field.params.dataSource]
    : field.params.dataSource;

  const [data, setData] = useState<ListData>([]);

  const getDataByPath = (dataToProcess: ReceivedData, path: string) => {
    const dataByPath = getPropertyByPath<ListData | undefined>(dataToProcess, path);

    if (!dataByPath) {
      console.log(`${widget.id}_${field.params.dataSource}_data_error`); // eslint-disable-line no-console
      console.warn(`Wrong data path ${field.params.path}`, dataToProcess); // eslint-disable-line no-console

      return dataToProcess;
    }

    return dataByPath;
  };

  // TODO: Вообще не понял что здесь происходит, переписал на предположениях. Нужно потестить хорошо и возможно исключить вероятность получения массива в аргументах
  const dataEventHandler = (dataToHandle: ReceivedData | ListData, dataSource: string) => {
    const preparedData =
      field.params.path && !Array.isArray(dataToHandle)
        ? getDataByPath(dataToHandle, field.params.path)
        : dataToHandle;

    if (!Array.isArray(preparedData) || !preparedData.length) {
      emitter.emitEvent(`${widget.id}_${dataSource}_data_error`);

      showNothingFoundError(new Error('Nothing found'));
      return;
    }

    const dataD = getFormattingData(preparedData, length);
    const events = `${widget.id}_${dataSource}_button-click`;

    setData(dataD);

    if (dataD.length === 0) {
      metrics.sendError(ErrorCode.NOTHING_FOUND, new Error('Nothing found'));
    }

    emitter.addListener(events, (listState: boolean) => {
      const newData = getFormattingData(preparedData, length, listState);

      setData(newData);
    });
  };

  const sendRequests = () => {
    const { dataRequests } = settings.config.globals;
    const { params } = widget;

    const initRequest = async (url: string, requestId: string) => {
      const { get } = createWidgetRequestService({ url });
      let result: { [key: string]: unknown; error?: string } = {};

      try {
        result = await get<typeof result>({ path: '/', attempts: 3, timeout: 300 }).request;
        emitter.emitEvent(`${widget.id}_${requestId}_data_updated`, [result]);
      } catch (error) {
        emitter.emitEvent(`${widget.id}_${requestId}_data_error`);
        showCommonError(result.error ? new Error(result.error) : (error as Error));
      }
    };

    if (dataRequests) {
      Object.keys(dataRequests).forEach((requestId) => {
        const request = dataRequests[requestId];
        const url = isFunction(request.url) ? request.url(params) : request.url;

        initRequest(url, requestId);
      });
    }
  };

  useEffect(() => {
    sendRequests();
    if (sources) {
      sources.forEach((request_id) => {
        emitter.addListener(
          `${widget.id}_${request_id}_data_updated`,
          (receivedData: ReceivedData) => {
            dataEventHandler(receivedData, request_id);
          },
        );
      });
    }
  }, []);

  const list = data.map((item) => {
    const update = (node: VNode) => {
      const childrenCount = toChildArray(node.props.children).length;

      if (Array.isArray(node)) {
        return node;
      }

      if (childrenCount) {
        const nodeClone = cloneElement(node, { ...node.props, componentData: item });
        const nodeCloneChildren = nodeClone.props.children;

        if (nodeCloneChildren && Array.isArray(nodeCloneChildren)) {
          nodeClone.props.children = nodeCloneChildren.map((child) => update(child as VNode));
        }

        return nodeClone;
      }

      const nodeClone = cloneElement(node, { ...node.props, componentData: item });

      return nodeClone;
    };

    if (children && Array.isArray(children)) {
      const updatedChildren = children.map((child) => update(child as VNode));

      return updatedChildren;
    }

    return children;
  });

  return sources ? (
    <div id={id} data-testid="list" className={`list_item ${className}`}>
      {list}
    </div>
  ) : null;
};
