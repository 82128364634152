import { useMemo, useState } from 'preact/hooks';

import { MonthsSelect } from './months_select/months_select';
import { MonthsRuler } from './months_ruler/months_ruler';

import './styles.css';

const createDateInfo = () => {
  const now = new Date();

  const currentMonthIndex = now.getMonth();

  const thisYearMonths = Array.from(
    { length: 12 - currentMonthIndex },
    (_, index) => index + currentMonthIndex,
  );
  const nextYearMonths = Array.from({ length: currentMonthIndex }, (_, index) => index);

  const currentYearIndex = now.getFullYear();

  const nextYearIndex = currentYearIndex + 1;

  return {
    allMonths: [...thisYearMonths, ...nextYearMonths],
    thisYearMonths,
    nextYearMonths,
    currentYearIndex,
    nextYearIndex,
  };
};

export const VacationMonths = ({ months }: { months: number[] }) => {
  const [activeMonths, setActiveMonths] = useState<Array<number>>(months);

  const { allMonths, currentYearIndex, nextYearIndex, thisYearMonths, nextYearMonths } = useMemo(
    createDateInfo,
    [],
  );

  return (
    <div className="vacation-months">
      <MonthsSelect
        monthsIndex={allMonths}
        activeMonthsIndex={activeMonths}
        onActiveMonthsChange={setActiveMonths}
      />
      <div className="vacation-months__ruler">
        <MonthsRuler
          segments={[
            { length: thisYearMonths.length, label: currentYearIndex, isInFuture: false },
            { length: nextYearMonths.length, label: nextYearIndex, isInFuture: true },
          ]}
        />
      </div>
      <select multiple name="months" hidden>
        {[...thisYearMonths, ...nextYearMonths].map((index) => (
          <option value={index} selected={activeMonths.includes(index)}>
            {index}
          </option>
        ))}
      </select>
    </div>
  );
};
