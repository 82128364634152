import { h, FunctionalComponent } from 'preact';

import { useTranslations } from '../../../../../lib/use_translations';
import { DefaultFormData } from '../form.types';
import { SubscriptionProps } from '../../../subscription.types';
import { CombinedAutocomplete } from '../../../../combined_autocomplete';
import { clsx } from '../../../../../../shared/lib/clsx';
import './styles.css';

type MainBlockProps = {
  preparedFormData: DefaultFormData;
  toggleFuzzyDate: () => void;
  showFuzzyDate: boolean;
  props: SubscriptionProps;
};

export const MainBlock: FunctionalComponent<MainBlockProps> = ({
  preparedFormData,
  toggleFuzzyDate,
  showFuzzyDate,
  props,
}) => {
  const { t } = useTranslations('subscription.form');

  const firstAutocompleteId = 'origin';
  const secondAutocompleteId = 'destination';
  const { originIata, destinationIata } = preparedFormData;

  const value = {
    [firstAutocompleteId]: originIata,
    [secondAutocompleteId]: destinationIata,
  };

  const hiddens = {
    origin: {
      origin_data: 'slug',
    },
    destination: {
      destination_data: 'slug',
    },
  };

  const translations = {
    [firstAutocompleteId]: { label: t('city_of_departure'), placeholder: t('city_of_departure') },
    [secondAutocompleteId]: {
      label: t('city_or_country_destination'),
      placeholder: t('city_or_country_destination'),
    },
  };

  const params = {
    url: 'https://suggest.travelpayouts.com/search?service=aviasales',
    fetch_default: true,
    fetch_on_focus: true,
    geoip_default: {
      origin: true,
    },
  };

  return (
    <div className="main-block-wrapper">
      <div className="auto-wrapper">
        {/* eslint-disable react/jsx-props-no-spreading */}
        <CombinedAutocomplete
          {...props}
          {...params}
          id="origin_and_destination"
          value={value}
          required={!!true}
          translations={translations}
          hiddens={hiddens}
        />
      </div>
      <div className="date-buttons-wrapper">
        <div
          className={clsx('date-button', {
            'date-button--tab': !showFuzzyDate,
            'date-button--button': showFuzzyDate,
          })}
          onClick={toggleFuzzyDate}
        >
          {t('exact_dates')}
        </div>
        <div
          className={clsx('date-button', {
            'date-button--button': !showFuzzyDate,
            'date-button--tab': showFuzzyDate,
          })}
          onClick={toggleFuzzyDate}
        >
          {t('flexible_dates')}
        </div>
      </div>
    </div>
  );
};
