import { useState } from 'preact/hooks';

import { CategorySelect } from '../category_select';
import { Layout } from '../layout';
import { HotelsByCategoriesData } from '../../blissey/blissey.types';
import { HotelsList } from '../hotels_list';

type Props = {
  hotelsByCategories: HotelsByCategoriesData;
  compact: boolean;
};

export const HotelsByCategories = ({ hotelsByCategories, compact }: Props) => {
  const [activeCategory, setActiveCategory] = useState(hotelsByCategories.categories[0]);

  return (
    <Layout
      header={() => (
        <CategorySelect
          categories={hotelsByCategories.categories}
          activeCategory={activeCategory}
          onCategoryChange={setActiveCategory}
        />
      )}
    >
      <HotelsList hotels={hotelsByCategories.hotels[activeCategory]} compact={compact} />
    </Layout>
  );
};
