import { FunctionalComponent } from 'preact';

import { withDataReceiver } from '../../lib/with_data_receiver';
import './index.css';
import { emitter } from '../../lib/event_emitter/initialized';

import { ButtonProps } from './button.types';

const ButtonView: FunctionalComponent<ButtonProps> = ({
  widget_id: widgetId,
  request_id: requestId,
  updateData,
  receivedData,
}) => {
  const { active } = receivedData;

  const handleClick = () => {
    const newActive = !active;

    updateData({ active });

    emitter.emitEvent(`${widgetId}_${requestId}_data_remove`);
    emitter.emitEvent(`${widgetId}_${requestId}_button-click`, [newActive]);
  };

  return (
    <div
      role="button"
      aria-label="show list"
      tabIndex={0}
      onClick={handleClick}
      className={`button-list ${active ? 'button-list--open' : ''}`}
    />
  );
};

export const Button = withDataReceiver<ButtonProps>(ButtonView);
