import { render } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { Components, connectedComponents } from '../../components';
import { DataProvider } from '../data_provider';
import { useWidgetResize } from '../use_widget_resize';
import { IWidget } from '../../types/widget.types';
import { WidgetConfig } from '../../types/config/config.types';
import { ErrorType } from '../../types/error.types';
import { ErrorCode, Metrics } from '../metrics';
import { Tabs } from '../../components/tabs';
import { LayoutRoot } from '../../components/layoutRoot';
import { sendRequests } from '../request';
import { Category, categoryMap } from '../../constants/categories';
import { cascoonLogger } from '../cascoon_logger';
import { CancellationError, RequestError } from '../../../shared/lib/request';
import { useConfigWithDynamicParameters } from '../cascoon_frontend_api/widget_config_with_dynamic_parameters';
import { listenJourneyId } from '../cascoon_frontend_api/journey_id';
import { clsx } from '../../../shared/lib/clsx';

import { LaunchFn } from './types';
import { initMetrics } from './init_metrics';

const WHITE_LABEL_CAMPAIGNS = ['100', '101'];

type ErrorDeclaration = {
  type: ErrorType;
  code: ErrorCode;
  error: Error;
  showLogo: boolean;
};

const getErrorCategory = (categoriesIds: number[]) => {
  if (categoriesIds.length === 0) {
    return Category.Default;
  }

  const firstCategoryId = categoriesIds[0];

  return categoryMap[firstCategoryId] ?? Category.Default;
};

/**
 * Рутовый компонент приложения виджета
 * Глобальная служебная обертка для виджета в пользовательском пространстве
 * Рендерит все необходимые контексты и обертки, определяет нужный для рендера лэйаут,
 * Выводит заглушку с ошибкой при ошибках, рендерит корневой компонент вьюхи виджета
 */
const Widget = ({
  config,
  widget,
  onError,
  metrics,
  onErrorClick,
}: {
  config: WidgetConfig;
  widget: IWidget;
  onError: (error: ErrorDeclaration) => void;
  onErrorClick: (error: ErrorDeclaration) => void;
  metrics: Metrics;
}) => {
  const finalConfig = useConfigWithDynamicParameters(widget.id, config);

  const { url, hiddens, meta } = finalConfig.globals;
  const { error_banner } = finalConfig.translations;
  const { categories_ids } = meta;
  const { type: widgetType, id: widgetId, params } = widget;
  const {
    campaign_id: campaignId,
    shmarker,
    promo_id: promoId,
    trace_id: traceId,
    locale,
    computedHost,
    direction,
  } = params;

  const [error, setError] = useState<ErrorDeclaration | null>(null);
  const { ref, layout, breakpoint } = useWidgetResize(finalConfig.layout, widgetId);

  const showError = (
    type: ErrorType = ErrorType.Common,
    code = ErrorCode.COMMON,
    rawError: Error,
  ) => {
    let showLogo = true;

    if (campaignId && WHITE_LABEL_CAMPAIGNS.includes(String(campaignId))) {
      showLogo = computedHost?.search(/aviasales|jetradar|hotellook/) >= 0;
    }
    setError({ showLogo, type, code, error: rawError });
  };

  useEffect(() => {
    if (error) onError(error);
  }, [error]);

  const showCommonError = (rawError: Error) => {
    showError(ErrorType.Common, ErrorCode.COMMON, rawError);
  };

  const showNothingFoundError = (rawError: Error) => {
    showError(ErrorType.NothingFound, ErrorCode.NOTHING_FOUND, rawError);
  };

  const category = getErrorCategory(categories_ids);

  const className = clsx(
    'cascoon-widget-resize-container',
    'cascoon',
    `cascoon-${widgetType}`,
    `cascoon-${widgetId}`,
    `cascoon--${breakpoint}`,
    { 'cascoon--error': error },
  );

  return (
    <Components.ErrorBoundary onError={showCommonError}>
      <DataProvider
        metrics={metrics}
        config={finalConfig}
        widget={widget}
        showCommonError={showCommonError}
        showNothingFoundError={showNothingFoundError}
      >
        <div
          dir={direction}
          data-cascoon-type={widgetType}
          data-cascoon-id={widgetId}
          id={widgetId}
          ref={ref}
          className={className}
        >
          {layout?.length && breakpoint && !error && (
            <LayoutRoot
              widget={widget}
              config={finalConfig}
              currentLayout={layout}
              activeBreakpoint={breakpoint}
            />
          )}
          {error && (
            <connectedComponents.ErrorBanner
              campaignId={campaignId}
              host={computedHost}
              globalUrl={url}
              marker={shmarker}
              promoId={promoId}
              traceId={traceId || hiddens.trace_id}
              locale={locale}
              translation={error_banner}
              type={error.type}
              showLogo={error.showLogo}
              onClick={onErrorClick}
              category={category}
              journeyId={hiddens.journey_id}
            />
          )}
        </div>
      </DataProvider>
    </Components.ErrorBoundary>
  );
};

/**
 * Функция для запуска виджета в пользовательской среде
 * Умеет правильно настроить аналитику и отрендерить виджет
 */
export const launchWidget: LaunchFn = ({
  widget,
  config,
  errorsHandler,
  wrapper,
  scriptUrl,
  tagName,
}) => {
  const metrics = initMetrics(widget.params, config.globals, wrapper, scriptUrl);

  const { offsetWidth: width, offsetHeight: height } = wrapper;

  cascoonLogger.log('[launchWidget]', widget.id, {
    widget,
    originalConfig: config,
    errorsHandler,
    metrics,
    wrapper,
  });

  listenJourneyId(widget.id, (id) => {
    metrics.setParams({ journey_id: id });
  });

  metrics.send('init', { width, height });

  wrapper.addEventListener('click', metrics.onClickHandler);

  const onError = (error: ErrorDeclaration) => {
    cascoonLogger.log('[launchWidget/onError]', widget.id, error);
    metrics.sendError(error.code, error.error);

    /**
     * В стату отправляем все ошибки кроме ошибок nothingFound и ошибок на уровне запросов, потому что такие ошибки возникают по причине
     * отсутствия рейсов на выбранные даты или при неверном указании ИАТ, если эти ошибки отправлять в роллбар -
     * их будет очень много
     * todo: Разделить реальные ошибки сети/запроса/нормализации/остальные
     */
    if (
      error.type !== ErrorType.NothingFound &&
      !(error.error instanceof RequestError) &&
      !(error.error instanceof CancellationError)
    )
      errorsHandler.send('renderError', error.error, config.id);

    wrapper.removeEventListener('click', metrics.onClickHandler);

    wrapper.style.setProperty('border', 'none', 'important');
    wrapper.style.setProperty('background-color', 'transparent', 'important');
    wrapper.classList.remove('cascoon--loading');
  };

  const onErrorClick = (error: ErrorDeclaration) => {
    metrics.sendErrorLead(error.code, error.error);
  };

  if (config.globals.dataRequests) {
    sendRequests(config, widget);
  }

  render(
    <Tabs
      params={widget.params}
      translations={config.translations.tabs}
      scriptOrigin={widget.scriptOrigin}
      metrics={metrics}
      tag={tagName}
    >
      <Widget
        config={config}
        widget={widget}
        metrics={metrics}
        onError={onError}
        onErrorClick={onErrorClick}
      />
    </Tabs>,
    wrapper,
  );
};
