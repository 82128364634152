import { FunctionalComponent } from 'preact';
import { useRef, useState } from 'preact/hooks';

import { pluralisation } from '../../lib/pluralisation';
import { makeNumber } from '../../../shared/lib/utils';
import ArrowIcon from '../../icons/select_arrow.svg';
import { clsx } from '../../../shared/lib/clsx';
import { useOutsideClick } from '../../lib/use_outside_click';

import { CountersSelectProps } from './counters_select.types';
import { List } from './list';
import './index.css';

export const CountersSelect: FunctionalComponent<CountersSelectProps> = ({
  fields,
  radio,
  translations,
  locale,
  no_labels,
  max_total: maxTotal = Infinity,
  showOverlay,
  hiddens,
  color_icons: iconColor,
}) => {
  const defaultTotal = fields.reduce((acc, { value }) => {
    const parsedValue = makeNumber(value);

    return acc + parsedValue;
  }, 0);

  const [total, setTotal] = useState(defaultTotal || 0);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleListClose = () => setOpen(false);

  useOutsideClick(wrapperRef, handleListClose);

  const handleTotalChange = (value: number) => {
    const newTotal = total + value;
    if (newTotal <= maxTotal) {
      setTotal(newTotal);
    }
  };

  return (
    <div ref={wrapperRef} className="counters-select">
      {translations.label && !no_labels ? (
        <div className="select-label" data-testid="counters-select-label">
          <span className="select-label__text">{translations.label}</span>
        </div>
      ) : (
        ''
      )}
      <div className="select-input-wrapper">
        <input
          value={
            total ? pluralisation(locale, total, translations.plural) : translations.placeholder
          }
          className="select-input like-input"
          data-testid="counters-select-input"
          readOnly
          onClick={(event) => {
            event.preventDefault();
            setOpen(!open);
          }}
        />
        <ArrowIcon
          className={clsx('select-arrow-svg', { 'fill-default': iconColor })}
          color={iconColor}
        />
      </div>
      <List
        isOpen={showOverlay || open}
        fields={fields}
        handleListClose={handleListClose}
        radio={radio}
        translations={translations}
        handleTotalChange={handleTotalChange}
        hiddens={hiddens}
        maxTotal={maxTotal}
        total={total}
        iconColor={iconColor}
      />
    </div>
  );
};
