import { FunctionComponent } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { createPopper, Instance } from '@popperjs/core';

import { TooltipProps } from './tooltip.types';
import './index.css';

export const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const { interactivity, target, position, placement, show, children, offset } = props;
  const [popperInstance, setPopperInstance] = useState<null | Instance>(null);
  const tooltip = useRef<HTMLDivElement>(null);

  const create = () => {
    if (target) {
      const popper = createPopper(target, tooltip.current, {
        placement,
        modifiers: [
          { name: 'flip' },
          { name: 'preventOverflow' },
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ],
      });

      setPopperInstance(popper);
    }
  };

  const destroy = () => {
    if (popperInstance) {
      popperInstance.destroy();
      setPopperInstance(null);
    }
  };

  const open = () => {
    tooltip.current.style.setProperty('display', 'block', 'important');
    create();
  };

  const close = () => {
    destroy();
    tooltip.current.style.setProperty('display', 'none', 'important');
  };

  const onMouseOverHandler = () => !interactivity && close();

  const onFocusHandler = () => !interactivity && close();

  useEffect(() => {
    if (show) {
      open();
    } else {
      close();
    }
  }, [show]);

  useEffect(() => {
    if (tooltip) {
      tooltip.current.style.setProperty('position', position, 'important');
    }
  }, [tooltip.current]);

  return (
    <div
      className="tooltip-wrapper"
      onFocus={onFocusHandler}
      onMouseOver={onMouseOverHandler}
      ref={tooltip}
      data-testid="tooltip"
    >
      {children}
    </div>
  );
};
