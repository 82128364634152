const getItem = (key: string) => localStorage.getItem(key) || '';
const setItem = (key: string, item: string) => localStorage.setItem(key, item);

const HIGHLIGHT_ALERT_KEY = 'tp-sbscr-popup-shown';
const EMAIL_KEY = 'tp-sbscr-email';

export const getEmailFromStorage = () => getItem(EMAIL_KEY);
export const setEmailToStorage = (email: string) => setItem(EMAIL_KEY, email);

export const getHighlightedShownFromStorage = () => getItem(HIGHLIGHT_ALERT_KEY);
export const setHighlightedShownToStorage = () => setItem(HIGHLIGHT_ALERT_KEY, 'true');
