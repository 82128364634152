import { Hotel } from '../../blissey/blissey.types';
import { clsx } from '../../../../../shared/lib/clsx';

import { MainInfo } from './main_info';
import { Gallery } from './gallery';
import { Rating } from './rating';
import { Offer } from './offer';

import './styles.css';

type Props = {
  hotel: Hotel;
  compact: boolean;
};

export const HotelCard = ({ hotel, compact }: Props) => {
  return (
    <div className={clsx('hotel-card', compact && 'hotel-card_compact')}>
      <div className="hotel-card__rating">
        <Rating rating={hotel.rating} explanation={compact} />
      </div>
      <div className="hotel-card__main-info">
        <MainInfo hotel={hotel} compact={compact} />
      </div>
      <div className="hotel-card__offer">
        <Offer hotel={hotel} compact={compact} />
      </div>
      <div className="hotel-card__gallery">
        <Gallery hotelId={hotel.hotel_id} />
      </div>
    </div>
  );
};
