import { FunctionalComponent } from 'preact';
import { useMemo, useRef } from 'preact/hooks';

import ClockSvg from '../../icons/clock.svg';
import { clsx } from '../../../shared/lib/clsx';
import {
  detectTimeFormat,
  TIME_FORMAT_MILITARY,
  timeStringToMilitaryTimeString,
  timeStringToStandardTimeString,
} from '../../lib/time';
import './index.css';

import { TimePickerProps } from './time_picker.types';

const calculateSteps = (interval: number, hour12: boolean) => {
  const intervalLength = interval;
  const intervalsPerday = Math.floor((60 * 24) / intervalLength);
  const intervalsAll = [];

  for (let i = 0; i < intervalsPerday; i += 1) {
    const timeFromMidnight = i * intervalLength;
    const timeStep = new Date().setHours(0, timeFromMidnight, 0, 0);
    const timeConfig: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: hour12 ? 'h12' : 'h23',
    };

    intervalsAll.push(new Date(timeStep).toLocaleTimeString([], timeConfig));
  }

  return intervalsAll;
};

export const TimePicker: FunctionalComponent<TimePickerProps> = (props) => {
  const {
    id,
    translations,
    no_labels: noLabels,
    widget_id: widgetId,
    interval,
    time_format_12_hours: timeFormat12Hours,
    value,
    color_icons: iconColor,
    widget: {
      params: { locale },
    },
  } = props;

  const isMilitaryTime = useMemo(() => {
    return locale && detectTimeFormat(locale) === TIME_FORMAT_MILITARY;
  }, [locale]);

  const selectRef = useRef<HTMLSelectElement>(null);
  const steps = useMemo(() => calculateSteps(interval, timeFormat12Hours), [
    interval,
    timeFormat12Hours,
  ]);

  return (
    <div data-testid="time_picker" className="time_picker">
      {translations.label && !noLabels ? (
        <label htmlFor={`${id}_${widgetId}`} className="time_picker__label">
          {translations.label}
        </label>
      ) : (
        ''
      )}
      <select
        data-testid="time_picker__select"
        className="time_picker__select"
        id={`${id}_${widgetId}`}
        name={`${id}_${widgetId}`}
        value={value.toUpperCase()}
        ref={selectRef}
      >
        {steps.map((time) => (
          <option key={time} value={time}>
            {` ${
              isMilitaryTime
                ? timeStringToMilitaryTimeString(time)
                : timeStringToStandardTimeString(time)
            } `}
          </option>
        ))}
      </select>
      <ClockSvg
        className={clsx('time_picker__clock_icon', { 'fill-default': iconColor })}
        color={iconColor}
      />
    </div>
  );
};
