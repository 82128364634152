export const categoriesTranslationsMap = {
  center: 'center',
  distance: 'distance',
  gay: 'gay',
  highprice: 'highprice',
  lake_view: 'lake_view',
  luxury: 'luxury',
  panoramic_view: 'panoramic_view',
  pets: 'pets',
  pool: 'pool',
  popularity: 'popularity',
  price: 'price',
  rating: 'rating',
  restaurant: 'restaurant',
  river_view: 'river_view',
  russians: 'russians',
  sea_view: 'sea_view',
  smoke: 'smoke',
  '0stars': 'stars0',
  '1stars': 'stars1',
  '2stars': 'stars2',
  '3stars': 'stars3',
  '4stars': 'stars4',
  '5stars': 'stars5',
  tophotels: 'tophotels',
};
