import { FunctionalComponent } from 'preact';

import './index.css';
import { CardProps } from './card.types';

export const Card: FunctionalComponent<CardProps> = ({ id, class: className, children }) => {
  return (
    <div id={id} data-testid="card" className={`card ${className}`}>
      {children}
    </div>
  );
};
