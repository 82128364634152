export enum ActionType {
  SubscriptionsReceived = 'SUBSCRIPTIONS_RECEIVED',
  SubscriptionCreated = 'SUBSCRIPTION_CREATED',
  SubscriptionUpdated = 'SUBSCRIPTION_UPDATED',
  SubscriptionDeleted = 'SUBSCRIPTION_DELETED',
  SubscriptionFrozen = 'SUBSCRIPTION_FROZEN',
  EmailConfirmationResent = 'EMAIL_CONFIRMATION_RESENT',
  SubscribedToNews = 'SUBSCRIBED_TO_NEWS',
  Error = 'ERROR',
  EmailResendError = 'EMAIL_RESEND_ERROR',
  Loading = 'LOADING',
}
