import { IParams } from '../types/node.types';

import { getValidProtocolUrl } from './url';

export const tpMediaLinkWrapper = (url: string, params: IParams): string => {
  const { hiddens = {}, url: globalUrl } = params.globals;

  const finalUrl = `${(Object.keys(hiddens) as Array<keyof typeof hiddens>).reduce(
    (tpMediaUrl, key) => {
      const hiddenValue = hiddens[key];
      const paramValue = params[hiddenValue as keyof IParams] as string;

      // Временное решение для удаления параметра trs из ссылки перехода для виджетов с типом не формы, когда значение trs в коде для вставки отсутствует.
      if (hiddenValue === 'trs' && !paramValue) {
        return tpMediaUrl;
      }
      return `${tpMediaUrl}${key}=${
        paramValue ? encodeURIComponent(paramValue) : encodeURIComponent(hiddenValue)
      }&`;
    },
    `${globalUrl}?`,
  )}u=${encodeURIComponent(getValidProtocolUrl(url))}`;

  return finalUrl;
};

export type TpMediaLinkWrapper = typeof tpMediaLinkWrapper;
