import { FunctionalComponent } from 'preact';

import './styles.css';

type CardInfoProps = {
  title: string;
};

export const CardInfo: FunctionalComponent<CardInfoProps> = ({ title, children }) => {
  return (
    <div className="sbscr-subscription-card-info">
      <div className="sbscr-subscription-card-info__title">{title}</div>
      <div className="sbscr-subscription-card-info__info">{children}</div>
    </div>
  );
};
