import { shuffle } from '../../../../shared/lib/utils';
import { BlisseyParamsWithLocationId, HotelsByCategoriesData } from '../blissey/blissey.types';

import { GetHotelsByLocationResponse } from './types';
import { normalizeHotelData } from './normalizations/normalize_hotel_data';
import { widgetLocationDumpService } from './services';
import { getAvailableCategories } from './get_available_categories';

export const getHotelsByLocation = async (
  params: BlisseyParamsWithLocationId,
): Promise<HotelsByCategoriesData> => {
  const { locationId, currency, language, limit, categories: categoriesFromParams } = params;

  const categories = await getAvailableCategories(locationId, categoriesFromParams);

  const response = await widgetLocationDumpService.get<GetHotelsByLocationResponse>({
    path: '',
    params: {
      currency,
      language,
      limit: String(limit),
      id: String(locationId),
      type: categories.join(','),
    },
  }).request;

  const normalized: HotelsByCategoriesData['hotels'] = {};

  Object.keys(response).forEach((categoryName) => {
    const hotels = response[categoryName];

    if (hotels.length) {
      normalized[categoryName] = shuffle(
        hotels.map((hotel) => normalizeHotelData(hotel, { locationId, blisseyParams: params })),
      );
    }
  });

  return { hotels: normalized, categories };
};
