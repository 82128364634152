import { useEffect, useRef, useState } from 'preact/hooks';

import { ImageSlider } from '../../../../../lib/image_slider';

import './styles.css';

type Props = {
  hotelId: number;
};

export const Gallery = ({ hotelId }: Props) => {
  const [images, setImages] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const { offsetWidth, offsetHeight } = ref.current;
      const newImages = Array.from({ length: 5 }, (_, i) => {
        return `https://photo.hotellook.com/image_v2/crop/h${hotelId}_${i}/${offsetWidth}/${offsetHeight}.auto`;
      });
      setImages(newImages);
    }
  }, []);

  return (
    <div ref={ref} className="gallery">
      {!!images.length && <ImageSlider images={images} />}
    </div>
  );
};
