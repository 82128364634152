import { emitter } from './event_emitter/initialized';

export interface IApi {
  getValue: <T>(fieldId: string, widgetId: string, callback: (data: T) => void) => void;
  setValue: (fieldId: string, widgetId: string, newValue: any) => void;
  subscribe: <T>(fieldId: string, widgetId: string, callback: (data: T) => void) => void;
}

export const api: IApi = {
  getValue(fieldId, widgetId, callback) {
    emitter.emitEvent(`${widgetId}_${fieldId}_get_value`, [callback]);
  },
  setValue(fieldId, widgetId, newValue) {
    emitter.emitEvent(`${widgetId}_${fieldId}_set_value`, [newValue]);
  },
  subscribe(fieldId, widgetId, callback) {
    emitter.addListener(`${widgetId}_${fieldId}_data_updated`, callback);
  },
};
