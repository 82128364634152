import { forwardRef, memo } from 'preact/compat';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { DateFormatter, DayPicker, WeekdayLabel } from 'react-day-picker';
import { createPopper, Instance } from '@popperjs/core';
import dayjs from 'dayjs';

import CloseIcon from '../../../icons/close.svg';
import CalendarIcon from '../../../icons/calendar.svg';
import { clsx } from '../../../../shared/lib/clsx';
import { LocaleUtils } from '../utils/locale_utils';
import { isUndefined } from '../../../../shared/lib/utils';
import { DatePickerDay } from '../date_picker_day';
import { DatePickerCaption } from '../date_picker_caption';
import { useOutsideClick } from '../../../lib/use_outside_click';
import { device } from '../../../lib/device';
import { setBodyScrollFreezeState } from '../../../../shared/lib/setBodyScrollFreezeState';

import { DatePickerInputProps } from './date_picker_input.types';

import './style.css';

const NotMemorizedDatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(
  (
    {
      id,
      value,
      required = false,
      disablable = false,
      showLabel = true,
      hiddens,
      hidden,
      valueFormat,
      className,
      translations,
      dayPickerProps,
      name,
      onInputClick,
      isPopupOpen = false,
      onPopupOpen,
      onPopupClose,
      onValueClear = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      iconColor,
      children,
      additionalInputRef,
      disabled = false,
      calendarTitle = '',
    },
    ref,
  ) => {
    const currentDate = dayjs().toDate();
    const localeUtils = useMemo(() => new LocaleUtils(translations), []);
    const wrapperTestId = `date_picker_wrapper_${id}`;
    const closeIconTestId = `date_picker_close_icon_${id}`;
    const hiddensTestId = `date_picker_hiddens_${id}`;
    const hiddenTestId = `date_picker_hidden_${id}`;
    const inputTestId = `date_picker_input_${id}`;
    const popupWrapperId = `date_picker_popup_${id}`;
    const isPopupActive = !isUndefined(dayPickerProps);
    const isCloseIconShown = disablable && value;

    const isMobile = device.isMobileSize();

    const popperRef = useRef<HTMLDivElement>(null);
    const optionalInputRef = useRef<HTMLInputElement>(null);
    const inputRef = ref ?? optionalInputRef;
    const isLabelShown = translations.label && showLabel;
    const [popperInstance, setPopperInstance] = useState<null | Instance>(null);

    const create = () => {
      if (inputRef.current) {
        const popper = createPopper(inputRef.current, popperRef.current, {
          placement: 'bottom-start',
          modifiers: [
            { name: 'flip' },
            {
              name: 'preventOverflow',
            },
            {
              name: 'offset',
              options: {
                offset: [0, 5],
              },
            },
          ],
        });

        setPopperInstance(popper);
      }
    };

    const destroy = () => {
      if (popperInstance) {
        popperInstance.destroy();
        setPopperInstance(null);
      }
    };

    const handlePopupOpen = () => {
      if (popperRef.current && !popperInstance) {
        popperRef.current.style.setProperty('display', 'block', 'important');
        create();
      }
    };

    const handlePopupClose = () => {
      if (popperRef.current && popperInstance) {
        popperRef.current.style.setProperty('display', 'none', 'important');
        destroy();
      }
    };

    const closePopper = () => {
      if (onPopupClose) {
        onPopupClose();
      } else {
        handlePopupClose();
      }
    };

    const openPopper = () => {
      if (onPopupOpen) {
        onPopupOpen();
      } else {
        handlePopupOpen();
      }
    };

    const handleInputClick = () => {
      if (isPopupActive) {
        openPopper();
      }

      if (onInputClick) {
        onInputClick();
      }
    };

    // prettier-ignore
    const handleOutsideClick = (event: Event) => {
      const targetElement = (
        event.composedPath ? event.composedPath()[0] : event.target
      ) as Element;
      const targetIsCurrentInput = targetElement !== inputRef.current;
      const targetIsAdditionalInput =
        additionalInputRef !== null && targetElement !== additionalInputRef;
      const isPopupCloseable = isPopupActive && targetIsCurrentInput && targetIsAdditionalInput;

      if (isPopupCloseable) {
        closePopper();
      }
    };

    useOutsideClick(popperRef, handleOutsideClick);

    useEffect(() => {
      if (isPopupOpen) {
        handlePopupOpen();
      } else {
        handlePopupClose();
      }
    }, [isPopupOpen]);

    useEffect(() => {
      setBodyScrollFreezeState(isMobile && isPopupOpen);
    }, [isMobile, isPopupOpen]);

    const formatCaption: DateFormatter = (date) => {
      const dayjsDate = dayjs(date);
      const month = dayjsDate.month();
      const year = dayjsDate.year();

      return `${translations.dayjs.months[month]} ${year}`;
    };

    const formatWeekdayName: WeekdayLabel = (date) => {
      const day = dayjs(date).day();

      return translations.dayjs.weekdaysShort[day];
    };

    const Picker = (
      <DayPicker
        formatters={{ formatCaption, formatWeekdayName }}
        components={{
          Day: DatePickerDay,
          Caption: DatePickerCaption,
        }}
        fromMonth={currentDate}
        {...dayPickerProps} // eslint-disable-line react/jsx-props-no-spreading
      />
    );

    return (
      <div
        className={clsx('form-datepicker', className, {
          'form-datepicker--disabled': disabled,
          'form-datepicker--mobile': isMobile,
        })}
        data-testid={wrapperTestId}
      >
        {isLabelShown && (
          <label htmlFor={id} className="form-datepicker__label">
            {translations.label}
          </label>
        )}
        <input
          required={required}
          id={id}
          name={name}
          value={value ? localeUtils.formatDay(dayjs(value)) : ''}
          type="text"
          autoComplete="off"
          className="form-datepicker__input"
          onClick={handleInputClick}
          placeholder={translations.placeholder}
          ref={inputRef}
          data-testid={inputTestId}
          onFocus={handleInputClick}
          inputMode="none"
        />
        <span className="input-before-gradient autocomplete__slug calendar-before-gradient" />
        {isCloseIconShown ? (
          <CloseIcon
            className={clsx('calendar-svg', 'calendar-svg--close', {
              'fill-default': iconColor,
            })}
            onClick={onValueClear}
            data-testid={closeIconTestId}
            color={iconColor}
          />
        ) : (
          <CalendarIcon
            color={iconColor}
            className={clsx('calendar-svg', { 'fill-default': iconColor })}
          />
        )}
        {isMobile && isPopupOpen && (
          <div className="mobile-popup" data-testid={popupWrapperId}>
            <div className="mobile-popup__header">
              <div className="mobile-popup__title">{calendarTitle}</div>
              <CloseIcon
                className={clsx('mobile-popup__close', {
                  'fill-default': iconColor,
                })}
                onClick={onPopupClose}
                color={iconColor}
              />
            </div>
            <div className="mobile-popup__picker">{Picker}</div>
            <div className="mobile-popup__footer">
              <div className="mobile-popup__actions">{children}</div>
              <div className="mobile-popup__close" onClick={onPopupClose}>
                {translations.dayjs.close}
              </div>
            </div>
          </div>
        )}
        {!isMobile && isPopupActive && (
          <div
            tabIndex={-1}
            ref={popperRef}
            role="dialog"
            data-toggle="tooltip"
            className="form-datepicker__popup"
            data-testid={popupWrapperId}
          >
            {Picker}
            {children}
          </div>
        )}

        {hiddens &&
          Object.keys(hiddens).map((key) => {
            return (
              <input
                key={key}
                disabled={disabled}
                type="hidden"
                name={hiddens[key]}
                value={value ? dayjs(value).format(valueFormat) : ''}
                data-testid={hiddensTestId}
              />
            );
          })}
        {hidden && (
          <input
            id={hidden.name}
            name={hidden.name}
            disabled={disabled}
            type="hidden"
            value={value ? dayjs(value).format(hidden.format) : ''}
            data-testid={hiddenTestId}
          />
        )}
      </div>
    );
  },
);

export const DatePickerInput = memo(NotMemorizedDatePickerInput);
