import { h, FunctionComponent } from 'preact';
import { differenceInDays, format } from 'date-fns';

import { tpMediaLinkWrapper } from '../../../lib/tp_media_link_wrapper';
import { Formatter } from '../../../lib/formatter';
import { HotelOffer, SearchTranslations } from '../chansey.types';

import { ResultLinksProps, OfferLinkProps, ResultButtonProps } from './result_links.types';

import './result_links.css';

const formatPrice = (price: number, currency: string): string => {
  return Formatter.money(Number(price), currency);
};

const getButtonText = (result: HotelOffer, search: SearchTranslations): string => {
  return `${search.button_text} ${formatPrice(result.price, result.currency)} ${
    search.booking_at
  } ${result.gatename}`;
};

const OfferLink: FunctionComponent<OfferLinkProps> = ({ offer, params }) => {
  const href = tpMediaLinkWrapper(offer.deeplink, params);
  const priceText = formatPrice(offer.price, offer.currency);

  return (
    <a className="chansey-result-link" href={href} rel="noreferrer" target="_blank">
      <span className="offer-link-gatename-text">{`${offer.gatename} `}</span>
      <span className="offer-link-price-text">{priceText}</span>
    </a>
  );
};

const ResultButton: FunctionComponent<ResultButtonProps> = ({ result, search, params }) => {
  const href = tpMediaLinkWrapper(result.deeplink, params);
  const buttonText = getButtonText(result, search);

  return (
    <a className="chansey-result-button" href={href} rel="noreferrer" target="_blank">
      <button type="button" className="button">
        <span className="result-link-price-text">{buttonText}</span>
      </button>
    </a>
  );
};

export const ResultLinks: FunctionComponent<ResultLinksProps> = ({
  result,
  search,
  dates,
  params,
  onClick,
  plurals,
  locale,
}) => {
  const nightCount = differenceInDays(dates.endDate, dates.startDate);
  const formattedDates = `${format(dates.startDate, 'dd.MM')} - ${format(dates.endDate, 'dd.MM')}`;
  const additionalOffers = result.slice(1);
  const resultPriceText = `${search.nigths_price_price} ${search.nigths_price_for} ${plurals(
    locale,
    nightCount.toString(),
    search.nights_plural,
  )}`;

  return (
    <div className="chansey-result-links">
      <div className="chansey-result-wrapper">
        <ResultButton result={result[0]} search={search} params={params} />
        <div className="result-title">
          <span onClick={onClick} className="result-dates">
            {formattedDates}
          </span>
          <span className="result-price">{resultPriceText}</span>
        </div>
      </div>
      <div className="chansey-offer-wrapper">
        {additionalOffers.map((offer) => (
          <OfferLink params={params} offer={offer} />
        ))}
      </div>
    </div>
  );
};
