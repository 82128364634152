import { IWidget } from '../types/widget.types';
import { WidgetConfig } from '../types/config/config.types';

import { createWidgetRequestService } from './widget_request';
import { emitter } from './event_emitter/initialized';

export const sendRequests = (config: WidgetConfig, widget: IWidget) => {
  const { params } = widget;
  const initRequest = async (url: string, id: string) => {
    const { get } = createWidgetRequestService({ url });

    try {
      const result = await get({ path: '/', attempts: 3, timeout: 300 }).request;

      emitter.emitEvent(`${widget.id}_${id}_data_updated`, [result]);
    } catch (_) {
      emitter.emitEvent(`${widget.id}_${id}_data_error`);
    }
  };

  const { dataRequests } = config.globals;

  if (dataRequests) {
    Object.keys(dataRequests).forEach((id) => {
      const request = dataRequests[id];
      const url = typeof request.url === 'function' ? request.url(params) : request.url;
      initRequest(url, id);
    });
  }
};
