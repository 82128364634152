import * as CSS from 'csstype';
import { VNode } from 'preact';

import { IIEHTMLStyleElement } from '../types/custom.types';
import { IWidget } from '../types/widget.types';
import { IProps, IStyle } from '../types/node.types';
import { Breakpoint, Breakpoints } from '../types/config/layout.types';

const breakpointsList: Breakpoints = [
  Breakpoint.XL,
  Breakpoint.L,
  Breakpoint.M,
  Breakpoint.S,
  Breakpoint.XS,
];
const indent = (str: string, count: number) => str.replace(/^(?!\s*$)/gm, ' '.repeat(count));

export const appendStyles = (styles: string, wrapper: HTMLElement): void => {
  const style: IIEHTMLStyleElement = document.createElement('style');

  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = styles;
  } else {
    style.appendChild(document.createTextNode(styles));
  }
  if (wrapper?.parentNode) {
    wrapper.parentNode?.insertBefore(style, wrapper);
  } else {
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(style);
  }
};

export const getStyles = (styleAST: Record<string, IStyle>[], id: string): string =>
  styleAST.reduce((styles, cssObject) => {
    const className = Object.keys(cssObject)[0];
    let objectWithCss = {};
    const createSelector = (selector: string, modifier: string) => {
      // TODO убрать когда поменяем в конфиге :before, :after на &:before, &:after
      if (modifier.charAt(0) === ':') {
        return `${selector + modifier}`;
      }
      if (modifier.charAt(0) === '&') {
        return `${selector + modifier.substr(1)}`;
      }
      if (modifier.charAt(modifier.length - 1) === '&') {
        return `${modifier.slice(0, -1) + selector}`;
      }
      return `${`${selector} ${modifier}`}`;
    };
    const css = breakpointsList.reduce((acc, breakpoint) => {
      const selector = `.cascoon-${id}.cascoon--${breakpoint} .root .${className}`;
      objectWithCss = cssObject[className][breakpoint] || objectWithCss;
      let pseudoStyle = '';
      const nestedStyle = (
        nested: Record<string, string>,
        nestedSelector: string,
        style: string,
      ): string => {
        return Object.keys(nested).reduce((sum, props) => {
          const cssProp = nested[props];
          if (typeof cssProp === 'object') {
            return nestedStyle(cssProp, createSelector(nestedSelector, props), sum);
          }

          return `${sum}\n${nestedSelector} {${indent(
            // TODO: Циклический вызов, нужно подумать как решить
            getStyleProps({ [props]: cssProp }), // eslint-disable-line
            2,
          )}\n${'}'}`;
        }, style);
      };

      const getStyleProps = (object: CSS.PropertiesHyphen): string =>
        Object.keys(object).reduce((sum, props) => {
          const cssProp = object[props as keyof CSS.PropertiesHyphen];

          if (typeof cssProp === 'object') {
            pseudoStyle = nestedStyle(cssProp, createSelector(selector, props), pseudoStyle);
            return sum;
          }
          return `${sum}\n${props}: ${cssProp};`;
        }, '');

      return `${acc}\n${selector} {${indent(
        getStyleProps(objectWithCss),
        2,
      )}\n${'}'}${pseudoStyle}`;
    }, '');

    return styles + css;
  }, '');

export const customStyleGenerator = (nodes: VNode<IProps>[], widget: IWidget) => {
  const merge = (acc: Record<string, IStyle>[], node: VNode<IProps>): Record<string, IStyle>[] => {
    if (node.props && node.props.style) {
      const { id, style } = node.props;
      acc.push({ [id]: style });
    }
    if (node.props.children && Array.isArray(node.props.children) && node.props.children.length) {
      return node.props.children.reduce(merge, acc);
    }
    return acc;
  };

  const styleAST = nodes.reduce(merge, []);

  appendStyles(getStyles(styleAST, widget.id), widget.wrapper);
};
