import { clsx } from '../../../../../../shared/lib/clsx';
import './styles.css';

type Props = {
  segments: Array<{ length: number; label: string | number; isInFuture: boolean }>;
};

export const MonthsRuler = ({ segments }: Props) => {
  return (
    <div className="months-ruler">
      {segments.map((segment) => (
        <div
          className={clsx(
            'months-ruler__segment',
            segment.isInFuture && 'months-ruler__segment_future',
          )}
          style={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            { '--width': segment.length }
          }
        >
          <div className="months-ruler__line" />
          <div className="months-ruler__label">{segment.label}</div>
          <div className="months-ruler__line" />
        </div>
      ))}
    </div>
  );
};
