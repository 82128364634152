import dayjs from 'dayjs';

import { dayjsLocalisation } from '../../../lib/dayjs_localisation';
import { DatePickerTranslations } from '../date_picker.types';

export class LocaleUtils {
  private dayjsLocale!: ILocale;

  private dateFormat?: string;

  constructor(translations: DatePickerTranslations) {
    if (!translations.dayjs) return;
    this.dayjsLocale = dayjsLocalisation(translations.dayjs);
    this.dateFormat = translations.date_format;
    this.formatDay = this.formatDay.bind(this);
  }

  formatDay(d: dayjs.ConfigType) {
    return dayjs(d).locale(this.dayjsLocale).format(this.dateFormat);
  }
}
