export enum Category {
  TrainsAndBuses = 'trains_and_buses',
  CarAndBikeRentals = 'car_and_bike_rentals',
  Cruises = 'cruises',
  Flights = 'flights',
  Accommodation = 'accommodation',
  Insurances = 'insurances',
  PackageTours = 'package_tours',
  ToursAndActivities = 'tours_and_activities',
  Default = 'default',
}

export const categoryMap: Record<number, Category> = {
  1: Category.PackageTours,
  2: Category.Insurances,
  3: Category.CarAndBikeRentals,
  5: Category.TrainsAndBuses,
  6: Category.ToursAndActivities,
  7: Category.CarAndBikeRentals,
  8: Category.Cruises,
  9: Category.Accommodation,
  10: Category.TrainsAndBuses,
  14: Category.Flights,
  27: Category.Cruises,
};
