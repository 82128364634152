import { Icon } from '../../icon';

import './styles.css';

export const StarsRating = ({ stars }: { stars: number }) => (
  <div className="stars-rating">
    {Array.from({ length: stars }).map(() => (
      <Icon type="star" height={11} />
    ))}
  </div>
);
