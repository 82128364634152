export const switchKeysWithValues = <T extends Record<string, string>>(obj: T) => {
  const values = Object.values(obj);

  return Object.keys(obj).reduce(
    (acc: Record<string, string>, item, index) => ({ ...acc, [values[index]]: item }),
    {},
  );
};

export const conditionalField = (
  field: string,
  value: any,
  condition: boolean,
): Record<string, any> => {
  if (condition) {
    const finalValue = typeof value === 'function' ? value() : value;
    return {
      [field]: finalValue,
    };
  }
  return {};
};
