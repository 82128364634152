import { Metrics } from '../metrics';
import { WidgetParams } from '../../types/config/params.types';
import { WidgetGlobals } from '../../types/config/globals.types';

export const initMetrics = (
  params: WidgetParams,
  configGlobals: WidgetGlobals,
  wrapper: HTMLElement,
  scriptUrl: string,
) => {
  const metrics = new Metrics();
  metrics.init(params, configGlobals, wrapper, scriptUrl);
  metrics.onShow(wrapper, () => metrics.send('show', {}));
  metrics.onInteract(wrapper, () => metrics.send('interact', {}));
  return metrics;
};
