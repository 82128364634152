import './styles.css';
import { Hotel } from '../../blissey/blissey.types';
import { HotelCard } from '../hotel_card/hotel_card';

type Props = { hotels: Hotel[]; compact: boolean };

export const HotelsList = ({ hotels, compact }: Props) => (
  <div className="hotels-list">
    {hotels.map((hotel) => (
      <div className="hotels-list__hotel">
        <HotelCard hotel={hotel} compact={compact} />
      </div>
    ))}
  </div>
);
