import { FunctionalComponent } from 'preact';

import { withDataReceiver } from '../../lib/with_data_receiver';
import { tpMediaLinkWrapper } from '../../lib/tp_media_link_wrapper';

import { LinkProps, LinkViewProps } from './link.types';

import './index.css';

const LinkView: FunctionalComponent<LinkViewProps> = (props) => {
  const { receivedData, redirect, id, widget_id, class: className, children } = props;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (redirect) {
      const link = event.currentTarget;
      link.href = tpMediaLinkWrapper(receivedData.href, props);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      id={`${id}_${widget_id}`}
      className={`link ${className}`}
      onClick={handleClick}
      href={receivedData.href}
      target="_blank"
      data-testid="link"
    >
      {children}
    </a>
  );
};

export const Link = withDataReceiver<LinkProps>(LinkView);
