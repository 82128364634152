import { IconType } from './icon.types';
import { iconsMap } from './icons-map';

type Props = {
  type: IconType;
  height?: number;
};

export const Icon = ({ type, height = 16 }: Props) => {
  const Component = iconsMap[type];
  return <Component height={height} />;
};
